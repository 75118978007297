import { type Observable } from 'rxjs'
import { ajax, type AjaxResponse } from 'rxjs/ajax'
import { apiURL } from '../config/environment'
import { type Band, HttpMethod, type Locale } from '../enums'
import { serializeQuery } from '../helpers/util-functions'
import { type Bonus, type Compensation } from '../types'

const contentTypeJSONHeader = {
  'Content-Type': 'application/json'
}
const acceptJSONHeader = {
  Accept: 'application/json; charset=UTF-8'
}

// @ts-expect-error Falta argumento
interface FetchBonusDataResponse extends AjaxResponse {
  response: Bonus
}
export const fetchBonusData = (
  band?: Band | null
): Observable<FetchBonusDataResponse> =>
  ajax({
    url: `${apiURL ?? ''}/bonus?${serializeQuery({
      band
    })}`,
    method: HttpMethod.Get,
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader
    },
    withCredentials: true
  })

// @ts-expect-error Falta argumento
interface FetchCompensationDataResponse extends AjaxResponse {
  response: { compensation: Compensation[] }
}
export const fetchCompensationData = (
  locale: Locale
): Observable<FetchCompensationDataResponse> =>
  ajax({
    url: `${apiURL ?? ''}/compensation`,
    method: HttpMethod.Get,
    headers: {
      ...acceptJSONHeader,
      'Accept-Language': locale
    },
    withCredentials: true
  })
