import { type ReactElement } from 'react'
import { Grid, Box } from '@material-ui/core'
import { Route, Routes } from 'react-router'
import { Link } from 'react-router-dom'
import abiLogo from '../../../assets/images/logo-abi.svg'
import logo from '../../../assets/images/logo-login.svg'
import illustration from '../../../assets/images/session-people.png'
import LanguageSwitcher from '../../common/LanguageSwitcher'
import ForgotPassword from './ForgotPassword'
import Login from './Login'
import PasswordRecovery from './PasswordRecovery'
import Register from './Register'
import styles from './Session.module.scss'
import VerifyCode from './VerifyCode'

const Session = (): ReactElement => {
  return (
    <Grid
      container
      direction="row"
      justifyContent="flex-end"
      alignItems="center"
      className={styles.container}
    >
      <Grid item sm={5} md={7} lg={8} className={styles.imageContainer}>
        <Box display={{ xs: 'none', sm: 'block' }}>
          <img
            src={illustration}
            alt="People money flow"
            className={styles.mainImage}
          />
        </Box>
      </Grid>

      <Grid item sm={12} md={5} lg={4} className={styles.panel}>
        <Link to="/session/login" tabIndex={-1} className={styles.appLogoLink}>
          <img className={styles.appLogo} src={logo} alt="Application logo" />
        </Link>

        <div className={styles.formContainer}>
          <Routes>
            <Route path="login" element={<Login />} />
            <Route path="register" element={<Register />} />
            <Route path="user-verification" element={<VerifyCode />} />
            <Route path="forgot-password" element={<ForgotPassword />} />
            <Route path="password-recovery" element={<PasswordRecovery />} />
          </Routes>

          <div className={styles.languageSwitcher}>
            <LanguageSwitcher />
          </div>
        </div>

        <img src={abiLogo} alt="AbInBev Logo" />
      </Grid>
    </Grid>
  )
}
export default Session
