import { shouldPolyfill } from '@formatjs/intl-displaynames/should-polyfill'

export default async function polyfill (): Promise<void> {
  if (typeof shouldPolyfill() !== 'undefined' && shouldPolyfill() !== null) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-displaynames/polyfill')
  }

  if ((Intl.DisplayNames as any).polyfilled !== null) {
    await import('@formatjs/intl-displaynames/locale-data/en')
    await import('@formatjs/intl-displaynames/locale-data/en')
  }
}
