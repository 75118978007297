import { Button, Typography } from '@material-ui/core'
import React, { type ReactElement, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { TranslationKey } from '../../../i18n/translations'
import {
  fetchUserSession,
  getCredentialsError,
  getFetching,
  getSending,
  getUser
} from '../slice'
import ModalInfo from './ModalInfo'
import styles from './Session.module.scss'
import { redirectSSO } from './valideteSSO'

function Login (): ReactElement {
  const [open, setOpen] = React.useState(false)
  const sending = useSelector(getSending)
  const fetching = useSelector(getFetching)
  const hasUser = !(useSelector(getUser) == null)
  const credentialsError = useSelector(getCredentialsError)
  const state = sessionStorage.getItem('state')
  const dispatch = useDispatch()

  const handleRedirectSSO = (): void => {
    redirectSSO()
  }

  const closeModal = (): void => {
    setOpen(false)
  }

  useEffect(() => {
    if (state !== null) {
      if (fetching && !hasUser) {
        dispatch(fetchUserSession())
      }
    }

    if (credentialsError) {
      setOpen(true)
    }
  }, [dispatch, fetching, hasUser])

  return (
    <>
      <div className={styles.textContainer}>
        <FormattedMessage id={TranslationKey.LOGIN_MESSAGE} />
      </div>

      {credentialsError && (
        <div className={styles.textErrorSession}>
          <Typography variant="subtitle1">
            <FormattedMessage id={TranslationKey.ERROR_SESSION} />
          </Typography>
        </div>
      )}

      <ModalInfo openModal={open} modalClose={closeModal} />

      <div className={styles.buttonContainer}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={sending}
          onClick={handleRedirectSSO}
        >
          <FormattedMessage id={TranslationKey.INIT_SESSION} />
        </Button>

        {sending && (
          <div className={styles.spinnerContainer}>
            <LoadingSpinner />
          </div>
        )}
      </div>
      <div className={styles.linksContainer}>
        <Typography variant="subtitle1" align="center">
          <a
            href="https://soporte.somosmaz.com/pqr/Benefi"
            target="_blank"
            rel="noreferrer"
          >
            <FormattedMessage id={TranslationKey.NEED_HELP} />
          </a>
        </Typography>
      </div>
    </>
  )
}

export default Login
