import { type ReactElement } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import logo from '../../../assets/images/navbar/logo.svg'
import { TranslationKey } from '../../../i18n/translations'
import { getUser } from '../../userSession/slice'
import MenuProfile from '../MenuProfile'
import styles from './Menu.module.scss'
import { MenuSidebar } from './MenuSidebar'
import { useEffect, useState } from 'react'
function Menu (): ReactElement {
  const user = useSelector(getUser)
  const location = useLocation()
  const [itemsMenu, setItemsMenu] = useState<MenuItem[]>([{
    translationKey: TranslationKey.MY_COMPENSATION,
    path: '/compensation'
  }])
  interface MenuItem {
    translationKey: string
    path: string
  }
  useEffect(() => {
    if(user?.role==='ADMINISTRATOR') {
      setItemsMenu([...itemsMenu, { translationKey:TranslationKey.ADMINISTRATION_PANEL, path: '/admin/payroll'}])
    }
  }, [user])
  return (
    <div className={styles.navbar}>
      <MenuSidebar menuItems={itemsMenu} />
      <Link to={(user != null) ? '/' : '/session/login'} className={styles.navbarLogo}>
        <img src={logo} alt="Logo Exit Interview" className={styles.mainLogo} />
      </Link>
      <ul className={styles.mainMenu}>
        <li>
          <Link to={(user != null) ? '/' : '/session/login'}>
            <img
              src={logo}
              alt="Logo Exit Interview"
              className={styles.mainLogo}
            />
          </Link>
        </li>
        {itemsMenu.map(({ path, translationKey }) => (
          <li
            key={translationKey}
            className={`${
              location.pathname.startsWith(path) ? styles.menuSelected : ''
            }`}
          >
            <Link to={path} key={path}>
              <FormattedMessage id={translationKey} />
            </Link>
          </li>
        ))}
      </ul>
      <div className={styles.menuRight}>
        <Link to="payroll" className={styles.btnPayroll}>
          <FormattedMessage id={TranslationKey.MY_PAYROLL_RECEIPT} />
        </Link>
        <MenuProfile />
      </div>
    </div>
  )
}

export default Menu
