import { createTheme } from '@material-ui/core/styles'
import { AppColor } from '../enums'

const montserratFontFamily = '"Montserrat", "Helvetica", "Arial", sans-serif;'

const theme = createTheme({
  palette: {
    primary: {
      main: AppColor.Primary,
      contrastText: AppColor.White
    },
    tonalOffset: 0.2,
    secondary: {
      main: AppColor.Secondary,
      contrastText: AppColor.White
    },
    error: {
      main: AppColor.Error
    }
  },
  typography: {
    h1: {
      fontFamily: montserratFontFamily,
      fontWeight: 300
    },
    h2: {
      fontFamily: montserratFontFamily,
      fontWeight: 300
    },
    h3: {
      fontFamily: montserratFontFamily,
      fontWeight: 400
    },
    h4: {
      fontFamily: montserratFontFamily
    },
    h5: {
      fontFamily: montserratFontFamily,
      fontWeight: 700
    },
    h6: {
      fontFamily: montserratFontFamily
    }
  },
  overrides: {
    MuiButton: {
      root: {
        borderRadius: 20,
        textTransform: 'none'
      }
    },
    MuiCheckbox: {
      root: {
        padding: 3,
        margin: '0 5px'
      }
    },
    MuiSlider: {
      thumb: {
        border: '3px solid white',
        boxShadow: '1px 1px 10px #0000005c',
        height: 18,
        marginTop: -8,
        width: 18
      }
    }
  }
})

export default theme
