import { BenefitType } from '../../enums'
import { mapBackendPublicURL } from '../../helpers/util-functions'
import {
  type Benefit,
  type BenefitCategory,
  type RawBenefit,
  type RawBenefitCategory
} from '../../types'

export const benefitCategoryMapper = (
  rawCategories: RawBenefitCategory[]
): BenefitCategory[] =>
  rawCategories.map(
    (raw: RawBenefitCategory): BenefitCategory => ({
      id: raw.id,
      title: raw.name,
      pillar: raw.pillar,
      imageURL: mapBackendPublicURL(raw.thumbnail),
      logoURL: mapBackendPublicURL(raw.icon)
    })
  )

const rawBenefitTypeMap = {
  deal: BenefitType.Agreement,
  benefit: BenefitType.Benefit
}

export const benefitMapper = (raw: RawBenefit): Benefit => ({
  id: raw.id,
  categoryID: raw.categoryID,
  type: rawBenefitTypeMap[raw.type],
  title: raw.title,
  description: raw.description,
  providerName: raw.supplier.name,
  providerImgURL: mapBackendPublicURL(raw.supplier.logo),
  address: raw.address ?? '',
  phone: raw.phone ?? '',
  websiteURL: raw.web_page,
  email: raw.another,
  attached: raw.attached,
  updated_at: raw.updated_at
})
