import { Button, Typography } from '@material-ui/core'
import React, {
  type FunctionComponent,
  type RefObject,
  useCallback,
  useRef
} from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import GeneralModal from '../../../components/GeneralModal'
import { type UserRole } from '../../../enums'
import { TranslationKey } from '../../../i18n/translations'
import { updateUsersRequest } from '../slice'

interface ConfirmRoleModalProps {
  buttonRef: RefObject<HTMLButtonElement>
  role: UserRole
}

const ConfirmRoleModal: FunctionComponent<ConfirmRoleModalProps> = ({
  buttonRef,
  role
}) => {
  const dispatch = useDispatch()
  const closeButtonRef = useRef<HTMLButtonElement | null>(null)

  const handleChangeClick = useCallback(() => {
    closeButtonRef.current?.click()
    dispatch(
      updateUsersRequest({
        role
      })
    )
  }, [dispatch, role])

  return (
    <GeneralModal
      isOpen
      buttonRef={buttonRef}
      maxWidth={300}
      bottomCloseButton={
        <Button
          ref={closeButtonRef}
          variant="outlined"
          style={{
            margin: 2
          }}
        >
          <FormattedMessage id={TranslationKey.CANCEL} />
        </Button>
      }
      bottomButtons={[
        <Button
          key="change"
          variant="contained"
          color="primary"
          style={{
            margin: 2
          }}
          onClick={handleChangeClick}
        >
          <FormattedMessage id={TranslationKey.CHANGE} />
        </Button>
      ]}
    >
      <div
        style={{
          textAlign: 'center'
        }}
      >
        <Typography
          variant="h5"
          color="primary"
          style={{
            marginBottom: 10
          }}
        >
          <FormattedMessage id={TranslationKey.CHANGE_ROLE} />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id={TranslationKey.CHANGE_ROLE_CONFIRMATION} />
        </Typography>
      </div>
    </GeneralModal>
  )
}

export default ConfirmRoleModal
