import React, {
  type FunctionComponent,
  type ReactElement,
  type ReactNode,
  useEffect,
  useState
} from 'react'
import { type IntlShape, RawIntlProvider } from 'react-intl'
import { useSelector } from 'react-redux'
import intlHelper from '../i18n/intlHelper'
import { getLocale } from '../modules/userSession/slice'

interface IntlWrapperProps {
  children: ReactNode
}

const IntlWrapper: FunctionComponent<IntlWrapperProps> = ({
  children
}): ReactElement => {
  const locale = useSelector(getLocale)
  const [intl, setIntl] = useState<IntlShape>(intlHelper.setIntl(locale))

  useEffect(() => {
    setIntl(intlHelper.setIntl(locale))
  }, [locale])

  return <RawIntlProvider value={intl}>{children}</RawIntlProvider>
}

export default IntlWrapper
