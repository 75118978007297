import DateFnsUtils from '@date-io/date-fns'
import {
  CircularProgress,
  IconButton,
  InputAdornment
} from '@material-ui/core'
import { Today as TodayIcon } from '@material-ui/icons'
import {
  DatePicker,
  type DatePickerProps,
  MuiPickersUtilsProvider
} from '@material-ui/pickers'
import 'date-fns'
import enLocale from 'date-fns/locale/en-US'
import esLocale from 'date-fns/locale/es'
import { useField } from 'formik'
import React, {
  type FunctionComponent,
  type ReactElement,
  type ReactNode,
  useCallback
} from 'react'
import { useSelector } from 'react-redux'
import { Locale } from '../../enums'
import { useDateFormatString } from '../../modules/userSession/hooks'
import { getLocale } from '../../modules/userSession/slice'

const localeMap = {
  [Locale.Spanish]: esLocale,
  [Locale.English]: enLocale
}

interface InputProps {
  value?: string
  fullWidth: boolean
  required: boolean
  placeholder: string
  endAdornment: ReactNode
}

interface FormDateFieldProps extends Partial<DatePickerProps> {
  name: string
  label: string
  required?: boolean
  loading?: boolean
}

const DateField: FunctionComponent<FormDateFieldProps> = ({
  name,
  label,
  placeholder,
  required = false,
  loading = false,
  disabled,
  ...props
}): ReactElement => {
  const locale = useSelector(getLocale)
  const dateFormat = useDateFormatString()

  const [field, meta, helpers] = useField<Date | null>(name)
  const handleChange = useCallback(
    (date: Date | null): void => {
      helpers.setValue(date)
    },
    [helpers]
  )

  const hasError = meta.touched && typeof meta.error !== 'undefined' && meta.error !== null

  const inputProps: InputProps = {
    value: '',
    fullWidth: true,
    required,
    placeholder: typeof placeholder !== 'undefined' && placeholder !== null ? placeholder : label,
    endAdornment: loading
      ? (
      <InputAdornment position="end">
        <CircularProgress size="20px" thickness={5} color="primary" />
      </InputAdornment>
        )
      : (
      <IconButton size="small" disabled={disabled}>
        <TodayIcon />
      </IconButton>
        )
  }
  // Ensures the input correctly formats the string
  if (field.value != null) delete inputProps.value

  return (
    <MuiPickersUtilsProvider utils={DateFnsUtils} locale={localeMap[locale]}>
      <DatePicker
        {...field}
        {...props}
        name={name}
        label={label}
        disabled={disabled}
        onChange={handleChange}
        required={required}
        format={dateFormat}
        inputVariant="filled"
        fullWidth
        clearable
        error={hasError}
        helperText={hasError ? meta.error : ' '}
        InputProps={inputProps}
      />
    </MuiPickersUtilsProvider>
  )
}

export default DateField
