/**
 * App
 */
export enum HttpMethod {
  Get = 'GET',
  Post = 'POST',
  Put = 'PUT',
  Delete = 'DELETE',
}

export enum Breakpoint {
  Small = 600,
  Medium = 960,
  Large = 1280,
  ExtraLarge = 1920,
}

export enum AppColor {
  Primary900 = '#5c2e01',
  Primary800 = '#7e3500',
  Primary700 = '#9f4900',
  Primary600 = '#bc5904',
  Primary500 = '#4fdbd1',
  Primary400 = '#ff9438',
  Primary300 = '#fda354',
  Primary200 = '#ffb777',
  Primary100 = '#fdc28d',
  Primary50 = '#fddbbc',

  Secondary900 = '#4b0224',
  Secondary800 = '#5a012a',
  Secondary700 = '#6e0032',
  Secondary600 = '#7e0039',
  Secondary500 = '#8e0040',
  Secondary400 = '#640026',
  Secondary300 = '#810031',
  Secondary200 = '#a72054',
  Secondary100 = '#d3457c',
  Secondary50 = '#ff7cae',
  Complementary = '#27dac5',
  ComplementaryLight = '#caf6f0',
  ComplementaryVariant = '#085457',
  NavBottomColor = '#383838',
  Error = '#b00020',
  Black = '#000',
  White = '#fff',
  Placeholder = '#9a9a9a',
  Primary = Primary500,
  PrimaryVariant = Primary700,
  Secondary = Secondary200,
  SecondaryVariant = Secondary700,
  PrimaryHighlight = '#a7205414',
  // Card
  CardOrange = '#e55d0c',
  CardBlue = '#03285a',
  CardTeal = '#78d3cd',
  CardGreen = '#0F7369',
  CardViolet = '#810031',
  CardBlueD = '#03285A',
  CardYellow = '#EA994B',
  CardBrown = '#5a012a',
}

export enum Locale {
  Spanish = 'es',
  English = 'en',
}

export enum SortDirection {
  Ascending = 'asc',
  Descending = 'desc',
}

/**
 * Business Logic
 */
export enum UserRole {
  Admin = 'ADMINISTRATOR',
  Regular = 'REGULAR',
  Invalid = 'INVALID',
}

export enum VicePresidencyCode {
  Sales = 'SALES',
  Finance = 'FINANCE',
  Legal = 'LEGAL / CORPORATE AFFAIRS',
  Logistics = 'LOGISTICS',
  Supply = 'SUPPLY',
  Solutions = 'SOLUTIONS',
  Marketing = 'MARKETING',
  Procurement = 'PROCUREMENT',
  People = 'PEOPLE',
}

export enum BusinessUnitCode {
  CAC = 'CAC',
  Colombia = 'CO',
  Ecuador = 'EC',
  HondurasAndSalvador = 'HN / SV',
  Mexico = 'MEX',
  Peru = 'PE',
}

export enum CountryCode {
  Barbados = 'BB',
  Colombia = 'CO',
  Dominica = 'DM',
  DominicanRepublic = 'DO',
  Ecuador = 'EC',
  Guatemala = 'GT',
  Honduras = 'HN',
  Mexico = 'MX',
  Panama = 'PA',
  Peru = 'PE',
  Salvador = 'SV',
  SaintVincent = 'VC',
}

export enum CountryCurrencyCode {
  Barbados = 'BBD',
  Colombia = 'COP',
  Dominica = 'XCD',
  DominicanRepublic = 'DOP',
  Ecuador = 'USD',
  Guatemala = 'GTQ',
  Honduras = 'HNL',
  Mexico = 'MXN',
  Panama = 'USD',
  Peru = 'PEN',
  Salvador = 'USD',
  SaintVincent = 'XCD',
}

export enum Band {
  '0A' = '0-A',
  '0B' = '0-B',
  IA = 'I-A',
  IB = 'I-B',
  IIA = 'II-A',
  IIB = 'II-B',
  IIIA = 'III-A',
  IIIB = 'III-B',
  IVA = 'IV-A',
  IVB = 'IV-B',
  VA = 'V-A',
  VB = 'V-B',
  VIA = 'VI-A',
  VIB = 'VI-B',
  VIIA = 'VII-A',
  VIIB = 'VII-B',
  VIIIA = 'VIII-A',
  VIIIB = 'VIII-B',
  IXA = 'IX-A',
  IXB = 'IX-B',
  XA = 'X-A',
  XB = 'X-B',
}

export enum Gender {
  Male = 'M',
  Female = 'F',
}

/**
 * User Session
 */
export enum PasswordChangeFields {
  CurrentPassword = 'currentPassword',
  NewPassword = 'newPassword',
  NewPasswordConfirmation = 'newPasswordConfirmation',
}

export enum ProfileEditField {
  Name = 'name',
  Email = 'email',
}

/**
 * Compensation
 */
export enum CompensationType {
  Regular = 'REGULAR',
  Benefits = 'BENEFITS',
}

export enum SpecialMexicoCompensations {
  FoodVouchers = 'Vales de Despensa',
  SavingsFund = 'Fondo de Ahorro',
}

/**
 * Benefits
 */
export enum BenefitType {
  Benefit = 'BENEFIT',
  Agreement = 'AGREEMENT',
}

export enum BenefitPillar {
  Physical = 'PHYSICAL',
  Social = 'SOCIAL',
  Economic = 'ECONOMIC',
}

/**
 * Admin
 */
export enum AdminUserTableField {
  Selected = 'selected',
  Sharp = 'sharp',
  Name = 'name',
  Country = 'country',
  Company = 'uen',
  Email = 'email',
  Role = 'role',
}

/**
 *
 * Admin benefits
 */

export enum AdminBenefits {
  Name = 'name',
  Provider = 'provider',
  Describe = 'describe',
  Phone = 'phone',
  Address = 'address',
  Web = 'web',
  Contact = 'contact',
  Help = 'help',
}
