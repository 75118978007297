import { IconButton, Typography } from '@material-ui/core'
import { ArrowBackIosOutlined, Help as HelpIcon } from '@material-ui/icons'
import React, { type FunctionComponent, type ReactNode, type RefObject, useRef } from 'react'
import { Link } from 'react-router-dom'
import styles from './MenuMobile.module.scss'

interface MobileMenuProps {
  expand: boolean
  title: string | ReactNode
  describe?: string | ReactNode
  backPath?: string
  value?: string
  HelpModal?: FunctionComponent<{ buttonRef: RefObject<HTMLElement> }> | null
}

const MenuMobile: FunctionComponent<MobileMenuProps> = ({
  expand,
  title,
  describe,
  backPath = '/',
  HelpModal,
  value
}) => {
  const styleMenu = expand
    ? `${styles.menuMobile} ${styles.menuMobileExpand}`
    : styles.menuMobile

  const buttonRef = useRef(null)

  return (
    <div className={styleMenu}>
      <div className={styles.rowMobileMenu}>
        <div className={`${styles.col1} ${styles.pt} ${styles.pb}`}>
          <Link to={backPath}>
            <IconButton size="small">
              <ArrowBackIosOutlined fontSize="small" />
            </IconButton>
          </Link>
        </div>
        <div
          className={`${styles.col8} ${styles.pt} ${styles.pb} ${styles.textCenter}`}
        >
          <p className={styles.titleMenuMobile}>
            <strong>{title}</strong>
          </p>
        </div>
      </div>

      {expand && (
        <div className={`${styles.rowMobileMenu} ${styles.textCenter}`}>
          <div className={`${styles.col12}`}>
            <small className={`${styles.textMuted}`}>{describe}</small>
          </div>
          <div className={styles.col1}>
            <IconButton ref={buttonRef} size="small" className="helpButton">
              <HelpIcon />
            </IconButton>
            {(HelpModal != null) && <HelpModal buttonRef={buttonRef} />}
          </div>
          <div className={`${styles.col8} ${styles.textCenter}`}>
            <Typography
              className={styles.smallerFont}
              variant="h4"
              color="primary"
            >
              {value}
            </Typography>
          </div>
        </div>
      )}
    </div>
  )
}

export default MenuMobile
