import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Add as AddIcon } from '@material-ui/icons'
import React from 'react'
import { type IModalForms } from '../../../../types'
import FormProvider from './NewProvider'
import styles from './Providers.module.scss'

export default function ModalFormProviders (props: IModalForms): JSX.Element {
  const [open, setOpen] = React.useState(false)
  const { nameBtn, idRow, edit, providerName, saveData } = props

  const handleClick = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleSaveData = (save: boolean): void => {
    if (saveData != null) {
      saveData(save)
    }
  }

  return (
    <div>
      {edit
        ? (
        <Button
          variant="text"
          className={styles.textEdit}
          onClick={handleClick}
        >
          {nameBtn}
        </Button>
          )
        : (
        <Button variant="outlined" color="primary" onClick={handleClick}>
          <AddIcon color="primary" />
          {nameBtn}
        </Button>
          )}
      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.borderModal}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h5"
            color="primary"
            className={styles.textCenter}
          >
            {nameBtn}
          </Typography>
        </DialogTitle>
        <DialogContent className={styles.cotentModal}>
          <DialogContentText id="alert-dialog-description">
            <FormProvider edit={edit} idItem={idRow} providerName={providerName} saveData={handleSaveData} />
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}
