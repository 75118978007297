import { Grid } from '@material-ui/core'
import AppBar from '@material-ui/core/AppBar'
import Tab from '@material-ui/core/Tab'
import TabContext from '@material-ui/lab/TabContext'
import TabList from '@material-ui/lab/TabList'
import TabPanel from '@material-ui/lab/TabPanel'
import React from 'react'
import PanelCaterogies from './PanelCategories'
import styles from './PanelControl.module.scss'
import PanelProviders from './PanelProvider'

export default function Panel (): JSX.Element {
  const [value, setValue] = React.useState('1')

  const handleChange = (event: any, newValue: any): void => {
    setValue(newValue)
  }

  return (
    <Grid container spacing={10} className={styles.container}>
      <Grid item lg={12} md={12} sm={12} className={styles.breadcrumbs}>
        Beneficios y convenios {'>'} Categorías
      </Grid>
      <Grid item lg={12} md={12} sm={12}>
        <TabContext value={value}>
          <AppBar position="static" className={styles.shadowHeader}>
            <TabList
              onChange={handleChange}
              className={styles.headerPanel}
              indicatorColor="primary"
            >
              <Tab label="Categorías" value="1" textColor="secondary" />
              <Tab label="Proveedores" value="2" textColor="secondary" />
            </TabList>
          </AppBar>
          <TabPanel value="1" className={styles.tabContent}>
            <PanelCaterogies />
          </TabPanel>
          <TabPanel value="2" className={styles.tabContent}>
            <PanelProviders />
          </TabPanel>
        </TabContext>
      </Grid>
    </Grid>
  )
}
