import dateTimeFormatPolyfill from './polyfills/datetimeformat'
import displayNamesPolyfill from './polyfills/displaynames'
import getCanonicalLocalesPolyfill from './polyfills/getcanonicallocales'
import listFormatPolyfill from './polyfills/listformat'
import localePolyfill from './polyfills/locale'
import numberFormatPolyfill from './polyfills/numberformat'
import pluralRulesPolyfill from './polyfills/pluralrules'
import relativeTimeFormatPolyfill from './polyfills/relativetimeformat'

async function loadPolyfills (): Promise<void> {
  await getCanonicalLocalesPolyfill()
  await pluralRulesPolyfill()
  await displayNamesPolyfill()
  await localePolyfill()
  await listFormatPolyfill()
  await numberFormatPolyfill()
  await relativeTimeFormatPolyfill()
  await dateTimeFormatPolyfill()
}

export default loadPolyfills
