import {
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography
} from '@material-ui/core'
import React, {
  type ChangeEvent,
  type FunctionComponent,
  type RefObject,
  useCallback,
  useState
} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import GeneralModal from '../../../components/GeneralModal'
import { CountryCode } from '../../../enums'
import { TranslationKey } from '../../../i18n/translations'
import isoCountries from '../../../services/isoCountries'
import { getUserCountryCode } from '../../userSession/slice'
import {
  ECTerms,
  GTTerms,
  HNTerms,
  MXTerms,
  PATerms,
  PETerms,
  RDTerms,
  SVTerms
} from './TermsContent'

interface TermsModalProps {
  buttonRef: RefObject<HTMLElement>
}

const platformCountries = [
  CountryCode.Ecuador,
  CountryCode.Guatemala,
  CountryCode.Honduras,
  CountryCode.Mexico,
  CountryCode.Panama,
  CountryCode.Peru,
  CountryCode.DominicanRepublic,
  CountryCode.Salvador
]

const companyMap: Record<string, string> = {
  [CountryCode.Ecuador]: ECTerms,
  [CountryCode.Guatemala]: GTTerms,
  [CountryCode.Honduras]: HNTerms,
  [CountryCode.Mexico]: MXTerms,
  [CountryCode.Panama]: PATerms,
  [CountryCode.Peru]: PETerms,
  [CountryCode.DominicanRepublic]: RDTerms,
  [CountryCode.Salvador]: SVTerms
}

const TermsModal: FunctionComponent<TermsModalProps> = ({ buttonRef }) => {
  const intl = useIntl()
  const initCountryCode = useSelector(getUserCountryCode)

  const [countryCode, setCountryCode] = useState<CountryCode | undefined>(
    initCountryCode
  )
  const handleCountryCodeChange = useCallback(
    (event: ChangeEvent<{ name?: string, value: unknown }>) => {
      setCountryCode(event.target.value as CountryCode | undefined)
    },
    []
  )

  const terms = countryCode !== undefined ? companyMap[countryCode] : null

  return (
    <GeneralModal isOpen buttonRef={buttonRef} maxWidth={700}>
      <Typography
        variant="h5"
        color="primary"
        style={{
          marginTop: 20,
          marginBottom: 20
        }}
      >
        <FormattedMessage id={TranslationKey.TERMS_AND_CONDITIONS} />
      </Typography>

      {initCountryCode !== null && (
        <div
          style={{
            marginBottom: 30,
            width: '100%'
          }}
        >
          <Typography variant="body1">
            <FormattedMessage id={TranslationKey.PLEASE_SELECT_COUNTRY} />
          </Typography>

          <FormControl
            variant="filled"
            style={{
              width: 300
            }}
          >
            <InputLabel>
              <FormattedMessage id={TranslationKey.COUNTRY} />
            </InputLabel>
            <Select value={countryCode} onChange={handleCountryCodeChange}>
              {platformCountries.map((code) => (
                <MenuItem key={code} value={code}>
                  {isoCountries.getName(code, intl.locale)}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </div>
      )}

      {terms !== null && (
        <div
          style={{
            textAlign: 'justify',
            whiteSpace: 'pre-wrap',
            width: '100%'
          }}
        >
          {terms}
        </div>
      )}
    </GeneralModal>
  )
}

export default TermsModal
