import { useFormikContext } from 'formik'
import { isEqual as _isEqual, omit as _omit } from 'lodash-es'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { usePrevious } from '../../../helpers/hooks'
import { getCredentialsError, setCredentialsError } from '../slice'

type FormValues = Record<string, string>

function CredentialsError (): null {
  const { setFieldValue, values } = useFormikContext<FormValues>()
  const credentialsError = useSelector(getCredentialsError)

  useEffect(() => {
    setFieldValue('credentialsError', credentialsError)
  }, [credentialsError, setFieldValue])

  const prevValues = usePrevious(values)
  const equalValues = _isEqual(
    _omit(prevValues, 'credentialsError'),
    _omit(values, 'credentialsError')
  )
  const dispatch = useDispatch()
  useEffect(() => {
    if (equalValues) return
    dispatch(
      setCredentialsError({
        credentialsError: false
      })
    )
  }, [dispatch, equalValues])

  return null
}

export default CredentialsError
