import { Button, Divider, Grid, Typography } from '@material-ui/core'
import { Add as AddIcon, Delete as DeleteIcon } from '@material-ui/icons'
import React, { type ReactElement, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import searchImg from '../../../assets/images/no-results.png'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { TranslationKey } from '../../../i18n/translations'
import { getFetching, getUsers } from '../slice'
import UploadCollaboratorsReportModal from './UploadCollaboratorsReportModal'
import UploadExitsReportModal from './UploadExitsReportModal'
import styles from './UsersAdmin.module.scss'
import UsersFilters from './UsersFilters'
import UsersSearch from './UsersSearch'
import UsersTable from './UsersTable'

function UsersAdmin (): ReactElement {
  const hasUsers = !(useSelector(getUsers).length === 0)
  const fetching = useSelector(getFetching)

  const collaboratorsRef = useRef<HTMLButtonElement | null>(null)
  const exitsRef = useRef<HTMLButtonElement | null>(null)

  return (
    <Grid container className={styles.container}>
      <UploadCollaboratorsReportModal buttonRef={collaboratorsRef} />
      <UploadExitsReportModal buttonRef={exitsRef} />

      <Grid item xs={8}>
        <Typography variant="h5" color="primary">
          <FormattedMessage id={TranslationKey.USER_MANAGEMENT} />
        </Typography>
      </Grid>
      <Grid item xs={4}>
        <Button
          ref={collaboratorsRef}
          className={styles.uploadButton}
          variant="outlined"
          color="primary"
        >
          <AddIcon color="primary" />
          <FormattedMessage id={TranslationKey.UPLOAD_COLLABORATORS} />
        </Button>
        <Button
          ref={exitsRef}
          className={styles.uploadButton}
          variant="outlined"
          color="primary"
        >
          <DeleteIcon color="primary" />
          <FormattedMessage id={TranslationKey.UPLOAD_EXITS} />
        </Button>
      </Grid>

      <Grid item xs={12}>
        <Typography variant="body2">
          <FormattedMessage id={TranslationKey.USER_ADMIN_P1} />
        </Typography>
      </Grid>

      <Grid item xs={12}>
        <UsersSearch />
      </Grid>

      <Grid item xs={12}>
        <UsersFilters />
      </Grid>

      <Grid item xs={12} className={styles.dividerContainer}>
        <Divider />
      </Grid>

      <Grid item xs={12}>
        {fetching !== null
          ? (
          <div className={styles.spinnerContainer}>
            <LoadingSpinner />
          </div>
            )
          : (
          <>
            {!hasUsers
              ? (
              <div className={styles.instructions}>
                <Typography variant="body2">
                  <FormattedMessage id={TranslationKey.PERFORM_USER_SEARCH} />
                </Typography>
                <img src={searchImg} alt="Perform user search" />
              </div>
                )
              : (
              <UsersTable />
                )}
          </>
            )}
      </Grid>
    </Grid>
  )
}

export default UsersAdmin
