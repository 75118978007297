import { Container, Typography } from '@material-ui/core'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { TranslationKey } from '../../../i18n/translations'
import { getUser } from '../../userSession/slice'
import styles from './Home.module.scss'
import HomeCards from './HomeCards'

function Home (): JSX.Element {
  const user = useSelector(getUser)
  const firstName = user?.name.split(' ').slice(0, 2).join(' ') ?? ''
  const sharp = user?.sharp?.toString() ?? ''
  React.useEffect(() => {
    const sharp = user?.sharp.toString() ?? ''
    window.test(sharp)
  }, [])
  return (
    <>
      <div className={styles.userGreeting}>
        <Container maxWidth="lg">
          <Typography className={styles.greetingName}>
            <FormattedMessage
              id={TranslationKey.GREETING}
              values={{
                firstName
              }}
            />
          </Typography>
          <Typography className={styles.greetingMessage}>
            <FormattedMessage id={TranslationKey.INIT_QUESTION} />
          </Typography>
          <div className="modal-nps-poll"> </div>
        </Container>
      </div>
      <HomeCards />
    </>
  )
}
export default Home
