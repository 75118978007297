import { Grid } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import React, { type ChangeEvent, type ReactElement, useEffect, useState } from 'react'
import { map } from 'rxjs/operators'
import { adminBenefitsAPI } from '../../../../api'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { type RawBenefitCategory } from '../../../../types'
import styles from '../categories/Categories.module.scss'
import CategoryBenefits from '../categories/CategoryBenefits'
import NewBenefi from '../categories/ModalFormCategorie'

function PanelCategories (): ReactElement {
  const [categorie, setCategorie] = useState<RawBenefitCategory[]>([])
  const [searchCat, setSearchCat] = useState<RawBenefitCategory[]>([])
  const [loading, setLoading] = useState(false)

  const handlerSearch = (event: ChangeEvent<HTMLInputElement>): void => {
    const key = event.target.value
    const newData = searchCat.filter(
      (cat) => cat.name.toLowerCase().includes(key.toLowerCase())
    )
    setCategorie(newData)
  }

  const handlerPillar = (event: ChangeEvent<{ value: unknown }>): void => {
    const key = String(event.target.value)
    const newData = searchCat.filter((cat) => cat.pillar === key)

    if (key === 'all') {
      setCategorie(searchCat)
    } else {
      setCategorie(newData)
    }
  }

  const getCategoriesAdmin = (): void => {
    setLoading(true)

    adminBenefitsAPI
      .getCategoriesAdmin()
      // @ts-expect-error: PRoblemas de tipos
      .pipe(map(({ response }: { response: RawBenefitCategory[] }) => response))
      .subscribe(
        (benefits) => {
          setCategorie(benefits)
          setSearchCat(benefits)
          setLoading(false)
        },
        () => {
          setCategorie([])
          setLoading(false)
        }
      )
  }

  const handleDelete = (idCat: number): void => {
    setLoading(true)
    adminBenefitsAPI.DeleteCategorie({ id: idCat }).subscribe(
      () => {
        getCategoriesAdmin()
        setLoading(false)
      },
      () => {
        setLoading(false)
      }
    )
  }

  const handleSaveCategorie = (save: boolean): void => {
    if (save) {
      getCategoriesAdmin()
    }
  }

  useEffect(() => {
    getCategoriesAdmin()
  }, [])

  return (
    <Grid container direction="row" spacing={2} className={styles.container}>
      <Grid item xs={4} className={styles.paddingRows}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="filled-age-native-simple">
            Busca por pilar
          </InputLabel>
          <Select name="type" onChange={handlerPillar} label="Busca por pilar">
            <option value="PHYSICAL">Físico</option>
            <option value="SOCIAL">SocioEmocional</option>
            <option value="ECONOMIC">Financiero</option>
            <option value="all">Todos</option>
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={5} className={styles.paddingRows}>
        <FormControl fullWidth variant="outlined">
          <TextField
            id="outlined-search"
            onChange={handlerSearch}
            label="Buscar categoría"
            type="search"
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={3} className={styles.paddingRows}>
        <NewBenefi
          edit={false}
          nameBtn="Nueva categoria"
          showForm
          idRow={1}
          saveData={handleSaveCategorie}
        />
      </Grid>

      <Grid
        item
        xs={12}
        lg={12}
        md={12}
        sm={12}
        className={styles.categoriesRow}
      >
        {loading
          ? (
          <LoadingSpinner />
            )
          : (
              categorie.map((category) => (
            <CategoryBenefits
              url={`/admin/benefits/panel/list/${category.id}`}
              key={category.id}
              deleteItem={handleDelete}
              loading={loading}
              data={category}
              saveData={handleSaveCategorie}
            />
              ))
            )}
      </Grid>
    </Grid>
  )
}

export default PanelCategories
