import { Button, Grid, Typography } from '@material-ui/core'
import { Form, Formik } from 'formik'
import React, { type ReactElement, useCallback, useMemo } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { FormTextField } from '../../../components/FormFields'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { ProfileEditField } from '../../../enums'
import { TranslationKey } from '../../../i18n/translations'
import TranslateFormikErrors from '../../../wrappers/TranslateFormikErrors'
import { getUpdatingProfile, updateProfileRequest } from '../slice'
import styles from './EditProfile.module.scss'
import { getProfileSchema } from './schemas'

interface ProfileEditValues {
  [ProfileEditField.Name]: string
  [ProfileEditField.Email]: string
}

const initialValues: ProfileEditValues = {
  [ProfileEditField.Name]: '',
  [ProfileEditField.Email]: ''
}

function ProfileForm (): ReactElement {
  const intl = useIntl()
  const validationSchema = useMemo(() => getProfileSchema(intl), [intl])

  const updatingProfile = useSelector(getUpdatingProfile)

  const dispatch = useDispatch()
  const handleSubmit = useCallback(
    (values: ProfileEditValues) => {
      if (values.name !== null && values.email !== null) return
      dispatch(
        updateProfileRequest({
          ...values
        })
      )
    },
    [dispatch]
  )

  return (
    <Formik
      initialValues={initialValues}
      initialErrors={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <Form autoComplete="off">
        <TranslateFormikErrors />

        <Grid container justifyContent="center" direction="column">
          <Grid item xs={12} lg={12} md={12} sm={12}>
            <Typography variant="h5" className={styles.formTitle}>
              <FormattedMessage id={TranslationKey.EDIT_PROFILE} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              name={ProfileEditField.Name}
              label={intl.formatMessage({
                id: TranslationKey.MY_PREFERRED_NAME
              })}
              fullWidth
              disabled={updatingProfile}
            />
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              name={ProfileEditField.Email}
              label={intl.formatMessage({
                id: TranslationKey.EMAIL
              })}
              type="email"
              fullWidth
              disabled={updatingProfile}
            />
          </Grid>
          <Grid item xs={12} className={styles.buttonsContainer}>
            <Link to="/profile/me">
              <FormattedMessage id={TranslationKey.CANCEL} />
            </Link>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={updatingProfile}
              style={{ color: '#000' }}
            >
              {updatingProfile && <LoadingSpinner />}
              <FormattedMessage
                id={
                  updatingProfile
                    ? TranslationKey.SAVING
                    : TranslationKey.SAVE_CHANGES
                }
              />
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

export default ProfileForm
