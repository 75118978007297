import { startCase } from 'lodash-es'
import React, { type ReactElement, useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Locale } from '../../../enums'
import { getLocale, setLocale } from '../../userSession/slice'
import styles from './LanguageSwitcher.module.scss'

function LanguageSwitcher (): ReactElement {
  const locale = useSelector(getLocale)
  const dispatch = useDispatch()
  const handleToggleClick = useCallback(() => {
    const newLocale =
      locale === Locale.Spanish ? Locale.English : Locale.Spanish
    dispatch(
      setLocale({
        locale: newLocale
      })
    )
  }, [dispatch, locale])

  return (
    <button
      type="button"
      className={styles.container}
      onClick={handleToggleClick}
    >
      <span className={locale === Locale.Spanish ? styles.localeActive : ''}>
        {startCase(Locale.Spanish)}
      </span>{' '}
      /{' '}
      <span className={locale === Locale.English ? styles.localeActive : ''}>
        {startCase(Locale.English)}
      </span>
    </button>
  )
}

export default LanguageSwitcher
