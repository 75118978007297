import React, { type ChangeEvent, type ReactElement, useCallback, useState } from 'react'
import { Grid, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { adminBenefitsAPI } from '../../../../api'
import { logger } from '../../../../services'
import {
  type IFormControlCategorie,
  type IFormControlEdit,
  type typesFile
} from '../../../../types'
import styles from './Categories.module.scss'

const SUPPORTED_FORMATS = [
  'image/jpg',
  'image/jpeg',
  'image/svg',
  'image/png'
]

function NewBenefits (props: IFormControlEdit): ReactElement {
  const [fileThum, setFileThum] = useState<File | string>('')
  const [fileIcon, setFileIcon] = useState<File | string>('')
  const [showLoading, setShowLoading] = useState(false)
  const [message, setMessage] = useState('')
  const { edit, idItem, saveData, dataCat } = props

  const initialValues: IFormControlCategorie = {
    pillar: edit ? dataCat?.pillar : '',
    icon: '',
    thumbnail: '',
    name: edit ? dataCat?.name : ''
  }

  const handleThum = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    const target = event.currentTarget as HTMLInputElement
    setFileThum((target.files as FileList)[0])
  }, [])

  const handleIcon = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    const target = event.currentTarget as HTMLInputElement
    setFileIcon((target.files as FileList)[0])
  }, [])

  const saveCategorie = (values: IFormControlCategorie): void => {
    const dataForm = values
    dataForm.thumbnail = fileThum
    dataForm.icon = fileIcon

    if (edit) {
      dataForm.id = idItem
    }

    setShowLoading(true)

    adminBenefitsAPI.ManagerCat(dataForm).subscribe(
      () => {
        setMessage('Categoría guardada.')
        if (saveData != null) {
          saveData(true)
        }

        setShowLoading(false)
      },
      (err) => {
        setShowLoading(false)
        setMessage('No se pudo guardar la información')
        logger.error('Error save categories:', err)
      }
    )
  }

  const CategorieSchema = Yup.object().shape({
    pillar: Yup.string().required('* Campo requerido'),
    name: Yup.string().required('* Campo requerido'),
    icon: Yup.mixed().test(
      'icon',
      'Formato no soportado',
      // @ts-expect-error: Error de tipos
      (value: typesFile) => (value !== null) || ((value !== null) && SUPPORTED_FORMATS.includes(value.type))),
    thumbnail: Yup.mixed().test(
      'thumbnail',
      'Formato no soportado',
      // @ts-expect-error: Error de tipos
      (value: typesFile) => (value !== null) || ((value !== null) && SUPPORTED_FORMATS.includes(value.type)))
  })

  const formik = useFormik({
    initialValues,
    validationSchema: CategorieSchema,
    onSubmit: (values: IFormControlCategorie) => {
      saveCategorie(values)
    }
  })

  return (
    <Grid container direction="row" className={styles.RowForm}>
      <form
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        className={styles.contentForm}
      >
        <Grid item xs={12} spacing={2} className={styles.describeForm}>
          <strong>Información principal</strong>
        </Grid>
        <Grid item xs={12} spacing={2} className={styles.RowForm}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={styles.textInput}>
              Nombre de la categoría
            </Typography>
            <OutlinedInput
              id="name"
              labelWidth={0}
              placeholder="Nombre de la categoría"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
              onBlur={formik.handleBlur}
            />
            {(formik.errors.name !== null) ? <div style={{ color: '#ff0000' }}>{formik.errors.name}</div> : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} className={styles.RowForm}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={styles.textInput}>
              Tipo de beneficio
            </Typography>
            <Select
              id="pillar"
              name="pillar"
              onChange={formik.handleChange}
              value={formik.values.pillar}
              onBlur={formik.handleBlur}
            >
              <option value="PHYSICAL">Físico</option>
              <option value="SOCIAL">Socioemocional</option>
              <option value="ECONOMIC">Finaciero</option>
            </Select>
            {(formik.errors.pillar !== null) ? <div style={{ color: '#ff0000' }}>{formik.errors.pillar}</div> : null}
          </FormControl>
        </Grid>

        <Grid item xs={12} spacing={2} className={styles.RowForm}>
          <Typography className={styles.textInput}>Banner</Typography>
          <TextField
            fullWidth
            id="thumbnail"
            type="file"
            name="thumbnail"
            variant="outlined"
            onChange={handleThum}
            onBlur={formik.handleBlur}
            required={!edit}
          />
          {(formik.errors.thumbnail !== null) ? <div style={{ color: '#ff0000' }}>{formik.errors.thumbnail}</div> : null}
        </Grid>
        <Grid item xs={12} spacing={2} className={styles.RowForm}>
          <Typography className={styles.textInput}>Icono</Typography>
          <TextField
            fullWidth
            id="icon"
            type="file"
            name="icon"
            variant="outlined"
            onChange={handleIcon}
            onBlur={formik.handleBlur}
            required={!edit}
          />
          {(formik.errors.icon !== null) ? <div style={{ color: '#ff0000' }}> {formik.errors.icon}</div> : null}
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button type="submit" variant="contained" color="primary" disabled={showLoading}>
            {showLoading
              ? (
              <CircularProgress color="secondary" size={20} />
                )
              : (
                  'Guardar'
                )}
          </Button>
          {message !== '' ? <p>{message}</p> : null}
        </Grid>
      </form>
    </Grid>
  )
}

export default NewBenefits
