import { Typography } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import Grid from '@material-ui/core/Grid'
import { Close as CloseIcon } from '@material-ui/icons'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import ImgInfo from '../../../assets/images/info_modal.svg'
import { TranslationKey } from '../../../i18n/translations'
import LanguageSwitcher from '../../common/LanguageSwitcher'
import styles from './Session.module.scss'

interface IModalInfo {
  openModal: boolean
  modalClose: (open: boolean) => void
}

export default function ModalInfo (props: IModalInfo): JSX.Element {
  const { openModal, modalClose } = props

  const handleClose = (): void => {
    modalClose(false)
  }

  return (
    <Dialog
      open={openModal}
      onClose={handleClose}
      aria-labelledby="alert-dialog-title"
      aria-describedby="alert-dialog-description"
      className={styles.borderModal}
    >
      <Grid direction="row" container>
        <Grid item xs={6}>
          <LanguageSwitcher />
        </Grid>
        <Grid item xs={6} className={styles.textRight}>
          <CloseIcon color="primary" onClick={handleClose} />
        </Grid>
      </Grid>
      <DialogTitle id="alert-dialog-title">
        <Typography variant="h5" color="primary" className={styles.textCenter}>
          <FormattedMessage id={TranslationKey.SESION_MODAL_TITLE} />
        </Typography>
      </DialogTitle>
      <DialogContent>
        <DialogContentText id="alert-dialog-description">
          <Grid item xs={12} className={styles.textCenter}>
            <img src={ImgInfo} alt="Hola" />
          </Grid>

          <Grid item xs={12} className={styles.contentModal}>
            <FormattedMessage
              id={TranslationKey.SESION_MODAL_DESCRIBE}
              values={{
                CO: <strong>Colombia</strong>,
                RD: <strong>República Dominicana</strong>,
                EB: (
                  <a
                    className={styles.link}
                    href="https://www.embajadores.com.co/"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    Embajadores Beer Office
                  </a>
                ),
                tap: (
                  <a
                    className={styles.link}
                    href="https://play.google.com/store/apps/details?id=com.cnd.tapp"
                    target="_blank"
                    rel="noopener noreferrer"
                  >
                    {' '}
                    Tap
                  </a>
                ),
                MX: <strong>México</strong>
              }}
            />
          </Grid>

          <Grid item xs={12} className={styles.contentModal}>
            <FormattedMessage
              id={TranslationKey.SESION_MODAL_DESCRIBE_2}
              values={{ MX: <strong>México</strong> }}
            />
          </Grid>
        </DialogContentText>
      </DialogContent>
    </Dialog>
  )
}
