import { combineReducers } from '@reduxjs/toolkit'
import admin from '../modules/admin/slice'
import benefits from '../modules/benefits/slice'
import compensation from '../modules/compensation/slice'
import userSession from '../modules/userSession/slice'

const rootReducer = combineReducers({
  admin,
  userSession,
  compensation,
  benefits
})

export type RootState = ReturnType<typeof rootReducer>

export default rootReducer
