import { type ReactElement, useEffect } from 'react'
import { Breadcrumbs, Button, Grid, Typography } from '@material-ui/core'
import {
  GetApp as GetAppIcon,
  LaptopMac as LaptopMacIcon,
  LocationOn as LocationOnIcon,
  Mail as MailIcon,
  NavigateNext as NavigateNextIcon,
  Phone as PhoneIcon
} from '@material-ui/icons'
import DateRangeIcon from '@material-ui/icons/DateRange'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { Link } from 'react-router-dom'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { CountryCode } from '../../../enums'
import { mapBackendPublicURL } from '../../../helpers/util-functions'
import { TranslationKey } from '../../../i18n/translations'
import { getUserCountryCode } from '../../userSession/slice'
import { useBenefitByID, useCategoryByID, useFetchCategories } from '../hooks'
import { fetchBenefitDetails } from '../slice'
import styles from './BenefitDetails.module.scss'

function BenefitDetails (): ReactElement {
  const dispatch = useDispatch()
  const { benefitID } = useParams()
  const benefit = useBenefitByID(Number(benefitID))
  const category = useCategoryByID(benefit?.categoryID ?? 0)

  useFetchCategories()
  useEffect(() => {
    if (benefit != null) return
    dispatch(
      fetchBenefitDetails({
        benefitID: Number(benefitID)
      })
    )
  }, [benefit, benefitID, dispatch])

  const userCountryCode = useSelector(getUserCountryCode)

  return (
    <Grid
      container
      className={styles.container}
      alignItems="center"
      direction="column"
    >
      <Grid item xs={8} sm={12} className={styles.titleContainer}>
        <Typography variant="h5">
          <FormattedMessage id={TranslationKey.BENEFITS} />
        </Typography>

        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Link to="/" className={styles.breadcrumb}>
            <FormattedMessage id={TranslationKey.HOME} />
          </Link>
          <Link to="/benefits" className={styles.breadcrumb}>
            <FormattedMessage id={TranslationKey.BENEFITS} />
          </Link>
          <Typography color="textPrimary" className={styles.categoryLink}>
            {(benefit != null) ? benefit.title : '...'}
          </Typography>
        </Breadcrumbs>
      </Grid>

      {(benefit == null)
        ? (
        <Grid item xs={11} className={styles.spinnerContainer}>
          <LoadingSpinner />
        </Grid>
          )
        : (
        <>
          <Grid item xs={12} sm={10} md={6}>
            {(category != null) && (
              <img
                className={styles.coverImage}
                src={category.imageURL}
                alt={`${category.title} cover`}
              />
            )}
          </Grid>

          <Grid item xs={11} sm={8} md={6}>
            <br />
            <Typography variant="h6" color="primary">
              {benefit.title}
            </Typography>
            <Typography variant="body2" className={styles.SpaceLetterS}>{benefit.description}</Typography>

            <br />

            <Typography className={styles.customTitle}>
              <FormattedMessage id={TranslationKey.PROVIDER} />
            </Typography>
            <img
              className={styles.providerLogo}
              src={benefit.providerImgURL}
              alt={`${benefit.title} logo`}
            />

            {(benefit?.attached !== null) && (
              <>
                {userCountryCode === CountryCode.Mexico && (
                  <div className={styles.linkInstructions}>
                    <GetAppIcon fontSize="large" color="primary" />
                    <a
                      href={mapBackendPublicURL(benefit?.attached)}
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      <FormattedMessage
                        id={TranslationKey.PORTAL_INSTRUCTIONS}
                      />
                    </a>
                  </div>
                )}

                {userCountryCode === CountryCode.Honduras && (
                  <div className={styles.linkInstructions}>
                    <GetAppIcon fontSize="large" color="primary" />
                    <a
                      href={mapBackendPublicURL(benefit?.attached)}
                      target="_blank"
                      rel="noreferrer"
                      download
                    >
                      <FormattedMessage id={TranslationKey.FORMAT} />
                    </a>
                  </div>
                )}
              </>
            )}

            {((benefit?.phone !== null) ||
              (benefit?.address !== null) ||
              (benefit?.email !== null) ||
              (benefit?.websiteURL !== null)) && (
              <>
                <Typography className={styles.customTitle}>
                  <br />
                  <FormattedMessage id={TranslationKey.CONTACT} />
                </Typography>

                {(benefit?.phone !== null) && (
                  <div className={styles.contactItem}>
                    <PhoneIcon color="primary" />
                    <span>
                      <FormattedMessage id={TranslationKey.PHONE} />
                    </span>
                    {benefit?.phone}
                  </div>
                )}

                {(benefit?.address !== null) && (
                  <div className={styles.contactItem}>
                    <LocationOnIcon color="primary" />
                    <span>
                      <FormattedMessage id={TranslationKey.ADDRESS} />
                    </span>
                    {benefit?.address}
                  </div>
                )}

                {(benefit?.email !== null) && (
                  <div className={styles.contactItem}>
                    <MailIcon color="primary" />
                    <span>Email</span>
                    <a
                      href={`mailto:${benefit?.email}`}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {benefit?.email}
                    </a>
                  </div>
                )}

                {(benefit?.websiteURL !== null) && (
                  <div className={styles.contactItem}>
                    <LaptopMacIcon color="primary" />
                    <span>
                      <FormattedMessage id={TranslationKey.WEBSITE} />
                    </span>
                    {(benefit?.websiteURL !== null) && (
                      <a
                        href={benefit.websiteURL}
                        target="_blank"
                        rel="noopener noreferrer"
                      >
                        {benefit.websiteURL}
                      </a>
                    )}
                  </div>
                )}
              </>
            )}

            <div className={styles.contactItem}>
              <DateRangeIcon color="primary" />
              <span>
                <FormattedMessage id={TranslationKey.UPDATE_DATE} />:
              </span>
              <p
                style={{
                  color: '#ea6c00',
                  marginLeft: 10
                }}
                color="primary"
              >
                {new Date(benefit.updated_at).toISOString().split('T')[0]}
              </p>
            </div>
          </Grid>

          <Grid item xs={11} md={10} className={styles.buttonContainer}>
            <Link to="/benefits">
              <Button variant="outlined" color="primary">
                <FormattedMessage id={TranslationKey.VIEW_MORE_BENEFITS} />
              </Button>
            </Link>
          </Grid>
        </>
          )}
    </Grid>
  )
}

export default BenefitDetails
