import { Container, Grid, Typography } from '@material-ui/core'
import InfoIcon from '@material-ui/icons/Info'
import React from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import iconCalcluladora from '../../../assets/images/Icono_Calculadora_Home.svg'
import iconCertificados from '../../../assets/images/Icono_Certificados_Home.svg'
import iconNomina from '../../../assets/images/Icono_Nomina_Home.svg'
import iconVacaciones from '../../../assets/images/Icono_Vacaciones_Home.svg'
import iconMinu from '../../../assets/images/Logo_Minu_Home.svg'
import { TranslationKey } from '../../../i18n/translations'
import { getUser } from '../../userSession/slice'
import styles from './HomeCards.module.scss'

function HomeCards (): JSX.Element {
  interface cardProps {
    link: string
    icon: string
    text: string
    show: boolean
  }

  const cards: cardProps[] = [
    {
      link: '/compensation',
      icon: iconCalcluladora,
      text: TranslationKey.CALCULATE_YOUR_COMPENSATION,
      show: true
    },
    {
      link: '/payroll',
      icon: iconNomina,
      text: TranslationKey.YOUR_PAYROLL_RECEIPTS,
      show: true
    }
    // ,
    // {
    //   link: '/certificates',
    //   icon: iconCertificados,
    //   text: TranslationKey.YOUR_EMPLOYMENT_CERTIFICATES,
    //   show: true
    // }
    // ,
    // {
    //   link: '/vacations',
    //   icon: iconVacaciones,
    //   text: TranslationKey.REVIEW_VACATIONS,
    //   show: true
    // }
  ]

  const user = useSelector(getUser)

  return (
    <Container maxWidth="lg" className={styles.cardsContainer}>
      <Grid container spacing={3}>
        {user?.country === 'MX' && (
          <Grid item xs={6} md={6} lg={4}>
            <Link to="/minu" className={styles.cardFull}>
              <InfoIcon className={styles.iconInfo} />
              <div className={styles.cardFullContent}>
                <img src={iconMinu} alt="minu" />
                <Typography className={styles.cardFullText}>
                  <FormattedMessage
                    id={TranslationKey.WITHDRAW_YOUR_SALARY_WITH_MINU}
                  />
                </Typography>
              </div>
            </Link>
          </Grid>
        )}
        {cards
          .filter((card) => card.show)
          .map((card) => (
            <Grid item xs={6} md={6} lg={4} key={card.text}>
              <Link to={card.link} className={styles.card}>
                <img
                  src={card.icon}
                  alt={card.text}
                  className={styles.cardIcon}
                />
                <Typography className={styles.cardText}>
                  <FormattedMessage id={card.text} />
                </Typography>
              </Link>
            </Grid>
          ))}
      </Grid>
    </Container>
  )
}
export default HomeCards
