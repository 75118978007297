import { type ReactElement, useCallback, useEffect, useState } from 'react'
import { Avatar, Button, Typography } from '@material-ui/core'
import ContactSupportIcon from '@material-ui/icons/ContactSupport'
import ExitToAppIcon from '@material-ui/icons/ExitToApp'
import { startCase } from 'lodash-es'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link, useLocation } from 'react-router-dom'
import { TranslationKey } from '../../../i18n/translations'
import { getUser, logoutUserRequest } from '../../userSession/slice'
import styles from './MenuProfile.module.scss'

function MenuProfile (): ReactElement {
  const location = useLocation()
  const dispatch = useDispatch()

  const [showProfile, setShowProfile] = useState(false)

  const user = useSelector(getUser)

  const name = user?.nickname ?? user?.name
  const sharp = user?.sharp
  const email = user?.email
  const position = startCase((user?.position ?? '').toLocaleLowerCase())

  const handleCloseSessionClick = useCallback(() => {
    dispatch(logoutUserRequest())
  }, [dispatch])

  const handleShowProfile = (): void => {
    setShowProfile(!showProfile)
  }

  useEffect(() => {
    setShowProfile(false)
  }, [location])

  return (
    <div className={styles.navbarProfile}>
      <div className={styles.navbarPofilePhoto}>
        <Avatar alt={name} src={user?.avatar} onClick={handleShowProfile}>
          {name !== undefined
            ? name
              .split(' ')
              .map((part) => part[0])
              .slice(0, 2)
              .join('')
            : ''}
        </Avatar>
      </div>
      {showProfile && (
        <div className={styles.menuProfileContainer}>
          <Avatar
            alt={name}
            src={user?.avatar}
            className={styles.avatarProfile}
          >
            {name !== undefined
              ? name
                .split(' ')
                .map((part) => part[0])
                .slice(0, 2)
                .join('')
              : ''}
          </Avatar>
          <Typography className={styles.profileName}>{name}</Typography>
          <Typography className={styles.profileText}>{position}</Typography>
          <Typography className={styles.profileText}>{sharp}</Typography>
          <Typography className={styles.profileText}>{email}</Typography>
          <Link to="/profile/me" className={styles.profileButton}>
            <Button variant="outlined">
              <FormattedMessage id={TranslationKey.MY_PROFILE} />
            </Button>
          </Link>
          <ul className={styles.profileMenuList}>
            <li>
              <Link to="/external/help">
                <ContactSupportIcon />
                <FormattedMessage id={TranslationKey.HELP} />
              </Link>
            </li>
            <li>
              <Link to="/" onClick={handleCloseSessionClick}>
                <ExitToAppIcon />
                <FormattedMessage id={TranslationKey.CLOSE_SESSION}/> 
              </Link>
            </li>
          </ul>
        </div>
      )}
    </div>
  )
}
export default MenuProfile
