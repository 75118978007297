import { type ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Route, Routes } from 'react-router'
import Certifications from '../../../components/Certifications'
import Holidays from '../../../components/Holidays'
import LoadingSpinner from '../../../components/LoadingSpinner'
import Ticket from '../../../components/Ticket'
import ControlPanel from '../../admin/ControlPanel'
import ListBenefits from '../../admin/managerBenefits/categories/ListBenefits'
import Categories from '../../admin/managerBenefits/panelControl'
import PanelControl from '../../admin/managerBenefits/panelControl/PanelControl'
import Reports from '../../admin/Reports'
import UsersAdmin from '../../admin/UsersAdmin'
import BenefitDetails from '../../benefits/BenefitDetails'
import Benefits from '../../benefits/Benefits/Benefits'
import CategoryView from '../../benefits/CategoryView'
import Compensation from '../../compensation/Compensation'
import CompensationBenefits from '../../compensation/Compensation/CompensationBenefits'
import InitPanel from '../../compensation/Compensation/InitPanel'
import SecondPanel from '../../compensation/Compensation/SecondPanel'
import ThirdPanel from '../../compensation/Compensation/ThirdPanel'
import EditProfile from '../../userSession/EditProfile'
import { useInactivityTimer } from '../../userSession/hooks'
import {
  fetchUserSession,
  getFetching,
  getUser
} from '../../userSession/slice'
import UserProfile from '../../userSession/UserProfile'
import Footer from '../Footer'
import Home from '../Home'
import Menu from '../Menu'
import styles from './Dashboard.module.scss'
import { PayrollAdmin } from '../../admin/payroll-admin/Payroll-admin'

function Dashboard (): ReactElement {
  const hasUser = !(useSelector(getUser) == null)
  const fetching = useSelector(getFetching)

  // Fetch user session on startup
  const dispatch = useDispatch()
  useEffect(() => {
    if (fetching && !hasUser) {
      dispatch(fetchUserSession())
    }
  }, [dispatch, fetching, hasUser])

  useInactivityTimer()

  return fetching
    ? (
    <div className="full-spinner-container">
      <LoadingSpinner />
    </div>
      )
    : (
    <div className={styles.container}>
      <Menu />
      <div className={styles.content}>
        <Routes>
          <Route path="" element={<Home />} />
          <Route path="compensation" element={<Compensation />}>
            <Route path="" element={<InitPanel />} />
            <Route path="benefits" element={<CompensationBenefits />} />
            <Route path="bonus" element={<SecondPanel />} />
            <Route path="achievement" element={<ThirdPanel />} />
          </Route>

          <Route path="vacations" element={<Holidays />} />
          <Route path="benefits" element={<Benefits />} />
          <Route path="certificates" element={<Certifications />} />

          <Route path="payroll" element={<Ticket />} />

          <Route
            path="benefits/categories/:categoryID"
            element={<CategoryView />}
          />
          <Route path="benefits/:benefitID" element={<BenefitDetails />} />

          <Route path="profile">
            <Route path="me" element={<UserProfile />} />
            <Route path="edit" element={<EditProfile />} />
          </Route>

          <Route path="admin" element={<ControlPanel />}>
            <Route path="users" element={<UsersAdmin />} />
            <Route path="reports" element={<Reports />} />
            <Route path="benefits" element={<Categories />} />
            <Route path="benefits/panel" element={<PanelControl />} />
            <Route
              path="benefits/panel/list/:benefitID"
              element={<ListBenefits />}
            />
          <Route path="payroll" element={<PayrollAdmin />} />
          </Route>
        </Routes>
      </div>
      <Footer />
    </div>
      )
}

export default Dashboard
