import { Typography } from '@material-ui/core'
import React, { type FunctionComponent, type RefObject } from 'react'
import { FormattedMessage } from 'react-intl'
import GeneralModal from '../../../components/GeneralModal'
import { TranslationKey } from '../../../i18n/translations'

interface BenefitsHelpModalProps {
  buttonRef: RefObject<HTMLElement>
}

const BenefitsHelpModal: FunctionComponent<BenefitsHelpModalProps> = ({
  buttonRef
}) => (
  <GeneralModal isOpen buttonRef={buttonRef} maxWidth={400}>
    <Typography
      variant="h5"
      color="primary"
      style={{
        padding: '0 20px',
        marginTop: 30,
        marginBottom: 10
      }}
    >
      <FormattedMessage id={TranslationKey.BENEFITS_MODAL_TITLE1} />
    </Typography>

    <Typography variant="body1">
      <FormattedMessage id={TranslationKey.BENEFITS_MODAL_TEXT1} />
    </Typography>

    <Typography
      variant="h5"
      color="primary"
      style={{
        padding: '0 20px',
        marginTop: 40,
        marginBottom: 10
      }}
    >
      <FormattedMessage id={TranslationKey.BENEFITS_MODAL_TITLE2} />
    </Typography>

    <Typography variant="body1">
      <FormattedMessage id={TranslationKey.BENEFITS_MODAL_TEXT2} />
    </Typography>
  </GeneralModal>
)

export default BenefitsHelpModal
