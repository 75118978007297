import { type ReactElement, useRef } from 'react'
import { Link } from 'react-router-dom'
import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import { Help as HelpIcon } from '@material-ui/icons'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import { useCurrencyFormatter } from '../../../helpers/hooks'
import { TranslationKey } from '../../../i18n/translations'
import { TipHelpModal } from '../HelpModals'
import { getBenefitsTotal, getMappedCompensationBenefits } from '../slice'
import styles from './Compensation.module.scss'

function CompensationBenefits (): ReactElement {
  const benefitsRef = useRef(null)
  const benefitsTotal = useSelector(getBenefitsTotal)
  const compensationBenefits = useSelector(getMappedCompensationBenefits)
  const currencyFormatter = useCurrencyFormatter()

  return (
    <>
      <Grid item xs={12} className={styles.titleContainer}>
        <Typography variant="h5">
          <FormattedMessage id={TranslationKey.BENEFITS} />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage id={TranslationKey.BENEFITS_P} />
        </Typography>
      </Grid>

      <TipHelpModal
        buttonRef={benefitsRef}
        title={<FormattedMessage id={TranslationKey.BENEFITS_TOTAL} />}
        text={<FormattedMessage id={TranslationKey.BENEFITS_TOTAL_HELP} />}
      />

      <Grid
        item
        xs={12}
        sm={6}
        md={6}
        lg={5}
        className={`${styles.panelContainer} ${styles.noBorder}`}
      >
        <div className={styles.totalCard}>
          <IconButton ref={benefitsRef} color="primary" size="small">
            <HelpIcon fontSize="small" />
          </IconButton>
          <Typography variant="subtitle1">
            <FormattedMessage id={TranslationKey.BENEFITS_TOTAL} />
          </Typography>
          <Typography variant="h5" color="primary">
            {currencyFormatter.format(benefitsTotal)}
          </Typography>
        </div>
        {
        // @ts-expect-error Prefiere otro tipo de dato
        compensationBenefits.map((benefit: any) => (
          <div key={benefit.name} className={styles.benefitCard}>
            <Typography variant="h6">{benefit.name}</Typography>
            <Typography variant="body2">{benefit.help}</Typography>
            <Typography
              className={styles.benefitValue}
              variant="subtitle1"
              color="primary"
            >
              {currencyFormatter.format(benefit.value)}
            </Typography>
          </div>
        ))
        }

        <div className={styles.viewAllBenefits}>
          <Typography variant="body2">
            <FormattedMessage id={TranslationKey.BENEFITS_P2} />
          </Typography>

          <Link to="/compensation">
            <Button
              variant="outlined"
              color="primary"
              className={styles.bttnBack}
            >
              <FormattedMessage id={TranslationKey.MY_COMPENSATION} />
            </Button>
          </Link>

          <Link to="/benefits">
            <Button variant="outlined" color="primary">
              <FormattedMessage id={TranslationKey.VIEW_MORE_BENEFITS} />
            </Button>
          </Link>
        </div>
      </Grid>
    </>
  )
}

export default CompensationBenefits
