import React, {
  type FunctionComponent,
  type ReactElement,
  type ReactNode,
  useEffect,
  useState
} from 'react'
import { useDispatch } from 'react-redux'
import { Router } from 'react-router'
import history from '../history'

interface RouterWrapperProps {
  children: ReactNode
}

const RouterWrapper: FunctionComponent<RouterWrapperProps> = ({
  children
}): ReactElement => {
  const [location, setLocation] = useState(history.location)
  const dispatch = useDispatch()

  useEffect(() => {
    const unListen = history.listen((update) => {
      window.scrollTo(0, 0)
      setLocation(update.location)
    })
    return () => {
      unListen()
    }
  }, [dispatch])

  return (
    <Router navigator={history} location={location}>
      {children}
    </Router>
  )
}

export default RouterWrapper
