import React, { type ReactElement, useRef } from 'react'
import { Outlet } from 'react-router'
import Footer from '../Footer'
import Menu from '../Menu'
import styles from './External.module.scss'

function External (): ReactElement {
  const contentRef = useRef(null)
  return (
    <div className={styles.container}>
      <Menu />
      <div ref={contentRef} className={styles.content}>
        <Outlet />
      </div>
      <Footer />
    </div>
  )
}
export default External
