import {
  CLIENT_ID,
  SCOPE,
  URL_AUTHORIZE,
  URL_CALLBACK
} from '../../../config/environment'

const generateRandomString = (length: number): string => {
  let text = ''
  const possible =
    'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789'
  for (let i = 0; i < length; i += 1) {
    text += possible.charAt(Math.floor(Math.random() * possible.length))
  }
  return text
}

const generateState = (): string => {
  const randomState = generateRandomString(40)
  sessionStorage.setItem('state', randomState)
  return randomState
}

export const redirectSSO = (): any => {
  // @ts-expect-error: Problemas de tipos
  const parameters = new URLSearchParams({
    response_type: 'code',
    client_id: CLIENT_ID,
    redirect_uri: URL_CALLBACK,
    state: generateState(),
    scope: SCOPE
  })
  window.location.href = `${URL_AUTHORIZE ?? ''} ${parameters.toString()}`
}
