import { Grid, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import TextField from '@material-ui/core/TextField'
import { useFormik } from 'formik'
import React, { type ChangeEvent, useCallback, useState } from 'react'
import * as Yup from 'yup'
import { adminBenefitsAPI } from '../../../../api'
import {
  type IFormControlEdit,
  type ISuppliers,
  type typesFile
} from '../../../../types'
import styles from './Providers.module.scss'

export default function NewProvider (props: IFormControlEdit): JSX.Element {
  const [file, setFile] = useState<string | File>('')
  const [showLoading, setShowLoading] = useState(false)
  const [message, setMessage] = useState('')
  const { edit, idItem, providerName, saveData } = props

  const saveBenefit = (values: ISuppliers): void => {
    const dataForm = values
    dataForm.logo = file

    if (edit) {
      dataForm.id = idItem
    }

    setShowLoading(true)
    adminBenefitsAPI.ManagerSupplier(dataForm).subscribe(
      () => {
        setMessage('Datos guardados.')
        setShowLoading(false)
        if (saveData != null) {
          saveData(true)
        }
      },
      () => {
        setShowLoading(false)
        setMessage('No se pudo guardar el proveedor, intenta nuevamente')
      }
    )
  }

  const handleThum = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    const target = event.currentTarget as HTMLInputElement
    setFile((target.files as FileList)[0])
  }, [])

  const SUPPORTED_FORMATS = [
    'image/jpg',
    'image/jpeg',
    'image/svg',
    'image/png'
  ]

  const supplierSchema = Yup.object().shape({
    name: Yup.string().required('* Campo requerido'),
    logo: Yup.mixed()
      .test(
        'logo',
        'Formato no soportado',
        // @ts-expect-error: Error de tipos
        (value: typesFile) => (value !== null) || ((value !== null) && SUPPORTED_FORMATS.includes(value.type)))
      .nullable()
  })

  const formik = useFormik({
    initialValues: {
      logo: '',
      name: edit ? String(providerName) : ''
    },
    validationSchema: supplierSchema,
    onSubmit: (values) => {
      saveBenefit(values)
    }
  })

  return (
    <Grid container direction="row" className={styles.RowForm}>
      <form
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        className={styles.contentForm}
      >
        <Grid item xs={12} className={styles.describeForm}>
          <strong>Información principal</strong>
        </Grid>
        <Grid item xs={12} className={styles.RowForm}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={styles.textInput}>
              Nombre del proveedor
            </Typography>
            <OutlinedInput
              labelWidth={0}
              placeholder="Nombre del proveedor"
              name="name"
              onChange={formik.handleChange}
              value={formik.values.name}
            />
            {(formik.errors.name !== null)
              ? (
              <div style={{ color: '#ff0000' }}> {formik.errors.name}</div>
                )
              : null}
          </FormControl>
        </Grid>

        <Grid item xs={12} className={styles.RowForm}>
          <Typography className={styles.textInput}>Logo</Typography>
          <TextField
            fullWidth
            id="outlined-basic"
            type="file"
            name="logo"
            variant="outlined"
            onChange={handleThum}
            required={!edit}
          />
          {(formik.errors.logo !== null)
            ? (
            <div style={{ color: '#ff0000' }}> {formik.errors.logo}</div>
              )
            : null}
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            disabled={showLoading}
          >
            {showLoading
              ? (
              <CircularProgress color="secondary" size={20} />
                )
              : (
                  'Guardar'
                )}
          </Button>
          {message !== '' ? <p>{message}</p> : null}
        </Grid>
      </form>
    </Grid>
  )
}
