import { shouldPolyfill } from '@formatjs/intl-listformat/should-polyfill'

export default async function polyfill (): Promise<void> {
  // This platform already supports Intl.ListFormat
  if (typeof shouldPolyfill() !== 'undefined' && shouldPolyfill() !== null) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-listformat/polyfill')
  }

  if ((Intl.ListFormat as any).polyfilled !== null) {
    await import('@formatjs/intl-listformat/locale-data/es')
    await import('@formatjs/intl-listformat/locale-data/en')
  }
}
