import React from 'react'
import logo from '../../assets/images/logo-spinner.svg'
import styles from './LoadingSpinner.module.scss'

function LoadingSpinner (): JSX.Element {
  return <div className={styles.loadingContainer}>
    <div className={styles.loadingInfo}>
      <img src={logo} alt="logo" className={styles.loadingLogo} />
      <div className={styles.progressBarContainer}>
        <div className={styles.progressBar} style={{ width: '80%' }} />
      </div>
    </div>
  </div>
}
export default LoadingSpinner
