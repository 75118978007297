import { Grid } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import TextField from '@material-ui/core/TextField'
import React, { type ChangeEvent, useCallback, useState } from 'react'
import styles from '../categories/Categories.module.scss'
import ListProviders from '../providers/ListProviders'
import NewProvider from '../providers/ModalFormProviders'

export default function PanelProvider (): JSX.Element {
  const [keySearch, setKeySearch] = useState('')
  const [saveData, setSaveData] = useState(false)

  const hadleSearch = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    setKeySearch(String(event.target.value))
  }, [])

  const handleSaveCategorie = useCallback((save: boolean) => {
    setSaveData(save)
  }, [])

  return (
    <Grid container direction="row" spacing={2} className={styles.container}>
      <Grid item xs={9} className={styles.paddingRows}>
        <FormControl fullWidth variant="outlined">
          <TextField
            id="outlined-search"
            onChange={hadleSearch}
            label="Buscar proveedor"
            type="search"
            variant="outlined"
          />
        </FormControl>
      </Grid>
      <Grid item xs={3} className={styles.paddingRows}>
        <NewProvider
          nameBtn="Nuevo proveedor"
          showForm
          idRow={1}
          edit={false}
          saveData={handleSaveCategorie}
        />
      </Grid>

      <Grid
        item
        xs={12}
        lg={12}
        md={12}
        sm={12}
        className={styles.categoriesRow}
      >
        <ListProviders keySearch={keySearch} saveData={saveData}/>
      </Grid>
    </Grid>
  )
}
