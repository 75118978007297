import {
  Button,
  Grid,
  IconButton,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import { Search as SearchIcon } from '@material-ui/icons'
import React, {
  type ChangeEvent,
  type KeyboardEvent,
  type ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { Link } from 'react-router-dom'
import noResultsImg from '../../../assets/images/no-results.png'
import PillarFisico from '../../../assets/images/pilar-1.svg'
import PillarSocial from '../../../assets/images/pilar-2.svg'
import PillarFinanciero from '../../../assets/images/pilar-3.svg'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { AppColor, BenefitPillar } from '../../../enums'
import { useQueryParams } from '../../../helpers/hooks'
import { TranslationKey } from '../../../i18n/translations'
import MenuMobile from '../../common/MenuMobile'
import BenefitCard from '../BenefitCard'
import BenefitCategoryCard from '../BenefitCategoryCard'
import { useFetchCategories } from '../hooks'
import {
  fetchBenefits,
  getBenefits,
  getCategories,
  getFetchingBenefits,
  getFetchingCategories
} from '../slice'
import styles from './Benefits.module.scss'

const useInputStyles = makeStyles({
  adornedEnd: {
    paddingRight: 4
  }
})
const useButtonStyles = makeStyles({
  root: {
    color: AppColor.White,
    backgroundColor: `${AppColor.Primary} !important`,
    height: 34,
    width: 34,
    transition: 'none',
    '&hover': {
      color: AppColor.White,
      backgroundColor: AppColor.Primary
    }
  }
})

function Benefits (): ReactElement {
  const inputRef = useRef<HTMLInputElement>(null)
  const dispatch = useDispatch()

  const fetchingCategories = useSelector(getFetchingCategories)
  const categories = useSelector(getCategories)
  const fetchingBenefits = useSelector(getFetchingBenefits)
  const benefits = useSelector(getBenefits)

  useFetchCategories()

  const queryParams = useQueryParams()
  const initSearch = queryParams.get('search')
  const [textFilter, setTextFilter] = useState(initSearch ?? '')

  useEffect(() => {
    if (initSearch !== null) return
    dispatch(
      fetchBenefits({
        textFilter: initSearch ?? ''
      })
    )
  }, [dispatch, initSearch])

  const handleFilterTextChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = event.target.value
      setTextFilter(newValue)
    },
    []
  )

  const location = useLocation()
  const navigate = useNavigate()

  const handleKeyDown = useCallback(
    (event: KeyboardEvent) => {
      if (event.key === 'Enter') {
        navigate(`${location.pathname}?search=${textFilter}`)
      } else if (event.key === 'Escape') {
        setTextFilter('')
      }
    },
    [location.pathname, navigate, textFilter]
  )

  const handleSearchClick = useCallback(() => {
    const url = (textFilter !== null)
      ? `${location.pathname}?search=${textFilter}`
      : location.pathname
    navigate(url)
  }, [location.pathname, navigate, textFilter])

  const handleStartAgainClick = useCallback(() => {
    setTextFilter('')
    navigate(location.pathname)
    const inputElement = inputRef.current
    if (inputElement != null) inputElement.focus()
  }, [location.pathname, navigate])

  const inputClasses = useInputStyles()
  const buttonClasses = useButtonStyles()

  const findPillarF = categories.filter(
    (cat) => cat.pillar === BenefitPillar.Physical
  )
  const findPillarS = categories.filter(
    (cat) => cat.pillar === BenefitPillar.Social
  )
  const findPillarFn = categories.filter(
    (cat) => cat.pillar === BenefitPillar.Economic
  )

  return (
    <>
      <MenuMobile
        title={<FormattedMessage id={TranslationKey.BENEFITS} />}
        describe={<FormattedMessage id={TranslationKey.ANNUAL_TOTAL} />}
        expand={false}
      />
      <Grid
        container
        className={styles.container}
        alignItems="flex-start"
        justifyContent="center"
        direction="row"
      >
        <Grid item xs={12} className={styles.titleContainer}>
          <Typography variant="h5" className={styles.titleBenefits}>
            <FormattedMessage id={TranslationKey.BENEFITS} />
          </Typography>
          <Typography variant="body2">
            <FormattedMessage id={TranslationKey.BENEFITS_INSTRUCTIONS} />
          </Typography>
          <div className={styles.searchContainer}>
            <TextField
              inputRef={inputRef}
              className={styles.search}
              value={textFilter}
              placeholder="Buscar texto"
              onChange={handleFilterTextChange}
              onKeyDown={handleKeyDown}
              fullWidth
              variant="outlined"
              size="small"
              InputProps={{
                classes: inputClasses,
                endAdornment: (
                  <IconButton
                    classes={buttonClasses}
                    color="primary"
                    onClick={handleSearchClick}
                  >
                    <SearchIcon />
                  </IconButton>
                )
              }}
            />
          </div>
        </Grid>

        {(initSearch !== null) &&
          (fetchingCategories
            ? (
            <LoadingSpinner />
              )
            : (
            <Grid
              container
              alignItems="flex-start"
              className={styles.categoriesContainer}
            >
              {findPillarF.length > 0
                ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className={styles.pillarContent}
                >
                  <div className={styles.pillarF}>
                    <div className={styles.titlePillar}>
                      <img src={PillarSocial} alt="Pillar" />
                      <span>
                        <Typography variant="body2">
                          <FormattedMessage id={TranslationKey.PHYSICAL} />
                        </Typography>
                      </span>
                    </div>
                  </div>
                  <Grid container className={styles.contentPillar}>
                    {findPillarF.map((category) => (
                      <BenefitCategoryCard key={category.id} {...category} />
                    ))}
                  </Grid>
                </Grid>
                  )
                : (
                    ''
                  )}

              {findPillarS.length > 0
                ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className={styles.pillarContent}
                >
                  <div className={styles.pillarS}>
                    <div className={styles.titlePillar}>
                      <img src={PillarFisico} alt="Pillar" />
                      <span>
                        <Typography variant="body2">
                          <FormattedMessage
                            id={TranslationKey.SOCIO_EMOTIONAL}
                          />
                        </Typography>
                      </span>
                    </div>
                  </div>
                  <Grid container className={styles.contentPillar}>
                    {findPillarS.map((category) => (
                      <BenefitCategoryCard key={category.id} {...category} />
                    ))}
                  </Grid>
                </Grid>
                  )
                : (
                    ''
                  )}

              {findPillarFn.length > 0
                ? (
                <Grid
                  item
                  xs={12}
                  sm={6}
                  md={4}
                  className={styles.pillarContent}
                >
                  <div className={styles.pillarFi}>
                    <div className={styles.titlePillar}>
                      <img src={PillarFinanciero} alt="Pillar" />
                      <span>
                        <Typography variant="body2">
                          <FormattedMessage id={TranslationKey.FINANCIAL} />
                        </Typography>
                      </span>
                    </div>
                  </div>
                  <Grid container className={styles.contentPillar}>
                    {findPillarFn.map((category) => (
                      <BenefitCategoryCard key={category.id} {...category} />
                    ))}
                  </Grid>
                </Grid>
                  )
                : (
                    ''
                  )}
            </Grid>
              ))}

        {(initSearch === null) &&
          (fetchingBenefits
            ? (
            <LoadingSpinner />
              )
            : (
            <Grid item xs={12} className={styles.benefitsPanel}>
              <Typography variant="h6">
                <FormattedMessage id={TranslationKey.RESULTS} />
              </Typography>
              {(typeof benefits !== 'undefined')
                ? (
                <>
                  <div className={styles.benefitsContainer}>
                    {benefits.map((benefit) => (
                      <BenefitCard key={benefit.id} {...benefit} />
                    ))}
                  </div>

                  <Grid item xs={12}>
                    <Link to="/benefits">
                      <Button variant="contained" className={styles.btnBack}>
                        <FormattedMessage id={TranslationKey.CATEGORY_BACK} />
                      </Button>
                    </Link>
                  </Grid>
                </>
                  )
                : (
                <div className={styles.noResultsContainer}>
                  <Typography variant="body2">
                    <FormattedMessage
                      id={TranslationKey.NO_RESULTS_INSTRUCTIONS}
                      values={{
                        textFilter: initSearch ?? ''
                      }}
                    />
                  </Typography>

                  <button
                    type="button"
                    className={`link-button ${styles.startAgain}`}
                    onClick={handleStartAgainClick}
                  >
                    <FormattedMessage id={TranslationKey.START_AGAIN} />
                  </button>

                  <img src={noResultsImg} alt="No search results" />
                </div>
                  )}
            </Grid>
              ))}
      </Grid>
    </>
  )
}

export default Benefits
