import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import Dialog from '@material-ui/core/Dialog'
import DialogActions from '@material-ui/core/DialogActions'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import DeleteOutlinedIcon from '@material-ui/icons/DeleteOutlined'
import React, { type ReactElement } from 'react'
import { type IModalDelete } from '../../../../types'
import styles from './Categories.module.scss'

function ModalDelete (props: IModalDelete): ReactElement {
  const { title, describe, id, deleteItem, close, open, showAlert, loading } =
    props

  const handleDelete = (): void => {
    deleteItem(id)
  }

  return (
    <div>
      <Button onClick={open} className={styles.BtnDelete}>
        <DeleteOutlinedIcon color="primary" />
      </Button>
      <Dialog
        open={showAlert}
        onClose={close}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.borderModal}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h5"
            color="primary"
            className={styles.textCenter}
          >
            {title}
          </Typography>
        </DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            <p>{describe}</p>
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button
            color="primary"
            variant="contained"
            value={id.toString()}
            onClick={handleDelete}
          >
            {loading
              ? (
              <CircularProgress color="secondary" size={20} />
                )
              : (
                  'Eliminar'
                )}
          </Button>
          <Button onClick={close} variant="outlined" color="primary" autoFocus>
            Cancelar
          </Button>
        </DialogActions>
      </Dialog>
    </div>
  )
}

export default ModalDelete
