import { Button, Grid, IconButton, Typography } from '@material-ui/core'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons'
import { Form, Formik } from 'formik'
import React, { type ReactElement, useCallback, useMemo, useState } from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { FormTextField } from '../../../components/FormFields'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { PasswordChangeFields, UserRole } from '../../../enums'
import { TranslationKey } from '../../../i18n/translations'
import { type PasswordChangeValues } from '../../../types'
import TranslateFormikErrors from '../../../wrappers/TranslateFormikErrors'
import CredentialsError from '../Session/CredentialsError'
import { getSending, getUserRole, passwordRecoveryRequest } from '../slice'
import styles from './EditProfile.module.scss'
import { getNewPasswordSchema } from './schemas'

const initialValues = {
  credentialsError: false,
  currentPassword: '',
  newPassword: '',
  newPasswordConfirmation: ''
}

function PasswordChangeForm (): ReactElement {
  const intl = useIntl()
  const isAdmin = useSelector(getUserRole) === UserRole.Admin

  const validationSchema = useMemo(
    () => getNewPasswordSchema(intl, isAdmin),
    [isAdmin, intl]
  )

  const sendingRequest = useSelector(getSending)

  const dispatch = useDispatch()
  const handleSubmit = useCallback(
    (values: PasswordChangeValues) => {
      dispatch(
        passwordRecoveryRequest({
          currentPassword: values.currentPassword,
          newPassword: values.newPassword
        })
      )
    },
    [dispatch]
  )

  const [showingPassword, setShowingPassword] = useState(false)
  const handleTogglePasswordClick = useCallback(() => {
    setShowingPassword(!showingPassword)
  }, [showingPassword])

  const inputType = !showingPassword ? 'password' : 'text'
  const togglePasswordButton = (
    <IconButton
      className={styles.togglePasswordButton}
      onClick={handleTogglePasswordClick}
      tabIndex={-1}
    >
      {!showingPassword
        ? (
        <VisibilityIcon color="inherit" />
          )
        : (
        <VisibilityOffIcon color="inherit" />
          )}
    </IconButton>
  )

  return (
    <Formik
      initialValues={initialValues}
      validationSchema={validationSchema}
      onSubmit={handleSubmit}
      autoComplete="off"
    >
      <Form autoComplete="off" className={styles.form}>
        <TranslateFormikErrors />
        <CredentialsError />

        <Grid
          container
          justifyContent="center"
          direction="column"
          className={styles.formContainer}
        >
          <Grid item xs={12}>
            <Typography variant="h5" className={styles.formTitle}>
              <FormattedMessage id={TranslationKey.CHANGE_PWD} />
            </Typography>
          </Grid>
          <Grid item xs={12}>
            <FormTextField
              name={PasswordChangeFields.CurrentPassword}
              label={intl.formatMessage({
                id: TranslationKey.CURRENT_PWD
              })}
              disabled={sendingRequest}
              type={inputType}
              endElement={togglePasswordButton}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <FormTextField
              name={PasswordChangeFields.NewPassword}
              label={intl.formatMessage({
                id: TranslationKey.NEW_PWD
              })}
              disabled={sendingRequest}
              type={inputType}
              endElement={togglePasswordButton}
              fullWidth
            />
          </Grid>

          <Grid item xs={12}>
            <FormTextField
              name={PasswordChangeFields.NewPasswordConfirmation}
              label={intl.formatMessage({
                id: TranslationKey.REPEAT_NEW_PWD
              })}
              disabled={sendingRequest}
              type={inputType}
              endElement={togglePasswordButton}
              fullWidth
            />
          </Grid>

          <Grid item xs={12} className={styles.buttonsContainer}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={sendingRequest}
              style={{ color: '#000' }}
            >
              {sendingRequest && <LoadingSpinner />}
              <FormattedMessage
                id={
                  sendingRequest
                    ? TranslationKey.CHANGING
                    : TranslationKey.CHANGE_PWD
                }
              />
            </Button>
          </Grid>
        </Grid>
      </Form>
    </Formik>
  )
}

export default PasswordChangeForm
