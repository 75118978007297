import { Typography } from '@material-ui/core'
import React, { type FunctionComponent, type ReactNode, type RefObject } from 'react'
import GeneralModal from '../../../components/GeneralModal'

interface TipHelpModalProps {
  buttonRef?: RefObject<HTMLElement>
  buttonId?: string
  title: string | ReactNode
  text: string | ReactNode
}

const TipHelpModal: FunctionComponent<TipHelpModalProps> = ({
  buttonRef,
  buttonId,
  title,
  text
}) => (
  <GeneralModal
    isOpen
    buttonRef={buttonRef}
    buttonId={buttonId}
    maxWidth={400}
    minHeight={150}
  >
    <Typography
      variant="h6"
      style={{
        fontWeight: 'bold',
        textAlign: 'left',
        width: '100%',
        marginBottom: 5
      }}
    >
      {title}
    </Typography>
    <Typography
      variant="body1"
      style={{
        textAlign: 'left',
        whiteSpace: 'pre-wrap',
        width: '100%'
      }}
    >
      {text}
    </Typography>
  </GeneralModal>
)

export default TipHelpModal
