import {
  Checkbox,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TablePagination,
  TableRow,
  TableSortLabel
} from '@material-ui/core'
import { pick as _pick, snakeCase as _snakeCase } from 'lodash-es'
import React, {
  type ChangeEvent,
  type MouseEvent,
  type ReactElement,
  useCallback
} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { AdminUserTableField, SortDirection } from '../../../enums'
import { TranslationKey } from '../../../i18n/translations'
import isoCountries from '../../../services/isoCountries'
import {
  getPage,
  getPageSize,
  getSortColumn,
  getSortDirection,
  getSortDisabled,
  getSubmitting,
  getUsers,
  getUsersCount,
  setAllUsersSelected,
  setPage,
  setPageSize,
  setSortColumn,
  setSortDirection,
  toggleUserSelected
} from '../slice'
import UserTableActions from './UserTableActions'
import { type User } from '../../../types'

const sortLessFields: AdminUserTableField[] = [
  AdminUserTableField.Country,
  AdminUserTableField.Email,
  AdminUserTableField.Role
]

function UsersTable (): ReactElement {
  const intl = useIntl()
  const dispatch = useDispatch()

  const adminUsers = useSelector(getUsers)
  const count = useSelector(getUsersCount)
  const page = useSelector(getPage)
  const pageSize = useSelector(getPageSize)
  const sortColumn = useSelector(getSortColumn)
  const sortDirection = useSelector(getSortDirection)
  const submitting = useSelector(getSubmitting)
  const sortDisabled = useSelector(getSortDisabled)

  const handleAllSelectionChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setAllUsersSelected({
          selected: !!event.target.checked
        })
      )
    },
    [dispatch]
  )

  const handleSortClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const { column } = event.currentTarget.dataset
      if (
        sortDisabled !== null ||
        sortLessFields.includes(column as AdminUserTableField)
      ) {
        return
      }

      if (column !== sortColumn) {
        dispatch(
          setSortColumn({
            sortColumn: column as AdminUserTableField
          })
        )
      } else {
        const newSortDirection =
          sortDirection === SortDirection.Ascending
            ? SortDirection.Descending
            : SortDirection.Ascending
        dispatch(
          setSortDirection({
            sortDirection: newSortDirection
          })
        )
      }
    },
    [dispatch, sortColumn, sortDirection, sortDisabled]
  )

  const handleUserSelectChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const { sharp } = event.target.dataset
      dispatch(
        toggleUserSelected({
          sharp: Number(sharp)
        })
      )
    },
    [dispatch]
  )

  const handlePageSizeChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setPageSize({
          pageSize: Number(event.target.value)
        })
      )
    },
    [dispatch]
  )

  const handlePageClick = useCallback(
    (_event: MouseEvent<HTMLButtonElement> | null, newPage: number) => {
      dispatch(
        setPage({
          page: newPage
        })
      )
    },
    [dispatch]
  )

  return (
    <TableContainer component={Paper}>
      <UserTableActions />

      <Table>
        <TableHead>
          <TableRow>
            {Object.values(AdminUserTableField).map((field) => (
              <TableCell
                key={field}
                padding={
                  field === AdminUserTableField.Selected
                    ? 'checkbox'
                    : undefined
                }
                width={field === AdminUserTableField.Role ? 150 : undefined}
              >
                {field === AdminUserTableField.Selected
                  ? (
                  <Checkbox
                    indeterminate={
                      !(adminUsers.length === 0) &&
                      // eslint-disable-next-line @typescript-eslint/strict-boolean-expressions
                      adminUsers.some(({ selected }: any) => selected) &&
                      adminUsers.filter(({ selected }: any) => selected).length <
                        adminUsers.length
                    }
                    checked={
                      !(adminUsers.length === 0) &&
                      adminUsers.every(({ selected }: any) => selected)
                    }
                    disabled={submitting}
                    onChange={handleAllSelectionChange}
                    color="primary"
                  />
                    )
                  : (
                  <TableSortLabel
                    active={sortDisabled !== null && field === sortColumn}
                    direction={sortDirection}
                    onClick={handleSortClick}
                    data-column={field}
                    hideSortIcon={
                      sortDisabled ?? sortLessFields.includes(field)
                    }
                  >
                    <FormattedMessage
                      id={`USER_ADMIN_COLUMN_${_snakeCase(
                        field
                      ).toUpperCase()}`}
                    />
                  </TableSortLabel>
                    )}
              </TableCell>
            ))}
          </TableRow>
        </TableHead>

        <TableBody>
          {adminUsers.map((user: User) => (
            <TableRow
              key={user.sharp}
              style={{
                backgroundColor: user.selected !== null ? '#A7205414' : undefined,
                height: 43
              }}
            >
              {Object.entries(
                _pick(user, Object.values(AdminUserTableField))
              ).map(([field, value]) => (
                <TableCell
                  key={`${user.sharp}-${field}`}
                  padding={
                    field === AdminUserTableField.Selected
                      ? 'checkbox'
                      : undefined
                  }
                  width={field === AdminUserTableField.Role ? 150 : undefined}
                >
                  {field === AdminUserTableField.Selected && (
                    <Checkbox
                    // @ts-expect-error Prefiere otro tipo de dato
                      checked={value as boolean}
                      onChange={handleUserSelectChange}
                      disabled={submitting}
                      inputProps={
                        {
                          // @ts-expect-error Prefiere otro tipo de dato
                          'data-sharp': user.sharp
                        }
                      }
                      color="primary"
                    />
                  )}

                  {field === AdminUserTableField.Country &&
                    isoCountries.getName(value as string, intl.locale)}

                  {field === AdminUserTableField.Role && (
                    <FormattedMessage id={`USER_ROLE_${value.toString()}`} />
                  )}

                  {![
                    AdminUserTableField.Selected,
                    AdminUserTableField.Country,
                    AdminUserTableField.Role
                  ].includes(field as AdminUserTableField) && value}
                </TableCell>
              ))}
            </TableRow>
          ))}
        </TableBody>
      </Table>

      {(count !== null) && (
        <TablePagination
          component="div"
          count={count}
          rowsPerPage={pageSize}
          onChangeRowsPerPage={handlePageSizeChange}
          page={page}
          onPageChange={handlePageClick}
          labelRowsPerPage=""
          labelDisplayedRows={({ from, to, ...rest }) => (
            <FormattedMessage
              id={TranslationKey.HISTORY_ROW_DISPLAY}
              values={{
                from,
                to,
                count: rest.count
              }}
            />
          )}
        />
      )}
    </TableContainer>
  )
}

export default UsersTable
