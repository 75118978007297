import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { redirectSSO } from './valideteSSO'
import {
  fetchUserSession,
  getFetching,
  getUser
} from '../slice'

function SessionSSO (): JSX.Element {
  const dispatch = useDispatch()
  const fetching = useSelector(getFetching)
  const hasUser = !(useSelector(getUser) == null)

  useEffect(() => {
    if (fetching && !hasUser) {
      dispatch(fetchUserSession())
    } else {
      redirectSSO()
    }
  }, [dispatch, fetching, hasUser])

  return <div>
        <div className="full-spinner-container">
            <LoadingSpinner />
        </div>
    </div>
}

export default SessionSSO
