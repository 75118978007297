import React, { type ReactElement, useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { Outlet } from 'react-router'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { type Band } from '../../../enums'
import { getUser } from '../../userSession/slice'
import {
  fetchCompensation,
  getBand,
  getFetchingCompensation,
  setBand
} from '../slice'

function Compensation (): ReactElement {
  const dispatch = useDispatch()

  const user = useSelector(getUser)
  const band = useSelector(getBand)
  useEffect(() => {
    if (band !== null) return
    dispatch(
      setBand({
        band: user?.band as Band
      })
    )
  }, [band, dispatch, user])

  const fetchingCompensation = useSelector(getFetchingCompensation)
  useEffect(() => {
    if (!fetchingCompensation) return
    dispatch(fetchCompensation())
  }, [dispatch, fetchingCompensation])

  return (
    <>{fetchingCompensation ? <LoadingSpinner /> : <Outlet />}</>
  )
}

export default Compensation
