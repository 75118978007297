import React, { useState, useEffect } from 'react'
import {
    Container,
    Typography,
    Grid,
    FormControl,
    Select, Checkbox, ListItemText,
    MenuItem,
    Button,
    Box,
    CircularProgress, TextField, InputAdornment, InputLabel, OutlinedInput
} from '@material-ui/core'
import { withStyles } from '@material-ui/core/styles'
import type { Theme } from '@material-ui/core/styles'
import type { ClassNameMap } from '@material-ui/core/styles/withStyles'
interface MultiSelectProps {
    items: any[]
    label: string
    onSelectionChange?: (selectedItems: string[]) => void// Nueva función de devolución de llamada,
    disabled?: boolean
    clearSelection: boolean
}
interface CustomCheckboxProps {
    checked: boolean
    classes?: ClassNameMap<'root' | 'checked'>
  }
  
  const CustomCheckbox: React.FC<CustomCheckboxProps> = ({ checked, classes }) => {
    return <Checkbox color="default" checked={checked} classes={classes} />
  }
export const MultiSelect: React.FC<MultiSelectProps> = (props: MultiSelectProps) => {
    const { items, label, onSelectionChange, disabled, clearSelection } = props
    const [elements, setElements] = useState<string[]>([])
    const handleChange = (event: any): void => {
        const {
            target: { value }
        } = event
        setElements(
            typeof value === 'string' ? value.split(',') : value
        )
        onSelectionChange?.(value)
    }
      useEffect(() => {
        if (clearSelection) {
          setElements([])
        }
      }, [clearSelection])
    return (
        <>
            <FormControl fullWidth>
                <Typography variant="caption" display="block" gutterBottom>
                    {label}
                </Typography>
                <Select
                    multiple
                    variant="outlined"
                    name="range"
                    renderValue={(selected: any) => selected.join(', ')}
                    labelId="demo-multiple-checkbox-label"
                    id="demo-multiple-checkbox"
                    value={elements}
                    onChange={handleChange}
                    input={<OutlinedInput label="Tag" />}
                    disabled={items.length === 0 || disabled}
                >
                    {items.map((range: string, index: number) => (
                        <MenuItem key={index} value={range || ''}>
                              {elements &&  <CustomCheckbox checked={elements.includes(range)} />}
                              {label === 'Rango' ? `Semana ${range}` : range }
                        </MenuItem>
                    ))}
                </Select>
            </FormControl>
        </>
    )
}
