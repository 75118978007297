import { useState } from 'react'
import { DialogContent, Grid, IconButton } from '@material-ui/core'
import Dialog from '@material-ui/core/Dialog'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Close as CloseIcon } from '@material-ui/icons'
import { backURL } from '../../../config/environment'

function ModalVideo (): JSX.Element {
  const [open, setOpen] = useState(true)
  const handleClose = (): void => {
    setOpen(false)
  }
  const videoUrl = `${backURL ?? ''}/storage/video_rewards.mp4`
  return (
    <Dialog fullWidth maxWidth="md" open={open} onClose={handleClose}>
        <DialogTitle>
          <Grid container spacing={2}>
            <Grid item xs={11}>
              Video
            </Grid>
            <Grid item xs={1}>
              <IconButton
                edge="start"
                color="inherit"
                onClick={handleClose}
                aria-label="close"
              >
                <CloseIcon />
              </IconButton>
            </Grid>
          </Grid>
        </DialogTitle>
        <DialogContent>
          <video width="100%" height="auto" controls>
            <source src={videoUrl} type="video/mp4" />
            Your browser does not support the video tag.
          </video>
        </DialogContent>
      </Dialog>
  )
}
export default ModalVideo
