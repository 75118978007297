import { Button, makeStyles, Typography } from '@material-ui/core'
import { DropzoneArea } from 'material-ui-dropzone'
import React, {
  type FunctionComponent,
  type RefObject,
  useCallback,
  useEffect,
  useState
} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import { Subscriber } from 'rxjs'
import { adminAPI } from '../../../api'
import FileUploadProgress from '../../../components/FileUploadProgress'
import GeneralModal from '../../../components/GeneralModal'
import toastConfig from '../../../config/toast'
import { AppColor } from '../../../enums'
import { TranslationKey } from '../../../i18n/translations'
import { logger } from '../../../services'
import styles from './UsersAdmin.module.scss'

const useStyles = makeStyles({
  root: {
    outline: 'none'
  },
  icon: {
    color: AppColor.Primary
  }
})

interface UploadCollaboratorsReportModalProps {
  buttonRef: RefObject<HTMLButtonElement>
}

const UploadCollaboratorsReportModal: FunctionComponent<
UploadCollaboratorsReportModalProps
> = ({ buttonRef }) => {
  const intl = useIntl()
  const [uploading, setUploading] = useState(false)
  const [file, setFile] = useState<File | null>(null)
  const [progress, setProgress] = useState(0)
  const [dropzoneKey, setDropzoneKey] = useState(0)
  const [showSuccess, setShowSuccess] = useState(false)

  const handleModalClose = useCallback(() => {
    setShowSuccess(false)
  }, [])

  const handleChange = useCallback((newFiles: File[]) => {
    setFile(newFiles?.[0])
    setUploading(true)
  }, [])

  useEffect(() => {
    if (!uploading || file == null) return undefined

    const progressSubscriber$ = new Subscriber<ProgressEvent>(
      // @ts-expect-error: Se espera que sea un observer un promise o un undefined
      (event: ProgressEvent) => {
        const newProgress = (event.loaded / event.total) * 100
        setProgress(newProgress)
      }
    )

    const formData = new FormData()
    formData.append('report', file, 'report.xlsb')
    logger.info('Uploading report file', file)

    const request$ = adminAPI
      .uploadCollaboratorsReport(formData, progressSubscriber$)
      .subscribe(
        (response) => {
          setDropzoneKey((currentKey) => currentKey + 1)
          setUploading(false)
          setProgress(0)
          setShowSuccess(true)
          logger.info('Correctly uploaded report.', response)
        },
        (error) => {
          logger.error('Error uploading report.', error)
          setFile(null)
          setUploading(false)
          setProgress(0)
          toast.error(
            intl.formatMessage({
              id: TranslationKey.REPORT_UPLOAD_ERROR
            }),
            toastConfig
          )
        }
      )

    return () => {
      progressSubscriber$.unsubscribe()
      request$.unsubscribe()
    }
  }, [file, intl, uploading])

  const dropzoneClasses = useStyles()

  return (
    <GeneralModal
      isOpen
      buttonRef={buttonRef}
      maxWidth={showSuccess ? 300 : 500}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
      bottomCloseButton={
        showSuccess
          ? (
          <Button variant="contained" color="primary">
            <FormattedMessage id={TranslationKey.UNDERSTOOD} />
          </Button>
            )
          : undefined
      }
      bottomButtons={[]}
      onAfterClose={handleModalClose}
    >
      <div
        style={{
          textAlign: 'center'
        }}
      >
        {
          !showSuccess
            ? (
            <>
              <Typography
                variant="h5"
                color="primary"
                style={{
                  marginBottom: 20,
                  marginTop: 10
                }}
              >
                <FormattedMessage id={TranslationKey.UPLOAD_COLLABORATORS} />
              </Typography>
              <DropzoneArea
                key={dropzoneKey}
                classes={dropzoneClasses}
                acceptedFiles={[
                  'application/vnd.ms-excel.sheet.binary.macroEnabled.12'
                ]}
                filesLimit={1}
                // 15 Mb
                maxFileSize={1.5e7}
                dropzoneText={intl.formatMessage({
                  id: TranslationKey.REPORT_LOAD_INSTRUCTIONS
                })}
                dropzoneParagraphClass={styles.dropzoneText}
                onChange={handleChange}
                showPreviewsInDropzone={false}
                showAlerts={false}
              />

              {file != null && (
                <FileUploadProgress fileName={file.name} progress={progress} />
              )}
            </>
              )
            : (
              <>
                <Typography variant="h5" color="primary">
                  <FormattedMessage id={TranslationKey.SUCCESSFUL_UPLOAD} />
                </Typography>
                <Typography variant="body1">
                  <FormattedMessage
                    id={TranslationKey.COLLABORATORS_REPORT_SUCCESS}
                  />
                </Typography>
              </>
              )
        }
      </div>
    </GeneralModal>
  )
}

export default UploadCollaboratorsReportModal
