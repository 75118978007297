import { Button, Menu, MenuItem, Toolbar } from '@material-ui/core'
import {
  ArrowDropDown as ArrowDropDownIcon,
  ArrowDropUp as ArrowDropUpIcon,
  Delete as DeleteIcon,
  Sync as SyncIcon
} from '@material-ui/icons'
import React, {
  type MouseEvent,
  type ReactElement,
  useCallback,
  useRef,
  useState
} from 'react'
import { FormattedMessage } from 'react-intl'
import { useSelector } from 'react-redux'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { UserRole } from '../../../enums'
import { getValidUserRoles } from '../../../helpers/util-functions'
import { TranslationKey } from '../../../i18n/translations'
import { getHasSelectedUsers, getSubmitting } from '../slice'
import ConfirmDeleteModal from './ConfirmDeleteModal'
import ConfirmRoleModal from './ConfirmRoleModal'
import styles from './UsersAdmin.module.scss'

const userRoles = getValidUserRoles()

function UserTableActions (): ReactElement {
  const canEdit = useSelector(getHasSelectedUsers)

  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)

  const handleMenuOpenClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>): void => {
      setAnchorEl(event.currentTarget)
    },
    []
  )
  const handleMenuClose = useCallback(() => {
    setAnchorEl(null)
  }, [])

  const deleteRef = useRef(null)
  const roleRef = useRef<HTMLButtonElement | null>(null)

  const [selectedRole, setSelectedRole] = useState(UserRole.Regular)
  const handleMenuItemClick = useCallback(
    (event: MouseEvent<HTMLLIElement>) => {
      const { role } = event.currentTarget.dataset
      setSelectedRole(role as UserRole)
      setAnchorEl(null)
      setTimeout(() => {
        roleRef.current?.click()
      }, 120)
    },
    []
  )

  const submitting = useSelector(getSubmitting)

  return (
    <Toolbar
      style={{
        position: 'relative'
      }}
    >
      <ConfirmDeleteModal buttonRef={deleteRef} />
      <ConfirmRoleModal buttonRef={roleRef} role={selectedRole} />

      <Button
        ref={deleteRef}
        variant="outlined"
        color="primary"
        disabled={canEdit ?? submitting}
        className={styles.uploadButton}
      >
        <DeleteIcon />
        <FormattedMessage id={TranslationKey.DELETE} />
      </Button>
      <Button
        variant="outlined"
        color="primary"
        disabled={canEdit ?? submitting}
        className={styles.uploadButton}
        onClick={handleMenuOpenClick}
      >
        <SyncIcon />
        <FormattedMessage id={TranslationKey.CHANGE_ROLE} />
        {(anchorEl != null) ? <ArrowDropUpIcon /> : <ArrowDropDownIcon />}
      </Button>

      {/* Hidden */}
      <Button
        ref={roleRef}
        style={{
          display: 'none'
        }}
      />

      <Menu
        anchorEl={anchorEl}
        getContentAnchorEl={null}
        open={!(anchorEl == null)}
        onClose={handleMenuClose}
        keepMounted
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'right'
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'right'
        }}
      >
        {userRoles.map((role) => (
          <MenuItem key={role} data-role={role} onClick={handleMenuItemClick}>
            <FormattedMessage id={`USER_ROLE_${role.toLocaleUpperCase()}`} />
          </MenuItem>
        ))}
      </Menu>

      {submitting !== null && <LoadingSpinner />}
    </Toolbar>
  )
}

export default UserTableActions
