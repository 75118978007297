import { configureStore } from '@reduxjs/toolkit'
import { createEpicMiddleware } from 'redux-observable'
import { BehaviorSubject } from 'rxjs'
import { switchMap } from 'rxjs/operators'
import rootEpic from './rootEpic'
import rootReducer from './rootReducer'

const epicMiddleware = createEpicMiddleware()

const store = configureStore({
  reducer: rootReducer,
  devTools: process.env.NODE_ENV !== 'production',
  middleware: [epicMiddleware]
})

const epic$ = new BehaviorSubject(rootEpic)
// Every time a new epic is given to epic$ it
// will unsubscribe from the previous one then
// call and subscribe to the new one because of
// how switchMap works
const hotReloadingEpic = (...args: unknown[]): any =>
  // eslint-disable-next-line @typescript-eslint/ban-ts-comment
  // @ts-expect-error
  epic$.pipe(switchMap((epic) => epic(...args)))

epicMiddleware.run(hotReloadingEpic)

if (process.env.NODE_ENV === 'development' && (module.hot != null)) {
  module.hot.accept('./rootReducer', () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires,global-require
    const newRootReducer = require('./rootReducer').default
    store.replaceReducer(newRootReducer)
  })

  module.hot.accept('./rootEpic', () => {
    // eslint-disable-next-line @typescript-eslint/no-unsafe-assignment,@typescript-eslint/no-unsafe-member-access,@typescript-eslint/no-var-requires,global-require
    const nextRootEpic = require('./rootEpic').default
    epic$.next(nextRootEpic)
  })
}

export type AppDispatch = typeof store.dispatch

export default store
