import {
  Container,
  Grid,
  List,
  ListItem,
  ListItemText,
  Typography
} from '@material-ui/core'
import React, { type MouseEvent, type ReactElement, useCallback } from 'react'
import { FormattedMessage } from 'react-intl'
import { Outlet, useLocation, useNavigate } from 'react-router'
import { AppColor } from '../../../enums'
import { TranslationKey } from '../../../i18n/translations'
import { useAdminRedirect } from '../hooks'
import styles from './ControlPanel.module.scss'

interface MenuItem {
  translationKey: string
  route: string
}
const adminMenuItems: MenuItem[] = [
  {
    translationKey: 'Recibos de Nómina',
    route: '/admin/payroll'
  },
  {
    translationKey: 'Usuarios',
    route: '/admin/users'
  }
  // {
  //   translationKey: TranslationKey.MANAGER_BENEFITS,
  //   route: '/admin/benefits/panel'
  // },
]

function ControlPanel(): ReactElement {
  useAdminRedirect()

  const { pathname } = useLocation()
  const navigate = useNavigate()

  const handleMenuClick = useCallback(
    (event: MouseEvent<HTMLDivElement>) => {
      const { route } = event.currentTarget.dataset as { route: string }
      navigate(route)
    },
    [navigate]
  )

  return (
    <Container maxWidth="xl" className={styles.container}>
      <Grid container>
        <Grid item xs={12} className={styles.titleContainer}>
        <Container className={styles.text_left}>
          <Typography variant="h3">Administrador</Typography>
          <Typography variant="subtitle1">
          Consulta los recibos de nómina y administra los usuarios de la herramienta.
        </Typography>
      </Container>
        </Grid>
        <Grid item xs={12} className={styles.menuContainer}>
          <Container>
            <List component="nav" aria-label="">
              <Grid container spacing={0}>
                {adminMenuItems.map(({ translationKey, route }, index) => (
                  <Grid item xs={3} key={translationKey}>
                    <ListItem
                      button
                      selected={pathname === route}
                      onClick={handleMenuClick}
                      data-route={route}
                      disabled={index === 1}
                      style={{
                        borderBottom: `3px solid ${pathname === route ? AppColor.NavBottomColor  : 'transparent'
                          }`,
                          backgroundColor: 'transparent',
                        color: pathname === route ? '#383838' : '#5F5F5F',
                        flex: 1
                      }}
                    >
                      <ListItemText
                        primary={<FormattedMessage id={translationKey} />}
                        color={pathname === route ? '#383838' : '#5F5F5F'}
                      />
                    </ListItem>
                  </Grid>
                ))}
              </Grid>
            </List>
          </Container>
        </Grid>
        <Grid item xs={12}>
          <Outlet />
        </Grid>
      </Grid>
    </Container>
  )
}

export default ControlPanel
