import React from 'react'
import ReactDOM from 'react-dom'
import { ThemeProvider } from '@material-ui/core'
import { Provider } from 'react-redux'
import reportWebVitals from './app/reportWebVitals'
import store from './app/store'
import theme from './config/theme'
import IntlWrapper from './wrappers/IntlWrapper'
import RouterWrapper from './wrappers/RouterWrapper'
import App from './app/App'
import './index.scss'

// If you are using date-fns v3.x, please import the v3 adapter
const render = (): void => {
  ReactDOM.render(
    <React.StrictMode>
      <Provider store={store}>
        <IntlWrapper>
          <RouterWrapper>
            <ThemeProvider theme={theme}>
              <App />
            </ThemeProvider>
          </RouterWrapper>
        </IntlWrapper>
      </Provider>
    </React.StrictMode>,
    document.getElementById('root')
  )
}

render()

if (process.env.NODE_ENV === 'development' && (module.hot != null)) {
  module.hot.accept('./app/App', render)
}

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
