import { Grid, Typography } from '@material-ui/core'
import FormControl from '@material-ui/core/FormControl'
import IconButton from '@material-ui/core/IconButton'
import InputLabel from '@material-ui/core/InputLabel'
import Select from '@material-ui/core/Select'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import React, {
  type ChangeEvent,
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { useIntl } from 'react-intl'
import { useSelector } from 'react-redux'
import { useParams } from 'react-router-dom'
import { map } from 'rxjs/operators'
import { adminBenefitsAPI } from '../../../../api'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { CountryCode } from '../../../../enums'
import isoCountries from '../../../../services/isoCountries'
import { type BenefitCategory, type RawBenefit } from '../../../../types'
import { getCategories } from '../slice'
import styles from './Categories.module.scss'
import DeleteItem from './ModalDelete'
import NewBenefi from './ModalFormCategorie'

function ListBenefits (): ReactElement {
  const [Benefits, setBenefist] = useState<RawBenefit[]>([])
  const [loading, setLoading] = useState(true)
  const { benefitID = '' } = useParams()
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalData, setTotalData] = useState(0)
  const [alertDelete, setAlertDelete] = useState(false)
  const [countryChange, setCountryChange] = useState('MX')
  const [idRow, setIdRow] = useState<number>(0)

  const categories = useSelector(getCategories)

  const getCategorieName = categories.find(
    (cat: BenefitCategory) => cat.id === parseInt(benefitID, 10)
  )

  const intl = useIntl()

  const handleClickDelete = (idBenefi: number): void => {
    setIdRow(idBenefi)
    setAlertDelete(true)
  }
  const handleCloseDelete = (): void => {
    setAlertDelete(false)
  }

  const countryOptions = useMemo(
    () =>
      Object.values(CountryCode).map((countryCode) => ({
        value: countryCode,
        label: isoCountries.getName(countryCode, intl.locale)
      })),
    [intl.locale]
  )

  const getBenefit = (country: string): void => {
    setLoading(true)
    adminBenefitsAPI
      .getBenefits(benefitID, country, page, rowsPerPage)
      .pipe(
        // @ts-expect-error: Errores de tipos
        map(
          ({
            response
          }: {
            response: { benefits: RawBenefit[], count: number }
          }): { benefits: RawBenefit[], count: number } => response
        )
      )
      .subscribe(
        (benefits) => {
          setBenefist(benefits.benefits)
          setTotalData(benefits.count)
          setLoading(false)
        },
        () => {
          setBenefist([])
          setLoading(false)
        }
      )
  }

  const handleDelete = (idBenefit: number): void => {
    setLoading(true)
    adminBenefitsAPI.DeleteBenefit({ id: idBenefit }).subscribe(
      () => {
        const country = countryChange !== '' ? countryChange : 'MX'
        getBenefit(country)
        setLoading(false)
        setAlertDelete(false)
      },
      () => {
        setLoading(false)
      }
    )
  }

  const hadleRows = useCallback((event: ChangeEvent<{ value: string }>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
    // getBenefit(countryChange);
  }, [])

  const hadleSearch = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    setCountryChange(event.target.value as CountryCode)
    getBenefit(event.target.value as CountryCode)
  }, [])

  useEffect(() => {
    getBenefit(countryChange)
  }, [page, rowsPerPage])

  const handleSaveBenefit = (save: boolean): void => {
    if (save) {
      getBenefit(countryChange)
    }
  }

  return (
    <Grid container spacing={2} className={styles.container}>
      <Grid item xs={12} lg={12} md={12} className={styles.spacingbreadcrumb}>
        <p className={styles.colorText}>Beneficios y convenios</p>
      </Grid>

      <Grid item xs={12} lg={6} md={6} className={styles.paddingTitleCategorie}>
        <Typography variant="h5" color="primary">
          {getCategorieName?.title}
        </Typography>
      </Grid>
      <Grid item xs={12} lg={3} md={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel htmlFor="filled-age-native-simple">
            Seleccionar país
          </InputLabel>
          <Select label="Seleccionar país" onChange={hadleSearch}>
            <option aria-label="None" value="" />
            {countryOptions.map(({ value, label }) => (
              <option key={label} value={value}>{label}</option>
            ))}
          </Select>
        </FormControl>
      </Grid>
      <Grid item xs={12} lg={3} md={3} className={styles.btnCenter}>
        <NewBenefi
          edit={false}
          nameBtn="Nuevo beneficio"
          showForm={false}
          idRow={idRow}
          saveData={handleSaveBenefit}
        />
      </Grid>

      <Grid item xs={12} className={styles.SpaceTable}>
        <table>
          <thead>
            <tr>
              <th>Tipo</th>
              <th>Categoría </th>
              <th>Nombre</th>
              <th colSpan={3}>País</th>
            </tr>
          </thead>
          <tbody>
            {loading
              ? (
              <Grid xs={12} className={styles.centerContent}>
                <LoadingSpinner />
              </Grid>
                )
              : (
                  Benefits.map((benefit) => (
                <tr key={benefit.id}>
                  <td>{benefit.type === 'deal' ? 'Convenio' : 'Beneficio'}</td>
                  <td>{benefit.category}</td>
                  <td>{benefit.title}</td>
                  <td>{benefit.country}</td>
                  <td className={styles.textEdit}>
                    <NewBenefi
                      nameBtn="Editar"
                      edit
                      showForm={false}
                      idRow={benefit.id}
                      data={benefit}
                      saveData={handleSaveBenefit}
                    />
                  </td>
                  <td>
                    <DeleteItem
                      deleteItem={handleDelete}
                      open={() => { handleClickDelete(benefit.id) }}
                      close={handleCloseDelete}
                      showAlert={alertDelete}
                      title="Eliminar beneficio"
                      describe="¿Seguro quieres eliminar el beneficio seleccionado?"
                      id={idRow}
                      loading={loading}
                    />
                  </td>
                </tr>
                  ))
                )}
          </tbody>
          <tfoot>
            <tr>
              <td colSpan={3} className={styles.textRightTd}>
                Filtrar por página:
              </td>
              <td>
                <select onChange={hadleRows}>
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
              </td>
              <td>
                {page}-{Benefits.length} de {rowsPerPage}
              </td>
              <td>
                <IconButton
                  disabled={page === 0}
                  aria-label="previous page"
                  onClick={() => { setPage(page - 1) }}
                >
                  <KeyboardArrowLeft />
                </IconButton>
                <IconButton
                  disabled={page >= Benefits.length || rowsPerPage >= totalData}
                  aria-label="next page"
                  onClick={() => { setPage(page + 1) }}
                >
                  <KeyboardArrowRight />
                </IconButton>
              </td>
            </tr>
          </tfoot>
        </table>
      </Grid>
    </Grid>
  )
}

export default ListBenefits
