import { useEffect, useMemo } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { type Benefit, type BenefitCategory } from '../../types'
import { getLocale } from '../userSession/slice'
import {
  fetchBenefitCategories,
  getBenefits,
  getCategories,
  getFetchingCategories
} from './slice'

export const useFetchCategories = (): void => {
  const dispatch = useDispatch()
  const fetchingCategories = useSelector(getFetchingCategories)
  const categories = useSelector(getCategories)
  const locale = useSelector(getLocale)

  useEffect(() => {
    if ((categories.length === 0) && fetchingCategories) {
      dispatch(fetchBenefitCategories())
    }
  }, [categories.length, dispatch, fetchingCategories, locale])
}

export const useBenefitByID = (benefitID: number): Benefit | undefined => {
  const benefits = useSelector(getBenefits)
  const benefit = useMemo<Benefit | undefined>(
    () => benefits?.find(({ id }) => id === benefitID),
    [benefitID, benefits]
  )
  return benefit
}

export const useCategoryByID = (
  categoryID: number
): BenefitCategory | undefined => {
  const categories = useSelector(getCategories)
  const category = useMemo<BenefitCategory | undefined>(
    () => categories.find(({ id }) => id === categoryID),
    [categories, categoryID]
  )
  return category
}
