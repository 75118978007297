import { Grid, LinearProgress, Typography } from '@material-ui/core'
import {
  CheckCircle as CheckCircleIcon,
  InsertDriveFile as InsertDriveFileIcon
} from '@material-ui/icons'
import React, { type FunctionComponent } from 'react'
import LoadingSpinner from '../LoadingSpinner'

interface FileUploadProgressProps {
  fileName: string
  progress: number
}

const FileUploadProgress: FunctionComponent<FileUploadProgressProps> = ({
  fileName,
  progress
}) => (
  <Grid
    container
    alignItems="center"
    style={{
      marginTop: 20
    }}
  >
    <Grid item xs={2}>
      <InsertDriveFileIcon
        color="primary"
        style={{
          fontSize: 50
        }}
      />
    </Grid>
    <Grid item xs={10}>
      <Grid container>
        <Grid
          item
          xs={10}
          style={{
            paddingBottom: 10
          }}
        >
          <Typography
            variant="body2"
            style={{
              textAlign: 'left'
            }}
          >
            {fileName}
          </Typography>
        </Grid>
        <Grid item xs={1}>
          <Typography variant="body2">{Math.round(progress)}%</Typography>
        </Grid>
        <Grid item xs={1}>
          {progress < 100
            ? (
            <LoadingSpinner />
              )
            : (
            <CheckCircleIcon color="primary" />
              )}
        </Grid>
        <Grid item xs={12}>
          <LinearProgress
            color="primary"
            value={progress}
            variant="determinate"
          />
        </Grid>
      </Grid>
    </Grid>
  </Grid>
)
export default FileUploadProgress
