import { Button, Typography } from '@material-ui/core'
import React, {
  type FunctionComponent,
  type RefObject,
  useCallback,
  useRef
} from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch } from 'react-redux'
import GeneralModal from '../../../components/GeneralModal'
import { TranslationKey } from '../../../i18n/translations'
import { deleteUsersRequest } from '../slice'

interface ConfirmDeleteModalProps {
  buttonRef: RefObject<HTMLElement>
}

const ConfirmDeleteModal: FunctionComponent<ConfirmDeleteModalProps> = ({
  buttonRef
}) => {
  const dispatch = useDispatch()
  const closeButtonRef = useRef<HTMLButtonElement>(null)

  const handleDeleteClick = useCallback(() => {
    closeButtonRef.current?.click()
    dispatch(deleteUsersRequest())
  }, [dispatch])

  return (
    <GeneralModal
      isOpen
      buttonRef={buttonRef}
      maxWidth={300}
      bottomCloseButton={
        <Button
          ref={closeButtonRef}
          variant="outlined"
          style={{
            margin: 2
          }}
        >
          <FormattedMessage id={TranslationKey.CANCEL} />
        </Button>
      }
      bottomButtons={[
        <Button
          key="delete"
          variant="contained"
          color="primary"
          style={{
            margin: 2
          }}
          onClick={handleDeleteClick}
        >
          <FormattedMessage id={TranslationKey.DELETE} />
        </Button>
      ]}
    >
      <div
        style={{
          textAlign: 'center'
        }}
      >
        <Typography
          variant="h5"
          color="primary"
          style={{
            marginBottom: 10
          }}
        >
          <FormattedMessage id={TranslationKey.DELETE_USERS} />
        </Typography>
        <Typography variant="body1">
          <FormattedMessage id={TranslationKey.DELETE_USERS_CONFIRMATION} />
        </Typography>
      </div>
    </GeneralModal>
  )
}

export default ConfirmDeleteModal
