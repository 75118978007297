import {
  Button,
  Grid,
  IconButton,
  Slider,
  Typography
} from '@material-ui/core'
import { Help as HelpIcon } from '@material-ui/icons'
import React, { useCallback, useEffect, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { type Band } from '../../../enums'
import { useCurrencyFormatter } from '../../../helpers/hooks'
import { TranslationKey } from '../../../i18n/translations'
import { BonusHelpModal, TipHelpModal } from '../HelpModals'
import {
  getBandSti,
  getBonus,
  getBonusTarget,
  getBonusTotal,
  getDiscretionaryIncentive,
  getFetchingBonus,
  getJobBandMultiplier,
  getKicker,
  getTimeDedicationRate,
  getTotalAchievementScore,
  setBandSti,
  setBonusTarget,
  setDiscretionaryIncentive,
  setJobBandMultiplier,
  setKicker,
  setTimeDedicationRate
} from '../slice'
import styles from './Compensation.module.scss'
import ModalVideo from './ModalVideo'
import { type BonusClass } from '../../../types'

function SecondPanel (): JSX.Element {
  const currencyFormatter = useCurrencyFormatter(true)
  const dispatch = useDispatch()

  const bonusRef = useRef(null)
  const bonus = useSelector(getBonus)
  const bonusTotal = useSelector(getBonusTotal)
  const fetchingBonus = useSelector(getFetchingBonus)
  const totalAchievementRef = useRef(null)
  const totalAchievementScore = useSelector(getTotalAchievementScore)

  const { title, description, variables = [] }: BonusClass | any = bonus?.bonus

  const discretionaryIncentiveVariables = variables?.filter(
    (data: any) => data.representation === 'di'
  )[0]
  const discretionaryIncentiveInfo = discretionaryIncentiveVariables

  const bandInfo = variables?.filter(
    (data: any) => data.representation === 'mrs'
  )[0]

  const timeDedicationRateInfo = variables?.filter(
    (data: any) => data.representation === 'tdr'
  )[0]

  const jobBandMultiplierInfo = variables?.filter(
    (data: any) => data.representation === 'bm'
  )[0]

  const bonusTargetInfo = variables?.filter(
    (data: any) => data.representation === 'bt'
  )[0]

  const kickerInfo = variables?.filter(
    (data: any) => data.representation === 'k'
  )[0]

  const updateDiscretionaryIncentive = useCallback(
    (dataValue: number) => {
      if (typeof discretionaryIncentiveInfo?.value === 'object') {
        dispatch(
          setDiscretionaryIncentive({
            discretionaryIncentive: dataValue
          })
        )
      }
    },
    [discretionaryIncentiveInfo, dispatch]
  )

  const updateBandSti = useCallback(
    (dataValue: number) => {
      if (typeof bandInfo?.value === 'object') {
        dispatch(
          setBandSti({
            bandSti: bandInfo.value[dataValue] as Band
          })
        )
      }
    },
    [bandInfo, dispatch]
  )

  const updateTimeDedicationRate = useCallback(
    (dataValue: number) => {
      if (typeof timeDedicationRateInfo?.value === 'object') {
        dispatch(
          setTimeDedicationRate({
            timeDedicationRate: dataValue
          })
        )
      }
    },
    [timeDedicationRateInfo, dispatch]
  )

  const updateKicker = useCallback(
    (dataValue: number) => {
      if (typeof kickerInfo?.value === 'object') {
        dispatch(
          setKicker({
            kicker: dataValue
          })
        )
      }
    },
    [kickerInfo, dispatch]
  )

  const updateJobBandMultiplier = useCallback(() => {
    if (typeof jobBandMultiplierInfo?.value === 'number') {
      dispatch(
        setJobBandMultiplier({
          jobBandMultiplier: jobBandMultiplierInfo?.value
        })
      )
    }
  }, [jobBandMultiplierInfo, dispatch])

  const updateBonusTarget = useCallback(() => {
    if (typeof bonusTargetInfo?.value === 'number') {
      dispatch(
        setBonusTarget({
          bonusTarget: bonusTargetInfo?.value
        })
      )
    }
  }, [bonusTargetInfo, dispatch])

  const kickerInitialValue =
    typeof kickerInfo?.value === 'object'
      ? kickerInfo?.value[kickerInfo?.value.length - 1]
      : 100

  const discretionaryIncentiveInitialValue =
    typeof discretionaryIncentiveInfo?.value === 'object'
      ? discretionaryIncentiveInfo?.value[
        discretionaryIncentiveInfo?.value.length - 1
      ]
      : 70

  const bandStiInitialStiValue =
    typeof bandInfo?.value === 'object' ? bandInfo?.value.length - 3 : 0

  useEffect(() => {
    updateDiscretionaryIncentive(discretionaryIncentiveInitialValue as number)
    updateBandSti(bandStiInitialStiValue)
    updateTimeDedicationRate(12)
    updateJobBandMultiplier()
    updateBonusTarget()
    updateKicker(kickerInitialValue as number)
  }, [
    updateDiscretionaryIncentive,
    discretionaryIncentiveInitialValue,
    updateBandSti,
    updateTimeDedicationRate,
    updateJobBandMultiplier,
    updateBonusTarget,
    updateKicker,
    kickerInitialValue,
    bandStiInitialStiValue
  ])

  const bandSti = useSelector(getBandSti)
  const discretionaryIncentive = useSelector(getDiscretionaryIncentive)
  const timeDedicationRate = useSelector(getTimeDedicationRate)
  const jobBandMultiplier = useSelector(getJobBandMultiplier)
  const bonusTarget = useSelector(getBonusTarget)
  const kicker = useSelector(getKicker)

  const getMarksByRepresentation = (marks: number | Array<string | number>): any => {
    if (typeof marks !== 'object') {
      return undefined
    }

    return marks.map((label, id) =>
      typeof label === 'string'
        ? {
            label,
            value: id
          }
        : { label, value: label }
    )
  }

  const getValueByRepresentation = (
    representation: string,
    marks: number | Array<string | number>
  ): any => {
    const marksInfo = getMarksByRepresentation(marks)
    const markMin = (marksInfo != null) ? marksInfo[0] : null
    const markMax = (marksInfo != null) ? marksInfo[marksInfo.length - 1] : null

    const min = (markMin != null) ? markMin.value : 0
    const max = (markMax != null) ? markMax.value : 0

    switch (representation) {
      case 'mrs':
        return {
          value: bandSti,
          marks: marksInfo,
          min,
          max
        }

      case 'di':
        return {
          value: discretionaryIncentive,
          marks: marksInfo,
          min,
          max
        }

      case 'tdr':
        return {
          value: timeDedicationRate,
          marks: marksInfo,
          min,
          max
        }

      case 'bm':
        return {
          value: jobBandMultiplier,
          min,
          max
        }

      case 'bt':
        return {
          value: bonusTarget,
          min,
          max
        }

      case 'k':
        return {
          value: kicker,
          marks: marksInfo,
          min,
          max
        }

      default:
        return {
          value: 0,
          marks: [{ label: '0', value: 0 }],
          min,
          max
        }
    }
  }

  const handleSliderDataChange = (
    dataValue: number,
    representation: string
  ): void => {
    switch (representation) {
      case 'di':
        updateDiscretionaryIncentive(dataValue)
        break
      case 'mrs':
        updateBandSti(dataValue)
        break
      case 'tdr':
        updateTimeDedicationRate(dataValue)
        break
      case 'k':
        updateKicker(dataValue)
        break
      case 'bm':
        updateJobBandMultiplier()
        break

      default:
        break
    }
  }

  return (
    <>
      {/** Modal video */}
      <ModalVideo />
      <Grid item xs={12} className={styles.titleContainer}>
        <Typography variant="h5">{title}</Typography>
        <Typography variant="body2">{description}</Typography>
      </Grid>

      <Grid item xs={12} sm={6} md={6} lg={5} className={styles.panelContainer}>
        <div className={styles.totalCard}>
          <BonusHelpModal buttonRef={bonusRef} />
          <IconButton ref={bonusRef} color="primary" size="small">
            <HelpIcon fontSize="small" />
          </IconButton>
          <Typography variant="subtitle1">
            <FormattedMessage id={TranslationKey.BONUS_STI_TOTAL} />
          </Typography>
          <Typography variant="h5" color="primary">
            {currencyFormatter.format(bonusTotal)}
          </Typography>
        </div>
        {variables.map((variable: any, index: number) => {
          const { representation, unit, value, variableType } = variable
          const optionStiValue = getValueByRepresentation(
            representation,
            value
          )
          const buttonId = `bono-sti-tooltip-${index}`
          const valueLabelFormat = (indexValue: number): string | number | undefined => {
            const markLabel =
              typeof optionStiValue.marks === 'object'
                ? optionStiValue.marks.find(
                  (option: any) => option.value === indexValue
                )?.label
                : ''
            return markLabel
          }
          return (
            <React.Fragment key={buttonId}>
              <div className={styles.cardGroup}>
                <div>
                  <Typography variant="subtitle1">{variable.title}</Typography>
                </div>
                <div>
                  <Typography variant="subtitle1" color="primary">
                    {optionStiValue.value}
                    {unit}
                  </Typography>
                  <IconButton
                    id={buttonId}
                    size="small"
                    className={styles.helpButton}
                  >
                    <HelpIcon fontSize="small" />
                  </IconButton>
                  <TipHelpModal
                    buttonId={buttonId}
                    title={variable?.title}
                    text={variable?.help}
                  />
                </div>
              </div>
              {variableType === 'INPUT_COMBO' && (
                <Slider
                  className={styles.slider}
                  color="secondary"
                  valueLabelDisplay="auto"
                  track={false}
                  step={null}
                  marks={optionStiValue.marks}
                  min={optionStiValue.min}
                  max={optionStiValue.max}
                  defaultValue={
                    representation === 'mrs'
                      ? optionStiValue.min
                      : optionStiValue.max
                  }
                  valueLabelFormat={valueLabelFormat}
                  onChange={(_, valueOChange) => {
                    handleSliderDataChange(
                      valueOChange as number,
                      representation
                    )
                  }
                  }
                />
              )}
              {variableType === 'INPUT_RANGE' && (
                <Slider
                  className={styles.slider}
                  color="secondary"
                  valueLabelDisplay="auto"
                  step={1}
                  marks
                  min={optionStiValue.min}
                  max={optionStiValue.max}
                  onChange={(_, valueOChange) => {
                    handleSliderDataChange(
                      valueOChange as number,
                      representation
                    )
                  }
                  }
                />
              )}
              <hr className={styles.separator} />
            </React.Fragment>
          )
        })}
        <div className={styles.cardGroup}>
          <div>
            <Typography variant="subtitle1">Total Achievement Score</Typography>
          </div>
          <div>
            <Typography variant="subtitle1" color="primary">
              {`${totalAchievementScore}%`}
            </Typography>
            <IconButton
              ref={totalAchievementRef}
              size="small"
              className={styles.helpButton}
            >
              <HelpIcon fontSize="small" />
            </IconButton>
            <TipHelpModal
              buttonRef={totalAchievementRef}
              title="Total Achievement Score"
              text={
                <FormattedMessage
                  id={TranslationKey.TOTAL_ACHIEVEMENT_SCORE_HELP}
                />
              }
            />
          </div>
        </div>
        <hr className={styles.separator} />
        <div className={styles.cardGroup}>
          <Link to={(bonus != null) ? '/compensation/achievement' : ''}>
            <Button
              variant="contained"
              color="primary"
              disabled={(bonus == null) || fetchingBonus}
            >
              {fetchingBonus}
              <span className={fetchingBonus ? styles.textMargin : undefined}>
                <FormattedMessage id={TranslationKey.CALCULATE_SCORE} />
              </span>
            </Button>
          </Link>
        </div>
      </Grid>
      <Grid item xs={false} sm={12} className={styles.disclaimer}>
        <Typography variant="body2">
          <FormattedMessage id={TranslationKey.COMPENSATION_DISCLAIMER} />
        </Typography>
      </Grid>
    </>
  )
}

export default SecondPanel
