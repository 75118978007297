import React, { type ReactElement, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { TranslationKey } from '../../i18n/translations'
import styles from './Footer.module.scss'
import { TermsModal } from './Modals'
import Privacy from './Modals/Privacy'

function Footer (): ReactElement {
  const termsRef = useRef(null)
  const privacy = useRef(null)

  return (
    <footer className={styles.footer}>
      <TermsModal buttonRef={termsRef} />
      <Privacy buttonRef={privacy} />

      <ul className={styles.textFooter}>
        <li className={styles.styleList}>
          <button ref={termsRef} type="button">
            <FormattedMessage id={TranslationKey.TERMS_AND_CONDITIONS} />
          </button>
        </li>
        <li className={styles.linkHidden}>
          <button ref={privacy} type="button">
            <FormattedMessage id={TranslationKey.PRIVACY_POLICY} />
          </button>
        </li>
        <li className={styles.linkHidden}>
          <a
            href="https://soporte.somosmaz.com/pqr/Benefi"
            rel="noreferrer"
            target="_blank"
            className={styles.linkText}
          >
            <FormattedMessage id={TranslationKey.USER_SUPPORT} />
          </a>
        </li>
        <li className={styles.linkHidden}>
          <span>
            <FormattedMessage
              id={TranslationKey.COPYRIGHT}
              values={{
                year: new Date().getFullYear()
              }}
            />
          </span>
        </li>
        <li className={styles.styleListMobile}>
          <button type="button">
            CopyRight AB-Inbev {new Date().getFullYear()}
          </button>
        </li>
      </ul>
    </footer>
  )
}

export default Footer
