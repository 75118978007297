import { Grid, Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import CircularProgress from '@material-ui/core/CircularProgress'
import FormControl from '@material-ui/core/FormControl'
import OutlinedInput from '@material-ui/core/OutlinedInput'
import Select from '@material-ui/core/Select'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import { useFormik } from 'formik'
import React, {
  type ChangeEvent,
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useParams } from 'react-router-dom'
import { ajax } from 'rxjs/ajax'
import { delay, map } from 'rxjs/operators'
import * as Yup from 'yup'
import { adminBenefitsAPI } from '../../../../api'
import { apiURL } from '../../../../config/environment'
import { CountryCode, HttpMethod } from '../../../../enums'
import { useDebounce } from '../../../../helpers/hooks'
import { TranslationKey } from '../../../../i18n/translations'
import isoCountries from '../../../../services/isoCountries'
import {
  type IBenefitSave,
  type IFormControlEdit,
  type ISuppliers,
  type typesFile
} from '../../../../types'
import styles from './Categories.module.scss'

function NewCategorieForm (props: IFormControlEdit): ReactElement {
  const { idItem, edit, data, saveData } = props
  const [options, setOptions] = useState<ISuppliers[]>([])
  const [open, setOpen] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [fetchLoading, setFetchLoading] = useState(false)
  const [message, setMessage] = useState('')
  const [file, setFile] = useState<File | string>('')
  const [supplierId, setSupplierId] = useState('')
  const [inputValue, setInputValue] = useState('')
  const { benefitID } = useParams()
  const intl = useIntl()
  const mountedRef = useRef(false)

  const debouncedValue = useDebounce(inputValue, 300)

  const countryOptions = useMemo(
    () =>
      Object.values(CountryCode).map((countryCode) => ({
        value: countryCode,
        label: isoCountries.getName(countryCode, intl.locale)
      })),
    [intl.locale]
  )

  const handleFile = useCallback((event: ChangeEvent<{ value: unknown }>) => {
    const target = event.currentTarget as HTMLInputElement
    setFile((target.files as FileList)[0])
  }, [])

  const saveBenefit = (values: IBenefitSave): void => {
    const dataForm = values
    dataForm.doc = file
    dataForm.categoryId = benefitID
    dataForm.supplierId = supplierId

    if (edit) {
      dataForm.id = idItem
    }

    setShowLoading(true)

    adminBenefitsAPI.ManagerBenefit(dataForm).subscribe(
      () => {
        setMessage('Beneficio creado.')
        if (saveData != null) {
          saveData(true)
        }
      },
      () => {
        setMessage('No se pudo guardar el beneficio, intenta nuevamente')
        setShowLoading(false)
      }
    )
  }

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value)
    },
    []
  )

  const SUPPORTED_FORMATS = ['application/pdf']

  const benefitSchema = Yup.object().shape({
    title: Yup.string().required('* Campo requerido'),
    type: Yup.string().required('* Campo requerido'),
    country: Yup.string().required('* Campo requerido'),
    description: Yup.string().required('* Campo requerido'),
    phone: Yup.string().nullable(),
    address: Yup.string().nullable(),
    webPage: Yup.string().nullable(),
    another: Yup.string().nullable(),
    doc: Yup.mixed().test(
      'doc',
      'Formato no soportado',
      // @ts-expect-error: Problemas de tipos de datos
      (value: typesFile | null | undefined): boolean =>
        (value == null) || ((value !== null) && SUPPORTED_FORMATS.includes(value.type))
    )
  })

  const initialValues: IBenefitSave = {
    title: edit ? String(data?.title) : '',
    supplierId: '',
    type: edit ? String(data?.type) : '',
    country: edit ? String(data?.country) : '',
    description: edit ? String(data?.description) : '',
    phone: edit ? String(data?.phone) : '',
    address: edit ? String(data?.address) : '',
    webPage: edit ? String(data?.web_page) : '',
    another: edit ? String(data?.another) : '',
    doc: ''
  }

  const formik = useFormik({
    initialValues,
    validationSchema: benefitSchema,
    onSubmit: (values: IBenefitSave) => {
      saveBenefit(values)
    }
  })

  useEffect(() => {
    if (!mountedRef.current || (debouncedValue !== null)) {
      mountedRef.current = true
      return undefined
    }

    setFetchLoading(true)

    const urlWithQuery = (apiURL !== null && typeof apiURL !== 'undefined')
      ? `${apiURL}/admin/suppliers?page=0&sortColumn=name&sortDirection=desc&pageSize=10&textFilter=${encodeURIComponent(
      debouncedValue
    )}`
      : ''

    const subscription$ = ajax({
      url: urlWithQuery,
      method: HttpMethod.Get,
      headers: {
        Accept: 'application/json; charset=UTF-8'
      },
      withCredentials: true
    })
      .pipe(
        // @ts-expect-error: Error de tipos
        delay(3000),
        map(
          ({ response }: { response: { suppliers: ISuppliers[] } }) =>
            response.suppliers
        )
      )
      .subscribe(
        (newOptions) => {
          setOptions(newOptions)
          setFetchLoading(false)
        },
        () => {
          setFetchLoading(false)
          setOptions([])
        }
      )

    return () => {
      subscription$.unsubscribe()
    }
  }, [debouncedValue])

  return (
    <Grid container className={styles.RowForm}>
      <form
        autoComplete="off"
        onSubmit={formik.handleSubmit}
        className={styles.contentForm}
      >
        <Grid item xs={12} className={styles.describeForm}>
          <strong>Información principal</strong>
        </Grid>
        <Grid item xs={12} className={styles.RowForm}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={styles.textInput}>
              Nombre del beneficio
            </Typography>
            <OutlinedInput
              labelWidth={0}
              placeholder="Nombre del beneficio"
              name="title"
              onChange={formik.handleChange}
              value={formik.values.title}
            />
            {(formik.errors.title !== null)
              ? (
              <div style={{ color: '#ff0000' }}> {formik.errors.title}</div>
                )
              : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} className={styles.RowForm}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={styles.textInput}>
              Tipo de beneficio
            </Typography>
            <Select
              name="type"
              onChange={formik.handleChange}
              value={formik.values.type}
            >
              <option value="">Seleccionar tipo</option>
              <option value="deal">Convenio</option>
              <option value="benefit">Beneficio</option>
            </Select>
            {(formik.errors.type !== null)
              ? (
              <div style={{ color: '#ff0000' }}> {formik.errors.type}</div>
                )
              : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} className={styles.RowForm}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={styles.textInput}>
              Seleccionar proveedor
            </Typography>
            <Autocomplete
              open={open}
              onOpen={() => {
                setOpen(true)
              }}
              onClose={() => {
                setOpen(false)
              }}
              getOptionSelected={(
                option: ISuppliers,
                optionValue: ISuppliers
              ) => option.id === optionValue.id}
              getOptionLabel={(option: ISuppliers) => `${option.name}`}
              renderOption={(option: ISuppliers) => (
                <div>
                  <Typography variant="body1">{option.name}</Typography>
                </div>
              )}
              options={options}
              loading={fetchLoading}
              loadingText={<FormattedMessage id={TranslationKey.LOADING} />}
              noOptionsText={
                (inputValue !== null)
                  ? (
                  <FormattedMessage
                    id={TranslationKey.AUTOCOMPLETE_NO_OPTIONS}
                  />
                    )
                  : (
                  <FormattedMessage
                    id={TranslationKey.AUTOCOMPLETE_INSTRUCTIONS}
                  />
                    )
              }
              onChange={(event, newValue) => {
                setSupplierId(String(newValue?.id))
              }}
              renderInput={(params) => (
                <TextField
                  {...params}
                  label=""
                  placeholder={intl.formatMessage({
                    id: TranslationKey.USER_SEARCH_INSTRUCTIONS
                  })}
                  variant="outlined"
                  size="medium"
                  value={inputValue}
                  onChange={handleInputChange}
                  fullWidth
                  InputLabelProps={{
                    shrink: false
                  }}
                  InputProps={{
                    ...params.InputProps,
                    endAdornment: (
                      <>
                        {fetchLoading
                          ? (
                          <CircularProgress color="inherit" size={20} />
                            )
                          : null}
                        {params.InputProps.endAdornment}
                      </>
                    )
                  }}
                />
              )}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} className={styles.RowForm}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={styles.textInput}>
              Seleccionar país
            </Typography>
            <Select
              name="country"
              value={formik.values.country}
              onChange={formik.handleChange}
            >
              <option value="">Seleccionar país</option>
              {countryOptions.map(({ value, label }) => (
                <option key={value} value={value}>{label}</option>
              ))}
            </Select>
            {(formik.errors.country !== null)
              ? (
              <div style={{ color: '#ff0000' }}> {formik.errors.country}</div>
                )
              : null}
          </FormControl>
        </Grid>
        <Grid item xs={12} className={styles.RowForm}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={styles.textInput}>
              Descripción del beneficio
            </Typography>
            <OutlinedInput
              labelWidth={0}
              placeholder="Escriba la descripción de máximo 500 caracteres"
              multiline
              rows={4}
              name="description"
              onChange={formik.handleChange}
              value={formik.values.description}
            />
          </FormControl>
          {(formik.errors.description !== null)
            ? (
            <div style={{ color: '#ff0000' }}> {formik.errors.description}</div>
              )
            : null}
        </Grid>

        <Grid item xs={5} className={styles.RowForm}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={styles.textInput}>Teléfono</Typography>
            <OutlinedInput
              labelWidth={0}
              placeholder="Escribe el teléfono"
              name="phone"
              onChange={formik.handleChange}
              value={formik.values.phone}
            />
          </FormControl>
        </Grid>
        <Grid xs={6} className={styles.RowForm}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={styles.textInput}>Dirección</Typography>
            <OutlinedInput
              labelWidth={0}
              placeholder="Dirección del proveedor"
              name="address"
              onChange={formik.handleChange}
              value={formik.values.address}
            />
          </FormControl>
        </Grid>
        <Grid item xs={6} className={styles.RowForm}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={styles.textInput}>Página web</Typography>
            <OutlinedInput
              labelWidth={0}
              placeholder="Dirección web"
              name="webPage"
              onChange={formik.handleChange}
              value={formik.values.webPage}
            />
          </FormControl>
        </Grid>
        <Grid item xs={5} className={styles.RowForm}>
          <FormControl variant="outlined" fullWidth>
            <Typography className={styles.textInput}>Otro contacto</Typography>
            <OutlinedInput
              labelWidth={0}
              placeholder="Otro contacto"
              name="another"
              onChange={formik.handleChange}
              value={formik.values.another}
            />
          </FormControl>
        </Grid>
        <Grid item xs={12} className={styles.RowForm}>
          <TextField
            fullWidth
            type="file"
            name="doc"
            variant="outlined"
            onChange={handleFile}
          />
        </Grid>
        <Grid item xs={12} style={{ textAlign: 'center' }}>
          <Button
            type="submit"
            variant="contained"
            color="primary"
            style={{ margin: 5 }}
            disabled={showLoading}
          >
            {showLoading
              ? (
              <CircularProgress color="secondary" size={20} />
                )
              : (
                  'Guardar'
                )}
          </Button>

          {message !== '' ? <p>{message}</p> : null}
        </Grid>
      </form>
    </Grid>
  )
}

export default NewCategorieForm
