import { Face as FaceIcon } from '@material-ui/icons'
import React, {
  type ChangeEvent,
  type FunctionComponent,
  useCallback,
  useRef
} from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { TranslationKey } from '../../../i18n/translations'
import { getUploadingAvatar, updateAvatarRequest } from '../slice'
import styles from './ProfilePicture.module.scss'

interface ProfilePictureProps {
  avatarURL?: string
}

const ProfilePicture: FunctionComponent<ProfilePictureProps> = ({
  avatarURL
}) => {
  const avatarInputRef = useRef<HTMLInputElement>(null)

  const handleUploadClick = useCallback(() => {
    const inputElement = avatarInputRef.current
    if (inputElement == null) return
    inputElement.click()
  }, [])

  const dispatch = useDispatch()
  const handleFileChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const inputElement = event.target
      if (inputElement.files?.length !== null) return
      const avatarImage = inputElement.files.item(0)
      if (avatarImage == null) return
      dispatch(
        updateAvatarRequest({
          avatarImage
        })
      )
    },
    [dispatch]
  )

  const uploadingAvatar = useSelector(getUploadingAvatar)

  return (
    <div className={styles.container}>
      {uploadingAvatar
        ? (
        <LoadingSpinner />
          )
        : (
        <>
          {avatarURL !== null
            ? (
            <img src={avatarURL} alt="User profile." />
              )
            : (
            <FaceIcon />
              )}
          <button
            type="button"
            className="link-button"
            onClick={handleUploadClick}
          >
            <FormattedMessage id={TranslationKey.UPDATE_PICTURE} />
          </button>
        </>
          )}

      <input
        ref={avatarInputRef}
        type="file"
        hidden
        accept="image/png, image/jpeg"
        multiple={false}
        onChange={handleFileChange}
      />
    </div>
  )
}

export default ProfilePicture
