import { Route, Routes } from 'react-router-dom'
import { ToastContainer } from 'react-toastify'
import 'react-toastify/dist/ReactToastify.css'
import Support from '../components/Support'
import Dashboard from '../modules/common/Dashboard'
import External from '../modules/common/External'
import MinuIframe from '../modules/minu/MinuIframe'
import { useUnauthorizedSessionIntercept } from '../modules/userSession/hooks'
import Session from '../modules/userSession/Session'
import Callback from '../modules/userSession/Session/callback'
import SessionSSO from '../modules/userSession/Session/SessionSSO'

function App (): JSX.Element {
  useUnauthorizedSessionIntercept()

  return (
    <>
      <Routes>
        <Route path="session/sso" element={<SessionSSO />} />
        <Route path="auth/callback" element={<Callback />} />
        <Route path="*" element={<Dashboard />} />
        <Route path="session/*" element={<Session />} />
        <Route path="external/*" element={<External />}>
          <Route path="help" element={<Support />} />
        </Route>
        <Route path="minu" element={<MinuIframe />} />
      </Routes>
      <ToastContainer />
    </>
  )
}

export default App
