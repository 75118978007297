import {
  Button,
  CircularProgress,
  Grid,
  makeStyles,
  TextField,
  Typography
} from '@material-ui/core'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, {
  type ChangeEvent,
  type ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { ajax } from 'rxjs/ajax'
import { delay, map } from 'rxjs/operators'
import { apiURL } from '../../../config/environment'
import { AppColor, HttpMethod } from '../../../enums'
import { useDebounce } from '../../../helpers/hooks'
import { TranslationKey } from '../../../i18n/translations'
import { logger } from '../../../services'
import { type RawAdminUser } from '../../../types'
import { adminUserMapper } from '../mappers'
import { getFetching, getSubmitting, setUsers } from '../slice'

const useStyles = makeStyles(() => ({
  popupIndicator: {
    color: AppColor.Primary
  }
}))

function UsersSearch (): ReactElement {
  const intl = useIntl()
  const submitting = useSelector(getSubmitting)
  const fetching = useSelector(getFetching)

  const [selectedUsers, setSelectedUsers] = useState<RawAdminUser[]>([])
  const handleUserFilterChange = useCallback(
    (_event: any, option: RawAdminUser[]) => {
      setSelectedUsers(option)
    },
    []
  )

  const [open, setOpen] = useState(false)

  const [options, setOptions] = useState<RawAdminUser[]>([])
  const [fetchLoading, setFetchLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const mountedRef = useRef(false)

  const debouncedValue = useDebounce(inputValue, 300)
  useEffect(() => {
    if (!mountedRef.current || (debouncedValue !== null)) {
      mountedRef.current = true
      return undefined
    }

    setFetchLoading(true)
    if (apiURL !== null && typeof apiURL !== 'undefined') {
      const urlWithQuery = `${apiURL}/admin/users?textFilter=${encodeURIComponent(
        debouncedValue
      )}`
      logger.info(`Autocompleting users with: ${urlWithQuery}`)
      const subscription$ = ajax({
        url: urlWithQuery,
        method: HttpMethod.Get,
        headers: {
          Accept: 'application/json; charset=UTF-8'
        },
        withCredentials: true
      })
        .pipe(
          // @ts-expect-error No se esperaba una promesa
          delay(3000),
          map(
            ({ response }: { response: { users: RawAdminUser[] } }) =>
              response.users
          )
        )
        .subscribe(
          (newOptions) => {
            setOptions(newOptions)
            setFetchLoading(false)
          },
          (err) => {
            logger.error(`Error autocompleting users with: ${urlWithQuery}`, err)
            setOptions([])
            setFetchLoading(false)
          }
        )
      return () => {
        subscription$.unsubscribe()
      }
    }
  }, [debouncedValue])

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value)
    },
    []
  )

  const dispatch = useDispatch()
  const handleAcceptClick = useCallback(() => {
    dispatch(
      setUsers({
        users: adminUserMapper(selectedUsers),
        count: 0,
        sortDisabled: true
      })
    )
  }, [dispatch, selectedUsers])

  const classes = useStyles()

  return (
    <Grid container alignItems="center" spacing={2}>
      <Grid item xs={11}>
        <Autocomplete
          multiple
          classes={classes}
          open={open}
          onOpen={() => {
            setOpen(true)
          }}
          onClose={() => {
            setOpen(false)
          }}
          getOptionSelected={(
            option: RawAdminUser,
            optionValue: RawAdminUser
          ) => option.sharp === optionValue.sharp}
          getOptionLabel={(option: RawAdminUser) =>
            `${option.sharp}, ${option.name}, ${option.email}`
          }
          renderOption={(option: RawAdminUser) => (
            <div>
              <Typography variant="body1">{option.name}</Typography>
              <Typography variant="body2" color="textSecondary">
                <small>{option.email}</small>
              </Typography>
            </div>
          )}
          options={options}
          loading={fetchLoading}
          disabled={submitting ?? fetching}
          onChange={handleUserFilterChange}
          value={selectedUsers}
          loadingText={<FormattedMessage id={TranslationKey.LOADING} />}
          noOptionsText={
            (inputValue !== null)
              ? (
              <FormattedMessage id={TranslationKey.AUTOCOMPLETE_NO_OPTIONS} />
                )
              : (
              <FormattedMessage id={TranslationKey.AUTOCOMPLETE_INSTRUCTIONS} />
                )
          }
          renderInput={(params) => (
            <TextField
              {...params}
              label=""
              placeholder={intl.formatMessage({
                id: TranslationKey.USER_SEARCH_INSTRUCTIONS
              })}
              variant="outlined"
              size="medium"
              value={inputValue}
              onChange={handleInputChange}
              fullWidth
              InputLabelProps={{
                shrink: false
              }}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <>
                    {fetchLoading
                      ? (
                      <CircularProgress color="inherit" size={20} />
                        )
                      : null}
                    {params.InputProps.endAdornment}
                  </>
                )
              }}
            />
          )}
        />
      </Grid>

      <Grid item xs={1}>
        <Button
          variant="outlined"
          color="primary"
          disabled={(selectedUsers.length === 0) ?? submitting ?? fetching}
          onClick={handleAcceptClick}
        >
          <FormattedMessage id={TranslationKey.ACCEPT} />
        </Button>
      </Grid>
    </Grid>
  )
}

export default UsersSearch
