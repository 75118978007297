import {
  Typography
} from '@material-ui/core'
import React, {
  type FunctionComponent,
  type RefObject
} from 'react'
import { FormattedMessage } from 'react-intl'
import GeneralModal from '../../../components/GeneralModal'
import { TranslationKey } from '../../../i18n/translations'

interface TermsModalProps {
  buttonRef: RefObject<HTMLElement>
}

const Privacy: FunctionComponent<TermsModalProps> = ({ buttonRef }) => (
        <GeneralModal isOpen buttonRef={buttonRef} maxWidth={700}>
            <Typography
                variant="h5"
                color="primary"
                style={{
                  marginTop: 20,
                  marginBottom: 20
                }}
            >
                <FormattedMessage id={TranslationKey.PRIVACY_POLICY} />
            </Typography>

            <div
                style={{
                  textAlign: 'justify',
                  whiteSpace: 'pre-wrap',
                  width: '100%'
                }}
            >
                <FormattedMessage id={TranslationKey.PRIVACY} />
            </div>

        </GeneralModal>
)

export default Privacy
