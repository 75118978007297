import React, { type ReactElement } from 'react'
import soonimg from './construccion.jpg'
import styles from '../Certifications/Certifications.module.scss'

function SoonTicket (): ReactElement {
  return <div className={styles.container}>
  
    <div className={styles.content}>
      <img
        src={soonimg}
        alt="Imagen de recibos proximamente"
        className={styles.imgMobil}
      />
    </div>
  </div>
}

export default SoonTicket
