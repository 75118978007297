import { Typography } from '@material-ui/core'
import Button from '@material-ui/core/Button'
import Dialog from '@material-ui/core/Dialog'
import DialogContent from '@material-ui/core/DialogContent'
import DialogContentText from '@material-ui/core/DialogContentText'
import DialogTitle from '@material-ui/core/DialogTitle'
import { Add as AddIcon } from '@material-ui/icons'
import React, { useState, type ReactElement } from 'react'
import { type IModalForms } from '../../../../types'
import styles from './Categories.module.scss'
import FormBenefi from './NewBenefits'
import FormCategorie from './NewCategorie'

function ModalFormCategorie (props: IModalForms): ReactElement {
  const [open, setOpen] = useState(false)
  const { nameBtn, showForm, idRow, edit, data, saveData, dataCat } = props

  const handleClick = (): void => {
    setOpen(true)
  }

  const handleClose = (): void => {
    setOpen(false)
  }

  const handleSaveCategorie = (save: boolean): void => {
    if (saveData != null) {
      saveData(save)
    }
  }

  const handleSaveBenefit = (save: boolean): void => {
    if (saveData != null) {
      saveData(save)
    }
  }

  return (
    <div>
      {edit
        ? (
        <Button
          variant="text"
          className={styles.textEdit}
          onClick={handleClick}
        >
          {nameBtn}
        </Button>
          )
        : (
        <Button variant="outlined" color="primary" onClick={handleClick}>
          <AddIcon color="primary" />
          {nameBtn}
        </Button>
          )}

      <Dialog
        open={open}
        onClose={handleClose}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
        className={styles.borderModal}
      >
        <DialogTitle id="alert-dialog-title">
          <Typography
            variant="h5"
            color="primary"
            className={styles.textCenter}
          >
            {nameBtn}
          </Typography>
        </DialogTitle>
        <DialogContent className={styles.cotentModal}>
          <DialogContentText id="alert-dialog-description">
            {showForm
              ? (
              <FormCategorie edit={edit} idItem={idRow} saveData={handleSaveCategorie} dataCat={dataCat} />
                )
              : (
              <FormBenefi idItem={idRow} edit={edit} data={data} saveData={handleSaveBenefit} />
                )}
          </DialogContentText>
        </DialogContent>
      </Dialog>
    </div>
  )
}

export default ModalFormCategorie
