import { StatusCodes } from 'http-status-codes'
import { useEffect } from 'react'
import { useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { toast } from 'react-toastify'
import { fromEvent, interval, merge } from 'rxjs'
import { type AjaxRequest, type AjaxResponse } from 'rxjs/ajax'
import { skipWhile, switchMap, take, tap, throttleTime } from 'rxjs/operators'
import xhook from 'xhook'
import appConfig from '../../config/app'
import { TranslationKey } from '../../i18n/translations'
import { logger } from '../../services'
import history from '../../history'
import {
  clearUserSession,
  getLocale,
  getUser,
  logoutUserRequest
} from './slice'

export const useUnauthorizedSessionIntercept = (): void => {
  const dispatch = useDispatch()
  useEffect(() => {
    xhook.after((req: AjaxRequest, res: AjaxResponse<any>) => {
      if (
        res.status === StatusCodes.UNAUTHORIZED &&
        !req.url?.includes('/api/user-session') &&
        !req.url?.includes('/api/register') &&
        !req.url?.includes('/api/sign-in') &&
        !req.url?.includes('/api/verify-code')
      ) {
        logger.info('Logged user out of session', req, res)
        dispatch(clearUserSession())
        history.push({
          pathname: '/session/login',
          search: ''
        })
      }
    })
  }, [dispatch])
}

const dateFormatPartMap: Record<string, 'M' | 'd' | 'y'> = {
  month: 'M',
  day: 'd',
  year: 'y'
}

export const useDateFormatString = (): string => {
  const intl = useIntl()
  const locale = useSelector(getLocale)

  const formatParts = intl.formatters.getDateTimeFormat(locale).formatToParts()
  return formatParts.reduce((dateFormat, next) => {
    const mappedChar = dateFormatPartMap[next.type] ?? next.value
    // @ts-expect-error: Problemas de tipos
    const newStr = Array.from(next.value)
      .map(() => mappedChar)
      .join('')
    return `${dateFormat}${newStr}`
  }, '')
}

export const useInactivityTimer = (): void => {
  const user = useSelector(getUser)
  const intl = useIntl()
  const dispatch = useDispatch()

  useEffect(() => {
    if (user == null) return undefined

    let toastId: string | number | undefined

    function hideWarning (): void {
      if (toastId !== undefined) {
        toast.dismiss(toastId)
        toastId = undefined
      }
    }

    function showWarning (): void {
      const minutes = Math.floor(
        appConfig.SECONDS_BEFORE_INACTIVITY_LOGOUT / 60
      )
      toastId = toast.error(
        intl.formatMessage(
          {
            id: TranslationKey.INACTIVITY_WARNING
          },
          {
            minutes
          }
        ),
        {
          position: 'bottom-right',
          draggable: false,
          pauseOnFocusLoss: false,
          autoClose: false,
          closeOnClick: false,
          pauseOnHover: false,
          hideProgressBar: true
        }
      )
    }

    const subscription = merge(
      fromEvent(document, 'click'),
      fromEvent(document, 'wheel'),
      fromEvent(document, 'scroll'),
      fromEvent(document, 'mousemove'),
      fromEvent(document, 'keyup'),
      fromEvent(window, 'resize'),
      fromEvent(window, 'scroll'),
      fromEvent(window, 'mousemove')
    )
      .pipe(
        throttleTime(300),
        tap(hideWarning),
        switchMap(() =>
          interval(1000).pipe(take(appConfig.MAX_INACTIVITY_SECONDS + 1))
        ),
        tap((inactivitySeconds) => {
          const secondsLeft =
            appConfig.MAX_INACTIVITY_SECONDS - inactivitySeconds
          if (
            secondsLeft <= appConfig.SECONDS_BEFORE_INACTIVITY_LOGOUT &&
            toastId === undefined
          ) {
            showWarning()
          }
        }),
        skipWhile(
          (inactivitySeconds) =>
            inactivitySeconds < appConfig.MAX_INACTIVITY_SECONDS
        )
      )
      .subscribe(() => {
        hideWarning()
        logger.info('Logged user out of session from inactivity.')
        dispatch(logoutUserRequest())
      })

    return () => {
      subscription.unsubscribe()
    }
  }, [dispatch, intl, user])
}
