import { shouldPolyfill } from '@formatjs/intl-datetimeformat/should-polyfill'

export default async function polyfill (): Promise<void> {
  if (typeof shouldPolyfill() !== 'undefined' && shouldPolyfill() !== null) {
    // Load the polyfill 1st BEFORE loading data
    await import('@formatjs/intl-datetimeformat/polyfill')
  }
  if ((Intl.DateTimeFormat as any).polyfilled !== null) {
    await import('@formatjs/intl-datetimeformat/locale-data/en')
    await import('@formatjs/intl-datetimeformat/locale-data/fr')
  }
}
