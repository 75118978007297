import { useFormikContext } from 'formik'
import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { getFlattenedFields } from '../helpers/util-functions'
import { getLocale } from '../modules/userSession/slice'

/**
 * Updates error field translations on locale change
 */
function TranslateFormikErrors (): null {
  const locale = useSelector(getLocale)
  const { errors, touched, setFieldTouched } = useFormikContext()

  useEffect(() => {
    /**
     * The `setTimeout` (push to the end of the event queue)
     * is needed so it waits for the locale to change.
     */
    setTimeout(() => {
      const flattenedErrors = getFlattenedFields(errors)
      flattenedErrors.forEach((fieldName) => {
        if (Object.keys(touched).includes(fieldName)) {
          setFieldTouched(fieldName)
        }
      })
    }, 0)
  }, [errors, locale, setFieldTouched, touched])

  return null
}

export default TranslateFormikErrors
