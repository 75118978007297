import React, { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { loginUserRequest } from '../slice'

function Callback (): JSX.Element {
  const dispatch = useDispatch()

  useEffect(() => {
    const urlSearchParams = new URLSearchParams(window.location.search)
    const params = Object.fromEntries(urlSearchParams.entries())
    const state = sessionStorage.getItem('state')

    if (params.state === state) {
      dispatch(
        loginUserRequest({
          code: params?.code,
          state: params?.state
        })
      )
    }
  }, [dispatch])
  return <div>
    <div className="full-spinner-container">
      <LoadingSpinner />
    </div>
  </div>
}

export default Callback
