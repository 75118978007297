import { Button, Typography } from '@material-ui/core'
import { Form, Formik, useFormikContext } from 'formik'
import React, { type ReactElement, useCallback, useEffect, useMemo } from 'react'
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import * as yup from 'yup'
import illustration from '../../../assets/images/icon-account-verification.svg'
import { FormDateField } from '../../../components/FormFields'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { useQueryParams } from '../../../helpers/hooks'
import { testCredentialsError } from '../../../helpers/util-functions'
import { TranslationKey } from '../../../i18n/translations'
import TranslateFormikErrors from '../../../wrappers/TranslateFormikErrors'
import { getSending, verifyCodeRequest } from '../slice'
import CredentialsErrorComponent from './CredentialsError'
import styles from './Session.module.scss'

enum VerifyCodeField {
  CredentialsError = 'credentialsError',
  BirthDate = 'birthDate',
  Code = 'code',
}

interface VerifyCodeFormValues {
  [VerifyCodeField.CredentialsError]?: boolean
  [VerifyCodeField.BirthDate]: Date | null
  [VerifyCodeField.Code]: number | ''
}

const getValidationSchema = (intl: IntlShape): yup.ObjectSchema<any> =>
  yup.object().shape({
    [VerifyCodeField.CredentialsError]: yup.boolean(),
    [VerifyCodeField.BirthDate]: yup
      .date()
      .nullable()
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.BIRTH_DATE
            })
          }
        )
      )
      .test(
        'no-credentials-error',
        intl.formatMessage({
          id: TranslationKey.INVALID_BIRTH_DATE_ERROR
        }),
        testCredentialsError
      )
  })

// Initializes code value in Formik from query parameters
function SetCodeValue (): null {
  const { setFieldValue } = useFormikContext<VerifyCodeFormValues>()
  const queryParams = useQueryParams()
  useEffect(() => {
    setFieldValue(VerifyCodeField.Code, queryParams.get(VerifyCodeField.Code))
  }, [])
  return null
}

const initialValues: VerifyCodeFormValues = {
  [VerifyCodeField.CredentialsError]: false,
  [VerifyCodeField.BirthDate]: null,
  [VerifyCodeField.Code]: ''
}

const initialErrors = {
  [VerifyCodeField.BirthDate]: '',
  [VerifyCodeField.Code]: ''
}

function VerifyCode (): ReactElement {
  const sending = useSelector(getSending)

  const dispatch = useDispatch()
  const handleSubmit = useCallback(
    (values: VerifyCodeFormValues) => {
      dispatch(
        verifyCodeRequest({
          date: values[VerifyCodeField.BirthDate] as Date,
          code: values[VerifyCodeField.Code] as number
        })
      )
    },
    [dispatch]
  )

  const intl = useIntl()
  const validationSchema = useMemo(() => getValidationSchema(intl), [intl])

  return (
    <>
      <div className={styles.textContainer}>
        <img src={illustration} alt="Person with magnifying glass" />

        <Typography variant="body2">
          <FormattedMessage id={TranslationKey.VERIFY_CODE_MESSAGE} />
        </Typography>
      </div>

      <Formik
        initialValues={initialValues}
        initialErrors={initialErrors}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Form autoComplete="off" noValidate>
          <TranslateFormikErrors />
          <CredentialsErrorComponent />
          <SetCodeValue />

          {/* Birth Date */}
          <FormDateField
            name={VerifyCodeField.BirthDate}
            label={intl.formatMessage({
              id: TranslationKey.BIRTH_DATE
            })}
            placeholder={intl.formatMessage({
              id: TranslationKey.BIRTH_DATE_PLACEHOLDER
            })}
            disabled={sending}
            required
          />

          <div className={styles.buttonContainer}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={sending}
            >
              <FormattedMessage id={TranslationKey.VERIFY_CODE} />
            </Button>
          </div>

          {sending && (
            <div>
              <LoadingSpinner />
            </div>
          )}
        </Form>
      </Formik>
    </>
  )
}

export default VerifyCode
