import Container from '@material-ui/core/Container'
import React, { type ReactElement } from 'react'
import styles from './EditProfile.module.scss'
import PasswordChangeForm from './PasswordChangeForm'
import ProfileForm from './ProfileForm'

function EditProfile (): ReactElement {
  return <Container maxWidth="sm" className={styles.contentProfile}>
    <ProfileForm />
    <br />
    <PasswordChangeForm />
  </Container>
}

export default EditProfile
