import { createIntl, createIntlCache, type IntlCache, type IntlShape } from 'react-intl'
import { type Locale } from '../enums'
import translations from './translations'

/**
 * Holds the intl object so it's accessible outside React components.
 * It gets updated when `locale` changes.
 */
class IntlHelper {
  private intl?: IntlShape

  private intlCache?: IntlCache

  setIntl (locale: Locale): IntlShape {
    this.intlCache = createIntlCache()
    this.intl = createIntl(
      {
        locale,
        messages: translations[locale]
      },
      this.intlCache
    )
    return this.intl
  }

  getIntl (): IntlShape | undefined {
    return this.intl
  }
}

export default new IntlHelper()
