import { Button, Typography } from '@material-ui/core'
import { Form, Formik } from 'formik'
import React, { type ReactElement, useCallback, useMemo } from 'react'
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import illustration from '../../../assets/images/icon-phone-password.svg'
import { FormTextField } from '../../../components/FormFields'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { TranslationKey } from '../../../i18n/translations'
import TranslateFormikErrors from '../../../wrappers/TranslateFormikErrors'
import { forgotPasswordRequest, getSending } from '../slice'
import styles from './Session.module.scss'

interface ForgotPasswordValues {
  email: string
}

const getValidationSchema = (intl: IntlShape): yup.ObjectSchema<any> =>
  yup.object({
    email: yup
      .string()
      .email(
        intl.formatMessage({
          id: TranslationKey.VALIDATE_EMAIL
        })
      )
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_EMAIL
          },
          {
            fieldName: 'Email'
          }
        )
      )
  })

const initialValues = {
  email: ''
}

function ForgotPassword (): ReactElement {
  const sending = useSelector(getSending)

  const dispatch = useDispatch()
  const handleSubmit = useCallback(
    (values: ForgotPasswordValues) => {
      dispatch(
        forgotPasswordRequest({
          email: values.email
        })
      )
    },
    [dispatch]
  )

  const intl = useIntl()
  const validationSchema = useMemo(() => getValidationSchema(intl), [intl])

  return (
    <>
      <div className={styles.textContainer}>
        <img src={illustration} alt="Phone with password." />

        <Typography variant="body2" className={styles.bold}>
          <FormattedMessage id={TranslationKey.FORGOT_PWD} />
        </Typography>
        <Typography variant="body2">
          <FormattedMessage id={TranslationKey.FORGOT_PWD_MESSAGE} />
        </Typography>
      </div>

      <Formik
        initialValues={initialValues}
        initialErrors={initialValues}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        <Form autoComplete="off">
          <TranslateFormikErrors />

          {/* Email Field */}
          <FormTextField
            name="email"
            label={intl.formatMessage({
              id: TranslationKey.EMAIL
            })}
            placeholder={intl.formatMessage({
              id: TranslationKey.EMAIL_PLACEHOLDER
            })}
            type="email"
          />

          <div className={styles.buttonContainer}>
            <Button
              type="submit"
              variant="contained"
              color="primary"
              disabled={sending}
            >
              <FormattedMessage id={TranslationKey.SEND_LINK} />
            </Button>

            {sending && (
              <div className={styles.spinnerContainer}>
                <LoadingSpinner />
              </div>
            )}

            <div className={styles.linksContainer}>
              <Link to="/external/help">
                <FormattedMessage id={TranslationKey.NO_LINK} />
              </Link>
              <Link to="/session/login">
                <FormattedMessage id={TranslationKey.RETURN} />
              </Link>
            </div>
          </div>
        </Form>
      </Formik>
    </>
  )
}

export default ForgotPassword
