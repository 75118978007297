import { type Observable } from 'rxjs'
import { ajax, type AjaxResponse } from 'rxjs/ajax'
import { apiURL } from '../config/environment'
import { HttpMethod } from '../enums'
import { type IPayroll } from '../types'

const contentTypeJSONHeader = {
  'Content-Type': 'application/json'
}
const acceptJSONHeader = {
  Accept: 'application/json; charset=UTF-8'
}

interface FetchPayrollDataResponse extends AjaxResponse<any> {
  response: IPayroll[]
}
interface FetchStringDataResponse extends AjaxResponse<any> {
  response: string[]
}

interface DownloadPayroll extends AjaxResponse<any> {
  response: any
}

export const fetchPayrollData = (
  date: string
): Observable<FetchPayrollDataResponse> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/payroll?period=${date}` : '',
    method: HttpMethod.Get,
    headers: {
      ...acceptJSONHeader,
      ...contentTypeJSONHeader
    },
    withCredentials: true
  })
  export const fetchFilterData = (
    data: string
  ): Observable<FetchStringDataResponse> =>
    ajax({
      url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/admin/payroll/autocomplete` : '',
      method: HttpMethod.Post,
      headers: {
        ...acceptJSONHeader,
        ...contentTypeJSONHeader
      },
      withCredentials: true,
      body: data
    })
    export const fetchPayrollPreview = (
      data: string
    ): Observable<FetchStringDataResponse> =>
      ajax({
        url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/admin/payroll/filter` : '',
        method: HttpMethod.Post,
        headers: {
          ...acceptJSONHeader,
          ...contentTypeJSONHeader
        },
        withCredentials: true,
        body: data
      })

      export const DownloadPayrollData = (
        idPayroll: string | number
      ): Observable<DownloadPayroll> =>
        ajax({
          url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/payroll/${idPayroll}` : '',
          method: HttpMethod.Get,
          responseType: 'blob',
          withCredentials: true
        })

        export const StartDownloadPayrollAllData = (
          data: string
         ): Observable<DownloadPayroll> =>
           ajax({
             url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/admin/payroll/download` : '',
             method: HttpMethod.Post,
             withCredentials: true,
             body: data
           })
           export const DownloadPayrollAllData = (id:string): Observable<DownloadPayroll> =>
             ajax({
               url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/admin/payroll/download?file=${id}` : '',
               method: HttpMethod.Get,
               withCredentials: true
             })
