import {
  type Action,
  createSelector,
  createSlice,
  type PayloadAction
} from '@reduxjs/toolkit'
import { differenceInDays, differenceInMonths, startOfDay } from 'date-fns'
import { StatusCodes } from 'http-status-codes'
import { toast } from 'react-toastify'
import { combineEpics, type Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { type AjaxError } from 'rxjs/ajax'
import { catchError, map, mergeMap, tap } from 'rxjs/operators'
import { userSessionAPI } from '../../api'
import appConfig from '../../config/app'
import toastConfig from '../../config/toast'
import { CountryCode, type Locale, UserRole } from '../../enums'
import {
  getLocaleFromBrowser,
  mapBackendPublicURL
} from '../../helpers/util-functions'
import history from '../../history'
import intlHelper from '../../i18n/intlHelper'
import { TranslationKey } from '../../i18n/translations'
import { logger } from '../../services'
import { type User } from '../../types'
import { mapRawUser } from './mappers'
import { URL_CALLBACK, URL_SSO, appURL } from '../../config/environment'

/**
 * Action Payloads
 */
interface LoginRequestPayload {
  code: string
  state: string
}

interface SetCredentialsErrorPayload {
  credentialsError: boolean
}

interface CreateUserRequestPayload {
  sharp: number
  email: string
  password: string
}

interface VerifyCodeRequestPayload {
  date: Date
  code: number
}

interface ForgotPasswordRequestPayload {
  email: string
}

interface PasswordRecoveryRequestPayload {
  newPassword: string
  currentPassword?: string
  token?: string
}

export interface SetUserSessionPayload {
  user: User
}

interface SetLocalePayload {
  locale: Locale
}

interface UpdateAvatarRequestPayload {
  avatarImage: File
}

interface UpdateAvatarPayload {
  avatar?: string
}

interface UpdateProfileRequestPayload {
  name: string
  email: string
}

interface UpdateProfilePayload {
  name: string | null
}

interface EmailChangeVerificationRequestPayload {
  token: string
}

interface UpdateUserEmailPayload {
  email: string | null
}

/**
 * State
 */
interface UserSessionState {
  fetchingSession: boolean
  sendingRequest: boolean
  locale: Locale
  user: User | null
  credentialsError: boolean
  uploadingAvatar: boolean
  updatingProfile: boolean
}

const initialState: UserSessionState = {
  fetchingSession: true,
  sendingRequest: false,
  locale: getLocaleFromBrowser(),
  user: null,
  credentialsError: false,
  uploadingAvatar: false,
  updatingProfile: false
}

/**
 * Slice
 */
const userSessionSlice = createSlice({
  name: 'userSession',
  initialState,
  reducers: {
    fetchUserSession (state) {
      state.fetchingSession = true
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    loginUserRequest (state, _action: PayloadAction<LoginRequestPayload>) {
      state.sendingRequest = true
      state.credentialsError = false
    },
    setCredentialsError (
      state,
      action: PayloadAction<SetCredentialsErrorPayload>
    ) {
      state.sendingRequest = false
      state.user = null
      state.fetchingSession = false
      state.credentialsError = action.payload.credentialsError
    },
    // eslint-disable-next-line @typescript-eslint/no-empty-function
    logoutUserRequest (state) {
      state.fetchingSession = true
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    createUserRequest (state, _action: PayloadAction<CreateUserRequestPayload>) {
      state.sendingRequest = true
      state.credentialsError = false
    },
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    verifyCodeRequest (state, _action: PayloadAction<VerifyCodeRequestPayload>) {
      state.sendingRequest = true
      state.credentialsError = false
    },
    forgotPasswordRequest (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<ForgotPasswordRequestPayload>
    ) {
      state.sendingRequest = true
      state.credentialsError = false
    },
    passwordRecoveryRequest (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<PasswordRecoveryRequestPayload>
    ) {
      state.sendingRequest = true
      state.credentialsError = false
    },
    setUserSession (state, action: PayloadAction<SetUserSessionPayload>) {
      state.fetchingSession = false
      state.sendingRequest = false
      state.credentialsError = false
      state.user = action.payload.user
    },
    clearUserSession (state: UserSessionState) {
      state.user = null
      state.fetchingSession = false
      state.sendingRequest = false
      state.credentialsError = false
    },
    setLocale (state, action: PayloadAction<SetLocalePayload>) {
      state.locale = action.payload.locale
    },
    updateAvatarRequest (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<UpdateAvatarRequestPayload>
    ) {
      state.uploadingAvatar = true
    },
    updateAvatar (state, action: PayloadAction<UpdateAvatarPayload>) {
      state.uploadingAvatar = false
      if (state.user != null && action.payload.avatar !== null) {
        state.user.avatar = mapBackendPublicURL(action.payload.avatar)
      }
    },
    updateProfileRequest (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<UpdateProfileRequestPayload>
    ) {
      state.updatingProfile = true
    },
    cancelUpdateProfile (state) {
      state.updatingProfile = false
    },
    updateProfile (state, action: PayloadAction<UpdateProfilePayload>) {
      state.updatingProfile = false
      if (state.user == null) return
      if (action.payload.name !== null) {
        state.user.nickname = action.payload.name
      }
    },
    emailChangeVerificationRequest (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<EmailChangeVerificationRequestPayload>
    ) {
      state.sendingRequest = true
    },
    updateUserEmail (state, action: PayloadAction<UpdateUserEmailPayload>) {
      state.sendingRequest = false
      if (action.payload.email !== null && state.user != null) {
        state.user.email = action.payload.email
      }
    }
  }
})

/**
 * Actions
 */
export const {
  fetchUserSession,
  loginUserRequest,
  setCredentialsError,
  logoutUserRequest,
  createUserRequest,
  verifyCodeRequest,
  forgotPasswordRequest,
  passwordRecoveryRequest,
  setUserSession,
  clearUserSession,
  setLocale,
  updateAvatarRequest,
  updateAvatar,
  updateProfileRequest,
  cancelUpdateProfile,
  updateProfile,
  emailChangeVerificationRequest,
  updateUserEmail
} = userSessionSlice.actions

/**
 * Selectors
 */
const getModuleState = (state: { userSession: UserSessionState }): UserSessionState => {
  return state.userSession
}

export const getFetching = createSelector(
  getModuleState,
  (state) => state.fetchingSession
)

export const getSending = createSelector(
  getModuleState,
  (state) => state.sendingRequest
)

export const getCredentialsError = createSelector(
  getModuleState,
  (state) => state.credentialsError
)

export const getLocale = createSelector(
  getModuleState,
  (state) => state.locale
)

export const getUser = createSelector(getModuleState, (state) => state.user)
export const getRole = createSelector(getUser, (user) => user?.role)

export const getUserID = createSelector(getUser, (user) => user?.id)
export const getUserBand = createSelector(getUser, (user) => user?.band)
export const getUserBU = createSelector(getUser, (user) => user?.businessUnit)
export const getUserRole = createSelector(getUser, (user) => user?.role)
export const getUserCountryCode = createSelector(
  getUser,
  (user) => user?.country
)

export const getUserHomeCountryCode = createSelector(
  getUser,
  (user) => user?.homeCoutry
)

export const getShowCompensation = createSelector(
  [getUserCountryCode, getUserHomeCountryCode],
  (countryCode, homeCountryCode) =>
    countryCode === CountryCode.Mexico && homeCountryCode !== null
)

export const getUserMonthsServed = createSelector(getUser, (user) =>
  user?.hiringDate != null
    ? Math.min(12, Math.abs(differenceInMonths(new Date(), user?.hiringDate)))
    : 0
)

export const getUserGender = createSelector(getUser, (user) => user?.gender)

export const getUploadingAvatar = createSelector(
  getModuleState,
  (state) => state.uploadingAvatar
)

export const getUpdatingProfile = createSelector(
  getModuleState,
  (state) => state.updatingProfile
)

/**
 * Epics
 */
type FetchUserSessionAction = ReturnType<typeof fetchUserSession>
type SetUserSessionAction = ReturnType<typeof setUserSession>
type ClearUserSessionAction = ReturnType<typeof clearUserSession>
type SetCredentialsErrorAction = ReturnType<typeof setCredentialsError>
type LoginRequestAction = ReturnType<typeof loginUserRequest>
type LogoutUserRequestAction = ReturnType<typeof logoutUserRequest>
type CreateUserRequestAction = ReturnType<typeof createUserRequest>
type VerifyCodeRequestAction = ReturnType<typeof verifyCodeRequest>
type ForgotPasswordRequestAction = ReturnType<typeof forgotPasswordRequest>
type PasswordRecoveryRequestAction = ReturnType<typeof passwordRecoveryRequest>
type UpdateAvatarRequestAction = ReturnType<typeof updateAvatarRequest>
type UpdateAvatarAction = ReturnType<typeof updateAvatar>
type UpdateProfileRequestAction = ReturnType<typeof updateProfileRequest>
type UpdateProfileAction = ReturnType<typeof updateProfile>
type CancelUpdateProfileAction = ReturnType<typeof cancelUpdateProfile>
type EmailChangeVerificationRequestAction = ReturnType<
  typeof emailChangeVerificationRequest
>
type UpdateUserEmailAction = ReturnType<typeof updateUserEmail>

const fetchUserSessionEpic: Epic<
Action,
SetUserSessionAction | ClearUserSessionAction
> = (action$) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, FetchUserSessionAction>(fetchUserSession.type),
    mergeMap(() =>
      userSessionAPI.fetchUserSession().pipe(
        map(({ response }) => mapRawUser(response.user)),
        map((user) => {
          // logger.info('Fetched user session.', user)
          return setUserSession({ user })
        }),
        tap(() => {
          if (history.location.pathname.includes('/session')) {
            history.push({
              pathname: '/',
              search: ''
            })
          }
        }),
        catchError((error) => {
          logger.error('Error fetching user session.', error)
          if (
            !history.location.pathname.includes('/session') &&
            !history.location.pathname.includes('/external')
          ) {
            history.push({
              pathname: '/session/login',
              search: ''
            })
          }
          
          return of(clearUserSession())
        })
      )
    )
  )

const loginUserRequestEpic: Epic<
Action,
SetUserSessionAction | ClearUserSessionAction | SetCredentialsErrorAction
> = (action$) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, LoginRequestAction>(loginUserRequest.type),
    // @ts-expect-error: Problemas de tipos
    mergeMap(({ payload }) =>
      userSessionAPI.loginUser(payload.code, payload.state).pipe(
        map(({ response }) => mapRawUser(response.user)),
        tap((user) => {
          const isAdmin = user.role === UserRole.Admin
          const maxValidDays = isAdmin ? 30 : 90
          const daysFromLastChange = differenceInDays(
            startOfDay(new Date()),
            startOfDay(user.lastPasswordChange)
          )
          const daysUntilExpiration = maxValidDays - daysFromLastChange
          if (daysUntilExpiration <= appConfig.PASSWORD_CHANGE_WARNING_DAYS) {
            const intl = intlHelper.getIntl()
            toast(
              intl?.formatMessage(
                {
                  id: TranslationKey.PASSWORD_CHANGE_WARNING
                },
                {
                  days: daysUntilExpiration
                }
              ),
              toastConfig
            )
          }
        }),
        map((user) => {
          logger.info('Logged in user.', user)
          return setUserSession({
            user
          })
        }),
        tap(() => {
          // `setTimeout` forces wait on user login on Redux store
          setTimeout(() => {
            history.push({
              pathname: '/',
              search: ''
            })
          }, 0)
        }),
        catchError((error: AjaxError) => {
          if (
            ![
              StatusCodes.UNAUTHORIZED,
              StatusCodes.UNPROCESSABLE_ENTITY,
              StatusCodes.NOT_ACCEPTABLE,
              StatusCodes.NOT_FOUND
            ].includes(error.status)
          ) {
            throw error
          }

          sessionStorage.removeItem('state')

          history.push({
            pathname: '/session/login',
            search: ''
          })

          return of(
            setCredentialsError({
              credentialsError: true
            })
          )
        }),
        catchError((error) => {
          logger.error('Error login user.', error)
          sessionStorage.removeItem('state')
          history.push({
            pathname: '/session/login',
            search: ''
          })
          toast.error('Error sending login request.', toastConfig)
          return of(clearUserSession())
        })
      )
    )
  )

export const logoutUserRequestEpic: Epic<Action, ClearUserSessionAction> = (
  action$
) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, LogoutUserRequestAction>(logoutUserRequest.type),
    mergeMap(() =>
      userSessionAPI.logoutUser().pipe(
        map((response) => {
          logger.info('User logout.', response)
          sessionStorage.removeItem('state')
          const ssoLogoutURL:string = `${URL_SSO ?? ''}/es/logout?redirect_url=${appURL ?? ''}/session/login`
          console.log('url cierre de sesión', ssoLogoutURL??'')
          history.push({
            pathname: ssoLogoutURL,
            search: ''
          })
          return clearUserSession()
        }),
        catchError((error) => {
          logger.error('Error on user logout.', error)
          sessionStorage.removeItem('state')
          history.push({
            pathname: '/session/login',
            search: ''
          })
          const ssoLogoutURL = `${URL_SSO ?? ''}/es/logout?redirect_url=${appURL ?? ''}/session/login`
          console.log('url cierre de sesión', ssoLogoutURL??'')
          history.push({
            pathname: ssoLogoutURL,
            search: ''
          })
          return of(clearUserSession())
        })
      )
    )
  )

export const createUserRequestEpic: Epic<Action, ClearUserSessionAction> = (
  action$,
  state$
) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, CreateUserRequestAction>(createUserRequest.type),
    // @ts-expect-error: Problemas de tipos
    mergeMap(({ payload }) =>
      userSessionAPI
        .createUser(
          payload.sharp,
          payload.email,
          payload.password,
          getLocale(state$.value)
        )
        .pipe(
          map(({ response }) => {
            logger.info(response)
            history.push({
              pathname: '/session/login',
              search: ''
            })
            const intl = intlHelper.getIntl()
            toast(
              intl?.formatMessage({
                id: TranslationKey.USER_REGISTERED_SUCCESS
              }),
              toastConfig
            )
            return clearUserSession()
          }),
          catchError((error) => {
            logger.error('There was an error registering the user.', error)
            const intl = intlHelper.getIntl()
            toast.error(
              intl?.formatMessage({
                id: TranslationKey.ERROR_REGISTERING_USER
              }),
              toastConfig
            )
            return of(clearUserSession())
          })
        )
    )
  )

export const verifyCodeRequestEpic: Epic<
Action,
SetCredentialsErrorAction | ClearUserSessionAction
> = (action$, state$) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, VerifyCodeRequestAction>(verifyCodeRequest.type),
    // @ts-expect-error: Problemas de tipos
    mergeMap(({ payload }) =>
      userSessionAPI
        .verifyCode(payload.date, payload.code, getLocale(state$.value))
        .pipe(
          map((answer) => {
            logger.info('Code verified.', answer)
            history.push({
              pathname: '/session/login',
              search: ''
            })
            const intl = intlHelper.getIntl()
            toast(
              intl?.formatMessage({
                id: TranslationKey.CODE_VERIFICATION_SUCCESS
              }),
              toastConfig
            )
            return clearUserSession()
          }),
          catchError((error: AjaxError) => {
            if (error?.status !== StatusCodes.UNAUTHORIZED) {
              throw error
            }
            logger.error('Error verifying code.', error)
            return of(
              setCredentialsError({
                credentialsError: true
              })
            )
          }),
          catchError((error) => {
            logger.error('Error verifying code.', error)
            return of(clearUserSession())
          })
        )
    )
  )

export const forgotPasswordRequestEpic: Epic<Action, ClearUserSessionAction> = (
  action$,
  state$
) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, ForgotPasswordRequestAction>(forgotPasswordRequest.type),
    // @ts-expect-error: Problemas de tipos
    mergeMap(({ payload }) =>
      userSessionAPI
        .forgotPassword(payload.email, getLocale(state$.value))
        .pipe(
          map((response) => {
            logger.info('Sent forgot password request.', response)
            history.push({
              pathname: '/session/login',
              search: ''
            })
            const intl = intlHelper.getIntl()
            toast(
              intl?.formatMessage({
                id: TranslationKey.FORGOT_PWD_NOTIFICATION
              }),
              toastConfig
            )
            return clearUserSession()
          }),
          catchError((error) => {
            logger.error('Error login user.', error)
            history.push({
              pathname: '/session/forgot-password',
              search: ''
            })
            toast.error('Error sending forgot password.', toastConfig)
            return of(clearUserSession())
          })
        )
    )
  )

export const passwordRecoveryRequestEpic: Epic<
Action,
SetCredentialsErrorAction | ClearUserSessionAction
> = (action$, state$) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, PasswordRecoveryRequestAction>(passwordRecoveryRequest.type),
    // @ts-expect-error: Problemas de tipos
    mergeMap(({ payload }) =>
      userSessionAPI
        .recoverPassword(
          getLocale(state$.value),
          payload.newPassword,
          payload.currentPassword,
          payload.token
        )
        .pipe(
          map((response) => {
            const isInternalChange = payload.token !== null
            logger.info('Password change', response)
            const pathname = isInternalChange
              ? '/profile/edit'
              : '/session/login'
            history.push({
              pathname,
              search: ''
            })
            const intl = intlHelper.getIntl()
            toast(
              intl?.formatMessage({
                id: TranslationKey.PASSWORD_RECOVERY_MESSAGE
              }),
              toastConfig
            )
            return isInternalChange
              ? setCredentialsError({
                credentialsError: false
              })
              : clearUserSession()
          }),
          catchError((error: AjaxError) => {
            if (
              ![
                StatusCodes.UNAUTHORIZED,
                StatusCodes.UNPROCESSABLE_ENTITY,
                StatusCodes.NOT_ACCEPTABLE
              ].includes(error.status)
            ) {
              throw error
            }
            return of(
              setCredentialsError({
                credentialsError: true
              })
            )
          }),
          catchError((error) => {
            logger.error('Error recovering password', error)
            toast.error(
              'Error sending password recovery request.',
              toastConfig
            )
            return of(clearUserSession())
          })
        )
    )
  )

const updateAvatarRequestEpic: Epic<Action, UpdateAvatarAction> = (action$) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, UpdateAvatarRequestAction>(updateAvatarRequest.type),
    // @ts-expect-error: Problemas de tipos
    mergeMap(({ payload }) =>
      userSessionAPI.uploadAvatar(payload.avatarImage).pipe(
        map(({ response }) => response.avatar),
        map((avatar) => {
          logger.info('User avatar update.', avatar)
          const intl = intlHelper.getIntl()
          toast(
            intl?.formatMessage({
              id: TranslationKey.AVATAR_UPLOAD_SUCCESS
            }),
            toastConfig
          )
          return updateAvatar({
            avatar
          })
        }),
        catchError((error) => {
          logger.error('Error uploading user avatar.', error)
          const intl = intlHelper.getIntl()
          toast.error(
            intl?.formatMessage({
              id: TranslationKey.AVATAR_UPLOAD_ERROR
            }),
            toastConfig
          )
          return of(updateAvatar({}))
        })
      )
    )
  )

const updateProfileRequestEpic: Epic<
Action,
UpdateProfileAction | CancelUpdateProfileAction
> = (action$, state$) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, UpdateProfileRequestAction>(updateProfileRequest.type),
    // @ts-expect-error: Problemas de tipos
    mergeMap(({ payload }) =>
      userSessionAPI
        .updateProfile(payload.name, payload.email, getLocale(state$.value))
        .pipe(
          map(({ response }) => {
            logger.info('Updated user profile.', response)
            const intl = intlHelper.getIntl()
            toast(
              intl?.formatMessage({
                id: TranslationKey.EDIT_PROFILE_SUCCESS
              }),
              toastConfig
            )
            return updateProfile({
              name: payload.name
            })
          }),
          catchError((error) => {
            logger.error('Error updating user profile.', error)
            const intl = intlHelper.getIntl()
            toast.error(
              intl?.formatMessage({
                id: TranslationKey.EDIT_PROFILE_ERROR
              }),
              toastConfig
            )
            return of(cancelUpdateProfile())
          })
        )
    )
  )

export const emailChangeVerificationEpic: Epic<
Action,
UpdateUserEmailAction
> = (action$, state$) =>
  action$.pipe(
    ofType<Action, EmailChangeVerificationRequestAction>(
      // @ts-expect-error: Problemas de tipos
      emailChangeVerificationRequest.type
    ),
    // @ts-expect-error: Problemas de tipos
    mergeMap(({ payload }) =>
      userSessionAPI
        .emailChangeVerification(payload.token, getLocale(state$.value))
        .pipe(
          map(({ response }) => response.email),
          map((email) => {
            logger.info('Email change verified with new email.', email)
            const intl = intlHelper.getIntl()
            toast(
              intl?.formatMessage({
                id: TranslationKey.EMAIL_CHANGE_SUCCESS
              }),
              toastConfig
            )
            history.push({
              pathname: '/',
              search: ''
            })
            return updateUserEmail({
              email
            })
          }),
          // Handle token void/invalid error
          catchError((error: AjaxError) => {
            if (![StatusCodes.UNAUTHORIZED].includes(error.status)) {
              throw error
            }
            logger.error('Email change unauthorized.', error)
            const intl = intlHelper.getIntl()
            toast.error(
              intl?.formatMessage({
                id: TranslationKey.EMAIL_CHANGE_UNAUTHORIZED
              }),
              toastConfig
            )
            history.push({
              pathname: '/',
              search: ''
            })
            return of(
              updateUserEmail({
                email: null
              })
            )
          }),
          // Handle other errors
          catchError((error: AjaxError) => {
            logger.error('Error verifying email change.', error)
            const intl = intlHelper.getIntl()
            toast.error(
              intl?.formatMessage({
                id: TranslationKey.EMAIL_CHANGE_ERROR
              }),
              toastConfig
            )
            history.push({
              pathname: '/',
              search: ''
            })
            return of(
              updateUserEmail({
                email: null
              })
            )
          })
        )
    )
  )

export const userSessionEpic = combineEpics(
  // @ts-expect-error: Problemas de tipos
  ...[
    fetchUserSessionEpic,
    loginUserRequestEpic,
    logoutUserRequestEpic,
    createUserRequestEpic,
    verifyCodeRequestEpic,
    forgotPasswordRequestEpic,
    passwordRecoveryRequestEpic,
    updateAvatarRequestEpic,
    updateProfileRequestEpic,
    emailChangeVerificationEpic
  ]
)

export default userSessionSlice.reducer
