import {
  type ChangeEvent,
  type FunctionComponent,
  type MouseEvent,
  type RefObject,
  useCallback,
  useMemo,
  useState
} from 'react'
import { Button, Grid, Typography } from '@material-ui/core'
import { FormattedMessage, useIntl } from 'react-intl'
import { toast } from 'react-toastify'
import { userSessionAPI } from '../../../api'
import errorReportImg from '../../../assets/images/error-report.svg'
import GeneralModal from '../../../components/GeneralModal'
import LoadingSpinner from '../../../components/LoadingSpinner'
import toastConfig from '../../../config/toast'
import { TranslationKey } from '../../../i18n/translations'
import { logger } from '../../../services'
import styles from './ReportErrorModal.module.scss'

interface ReportErrorModalProps {
  buttonRef: RefObject<HTMLElement>
}

enum ProfileField {
  Country = 'country',
  Company = 'company',
  VicePresidency = 'vp',
  Position = 'position',
  Band = 'band',
  SharpID = 'sharp',
  HiringDate = 'hiringDate',
}

const translationKeyMap: Record<ProfileField, string> = {
  [ProfileField.Country]: TranslationKey.COUNTRY,
  [ProfileField.Company]: TranslationKey.COMPANY,
  [ProfileField.VicePresidency]: TranslationKey.VICE_PRESIDENCY,
  [ProfileField.Position]: TranslationKey.POSITION,
  [ProfileField.Band]: TranslationKey.BAND,
  [ProfileField.SharpID]: TranslationKey.SHARP_ID,
  [ProfileField.HiringDate]: TranslationKey.HIRING_DATE
}

const initSelectedFields = Object.values(ProfileField).reduce(
  (obj, field) => ({
    [field]: false,
    ...obj
  }),
  {}
) as Record<ProfileField, boolean>

const ReportErrorModal: FunctionComponent<ReportErrorModalProps> = ({
  buttonRef
}) => {
  const intl = useIntl()

  const [description, setDescription] = useState('')
  const [selectedFields, setSelectedFields] = useState(initSelectedFields)
  const [errorSent, setErrorSent] = useState(false)
  const [sendingError, setSendingError] = useState(false)
  const canSend = useMemo(
    () => description !== null && Object.values(selectedFields).some((val) => val),
    [description, selectedFields]
  )

  const handleDescriptionChange = useCallback(
    (event: ChangeEvent<HTMLTextAreaElement>) => {
      setDescription(event.target.value)
    },
    []
  )

  const handleFieldClick = useCallback(
    (event: MouseEvent<HTMLButtonElement>) => {
      const { value } = event.currentTarget.dataset
      // Waits for ripple effect
      setTimeout(() => {
        setSelectedFields((fields) => ({
          ...fields,
          [value as ProfileField]: !fields[value as ProfileField]
        }))
      }, 200)
    },
    []
  )

  const handleModalClose = useCallback(() => {
    setTimeout(() => {
      setErrorSent(false)
    }, 500)
  }, [])

  const handleSubmitClick = useCallback(() => {
    if (!canSend) {
      return undefined
    }
    const fields = Object.values(ProfileField)
      .filter((field) => selectedFields[field])
      .join(', ')
    setSendingError(true)
    const subscription = userSessionAPI
      .reportError(fields, description)
      .subscribe(
        () => {
          setErrorSent(true)
          setSendingError(false)
        },
        (error) => {
          logger.error('Error sending error report.', error)
          toast.error(
            intl.formatMessage({
              id: TranslationKey.REPORT_ERROR_ERROR
            }),
            toastConfig
          )
          setSendingError(false)
        }
      )

    return () => {
      subscription.unsubscribe()
    }
  }, [canSend, description, intl, selectedFields])

  const form = (
    <>
      <Typography variant="h6" color="primary" className={styles.title}>
        <FormattedMessage id={TranslationKey.REPORT_ERROR_TITLE} />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage id={TranslationKey.REPORT_ERROR_TEXT} />
      </Typography>
      <div className={styles.fieldContainer}>
        {Object.values(ProfileField).map((value) => (
          <Button
            key={value}
            data-value={value}
            variant="outlined"
            className={`${styles.fieldButton} ${
              selectedFields[value] ? styles.selectedFieldButton : ''
            }`}
            color={selectedFields[value] ? 'primary' : undefined}
            onClick={handleFieldClick}
          >
            <FormattedMessage id={translationKeyMap[value]} />
          </Button>
        ))}
      </div>
      <div className={styles.textAreaContainer}>
        <Typography variant="h6">
          <FormattedMessage id={TranslationKey.REPORT_ERROR_INSTRUCTION} />
        </Typography>
        <textarea
          className={styles.textArea}
          value={description}
          onChange={handleDescriptionChange}
          placeholder={intl.formatMessage({
            id: TranslationKey.REPORT_ERROR_PLACEHOLDER
          })}
          cols={50}
          rows={10}
        />
      </div>
      <div className={styles.buttonsContainer}>
        <Button
          variant="contained"
          color="primary"
          onClick={handleSubmitClick}
          disabled={!canSend}
        >
          <FormattedMessage id={TranslationKey.SEND} />
        </Button>
      </div>
    </>
  )

  const sending = (
    <div className={styles.spinnerContainer}>
      <LoadingSpinner />
    </div>
  )

  const sent = (
    <div className={styles.sentContainer}>
      <img src={errorReportImg} alt="User with app" />
      <Typography variant="h6" color="primary">
        <FormattedMessage id={TranslationKey.ERROR_REPORT_SENT_TITLE} />
      </Typography>
      <Typography variant="body2">
        <FormattedMessage id={TranslationKey.ERROR_REPORT_SENT_BODY} />
      </Typography>
    </div>
  )

  let content = errorSent ? sent : form
  if (sendingError) content = sending

  return (
    <GeneralModal
      isOpen
      buttonRef={buttonRef}
      onRequestClose={handleModalClose}
      maxWidth={500}
      minHeight={400}
      shouldCloseOnEsc={false}
      shouldCloseOnOverlayClick={false}
    >
      <Grid container justifyContent="center" direction="row">
        <Grid item xs={12} lg={12} md={12} sm={12}>
          {content}
        </Grid>
      </Grid>
      {/* {content} */}
    </GeneralModal>
  )
}

export default ReportErrorModal
