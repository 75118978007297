import { type FunctionComponent, type RefObject } from 'react'
import { Typography } from '@material-ui/core'
import { FormattedMessage } from 'react-intl'
import imgFormula from '../../../assets/images/bonus-formula.png'
import GeneralModal from '../../../components/GeneralModal'
import { TranslationKey } from '../../../i18n/translations'

interface BonusHelpModalProps {
  buttonRef?: RefObject<HTMLElement>
}

const BonusHelpModal: FunctionComponent<BonusHelpModalProps> = ({
  buttonRef
}) => (
  <GeneralModal isOpen buttonRef={buttonRef} maxWidth={500} minHeight={150}>
    <Typography variant="h5" color="primary">
      <FormattedMessage id={TranslationKey.STI_FORMULA} />
    </Typography>

    <Typography
      variant="body2"
      style={{
        marginBottom: 10
      }}
    >
      <FormattedMessage id={TranslationKey.STI_FORMULA_HELP} />
    </Typography>

    <Typography
      variant="body2"
      style={{
        marginBottom: 10
      }}
    >
      <FormattedMessage id={TranslationKey.STI_FORMULA_P} />
    </Typography>
    <img src={imgFormula} alt="bonus formula" width="100%" height="auto" />
  </GeneralModal>
)

export default BonusHelpModal
