import {
  Checkbox,
  type CheckboxProps,
  FormControl,
  FormControlLabel,
  FormHelperText
} from '@material-ui/core'
import { useField } from 'formik'
import React, {
  type ChangeEvent,
  type FunctionComponent,
  type KeyboardEvent,
  type ReactElement,
  type ReactNode,
  useCallback
} from 'react'

interface FormCheckboxFieldProps extends Partial<CheckboxProps> {
  name: string
  label?: string | ReactNode
  onValueChange?: (checked: boolean, name: string) => void
}

const FormCheckboxField: FunctionComponent<FormCheckboxFieldProps> = ({
  name,
  label,
  onValueChange
}): ReactElement => {
  const [field, meta] = useField<boolean>(name)

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      field.onChange(event)
      if (onValueChange != null) onValueChange(!!event.target.checked, name)
    },
    [field, name, onValueChange]
  )

  const handleKeyUp = useCallback(
    (event: KeyboardEvent<HTMLButtonElement>) => {
      if (event.key !== ' ') {
        return
      }
      const newChecked = !field.value
      field.onChange({
        ...event,
        target: {
          name,
          value: newChecked,
          checked: newChecked
        }
      })
      if (onValueChange != null) onValueChange(newChecked, name)
    },
    [field, name, onValueChange]
  )

  const hasError = meta.touched && typeof meta.error !== 'undefined' && meta.error !== null

  return (
    <FormControl fullWidth>
      <FormControlLabel
        label={label}
        labelPlacement="end"
        control={
          <Checkbox
            color="primary"
            {...field}
            checked={!!field.value}
            onChange={handleChange}
            onKeyUp={handleKeyUp}
          />
        }
      />

      {hasError && (
        <FormHelperText error={hasError}>
          {hasError ? meta.error : ' '}
        </FormHelperText>
      )}
    </FormControl>
  )
}

export default FormCheckboxField
