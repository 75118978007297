import { type IntlShape } from 'react-intl'
import * as yup from 'yup'
import { PasswordChangeFields, ProfileEditField } from '../../../enums'
import { testCredentialsError } from '../../../helpers/util-functions'
import { TranslationKey } from '../../../i18n/translations'
import { type PasswordChangeValues, type ProfileEditValues } from '../../../types'

export const getProfileSchema = (
  intl: IntlShape
): yup.Schema<ProfileEditValues> =>
  yup.object().shape({
    [ProfileEditField.Name]: yup.string().trim(),
    [ProfileEditField.Email]: yup
      .string()
      .trim()
      .email(
        intl.formatMessage({
          id: TranslationKey.VALIDATE_EMAIL
        })
      )
  })

export const getNewPasswordSchema = (
  intl: IntlShape,
  isAdmin = false
): yup.Schema<PasswordChangeValues> =>
  yup.object().shape({
    credentialsError: yup.boolean(),

    [PasswordChangeFields.CurrentPassword]: yup
      .string()
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.OLD_PWD
            })
          }
        )
      )
      .test(
        'no-credentials-error',
        intl.formatMessage({
          id: TranslationKey.VALIDATE_CURRENT_PWD
        }),
        testCredentialsError
      ),

    [PasswordChangeFields.NewPassword]: yup
      .string()
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.NEW_PWD
            })
          }
        )
      )
      .matches(
        isAdmin
          ? /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.@$!%*#?&-])[A-Za-z\d.@$!%*#?&-]{15,}$/
          : /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.@$!%*#?&-])[A-Za-z\d.@$!%*#?&-]{8,}$/,
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_PWD_INSTRUCTIONS
          },
          {
            length: isAdmin ? 15 : 8
          }
        )
      )
      .test(
        'no-repeated-password',
        intl.formatMessage({
          id: TranslationKey.VALIDATE_PWD_NOT_NEW
        }),
        function noRepeatedPassword (
          this: {
            parent: { oldPassword: string }
          },
          value
        ): boolean {
          return this.parent.oldPassword !== value
        }
      ),
    // @ts-expect-error: Problemas de tipos
    [PasswordChangeFields.NewPasswordConfirmation]: yup
      .string()
      .when(PasswordChangeFields.NewPassword, {
        is: (password: string | null): boolean =>
          password !== null && password !== '',
        then: yup.string().oneOf(
          [yup.ref(PasswordChangeFields.NewPassword)],
          intl.formatMessage({
            id: TranslationKey.VALIDATE_PWD_MATCH
          })
        )
      })
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.REPEAT_NEW_PWD
            })
          }
        )
      )
  })
