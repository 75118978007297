/* eslint-disable no-param-reassign */
import {
  type Action,
  createSelector,
  createSlice,
  type PayloadAction
} from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { combineEpics, type Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import {
  catchError,
  debounceTime,
  filter,
  map,
  mergeMap,
  tap
} from 'rxjs/operators'
import { adminAPI } from '../../api'
import toastConfig from '../../config/toast'
import {
  AdminUserTableField,
  type CountryCode,
  SortDirection,
  UserRole
} from '../../enums'
import { serializeQuery } from '../../helpers/util-functions'
import intlHelper from '../../i18n/intlHelper'
import { TranslationKey } from '../../i18n/translations'
import { logger } from '../../services'
import { type AdminFilters, type AdminUser } from '../../types'
import { clearUserSession } from '../userSession/slice'
import { adminUserMapper } from './mappers'

/**
 * Action Payloads
 */
interface SetPagePayload {
  page: number
}

interface SetPageSizePayload {
  pageSize: number
}

interface SetSortColumnPayload {
  sortColumn: AdminUserTableField
}

interface SetSortDirectionPayload {
  sortDirection: SortDirection
}

interface SetFiltersPayload {
  country: CountryCode | null
  company: string | null
  role: UserRole | null
}

interface SetUsersPayload {
  users: AdminUser[]
  count: number
  sortDisabled?: boolean
}

interface ToggleUserSelectedPayload {
  sharp: number
}

interface SetAllUsersSelectedPayload {
  selected: boolean
}

interface UpdateUsersRequestPayload {
  role: UserRole
}

interface UpdateUsersRolePayload {
  role: UserRole
  userIds: number[]
}

/**
 * State
 */
interface AdminState {
  fetching: boolean
  submitting: boolean
  page: number
  pageSize: number
  sortColumn: AdminUserTableField
  sortDirection: SortDirection
  filters: {
    country: CountryCode | null
    company: string | null
    role: UserRole | null
  }
  sortDisabled: boolean
  users: AdminUser[]
  count: number
}

const initialState: AdminState = {
  fetching: false,
  submitting: false,
  page: 0,
  pageSize: 10,
  sortColumn: AdminUserTableField.Sharp,
  sortDirection: SortDirection.Descending,
  filters: {
    country: null,
    company: null,
    role: null
  },
  sortDisabled: false,
  users: [],
  count: 0
}

/**
 * Slice
 */
const adminSlice = createSlice({
  name: 'admin',
  initialState,
  extraReducers: {
    [clearUserSession.toString()]: () => initialState
  },
  reducers: {
    setPage (state, { payload }: PayloadAction<SetPagePayload>) {
      state.fetching = true
      state.page = payload.page
    },
    setPageSize (state, { payload }: PayloadAction<SetPageSizePayload>) {
      state.fetching = true
      state.pageSize = payload.pageSize
      state.page = 0
    },
    setSortColumn (state, { payload }: PayloadAction<SetSortColumnPayload>) {
      state.fetching = true
      state.sortColumn = payload.sortColumn
    },
    setSortDirection (
      state,
      { payload }: PayloadAction<SetSortDirectionPayload>
    ) {
      state.fetching = true
      state.sortDirection = payload.sortDirection
    },
    setFilters (state, { payload }: PayloadAction<SetFiltersPayload>) {
      state.fetching = true
      state.filters = payload
      state.page = 0
    },
    setUsers (state, { payload }: PayloadAction<SetUsersPayload>) {
      state.fetching = false
      state.submitting = false
      state.users = payload.users
      state.count = payload.count
      state.sortDisabled = (payload.sortDisabled !== null)
    },
    toggleUserSelected (
      state,
      { payload }: PayloadAction<ToggleUserSelectedPayload>
    ) {
      state.users.forEach((user) => {
        if (user.sharp !== payload.sharp) return
        user.selected = !user.selected
      })
    },
    setAllUsersSelected (
      state,
      { payload }: PayloadAction<SetAllUsersSelectedPayload>
    ) {
      state.users.forEach((user) => {
        user.selected = payload.selected
      })
    },
    deleteUsersRequest (state) {
      state.submitting = true
    },
    updateUsersRequest (
      state,
      // eslint-disable-next-line @typescript-eslint/no-unused-vars
      _action: PayloadAction<UpdateUsersRequestPayload>
    ) {
      state.submitting = true
    },
    updateUsersRole (state, { payload }: PayloadAction<UpdateUsersRolePayload>) {
      state.submitting = false
      state.users.forEach((user) => {
        if (payload.userIds.includes(user.sharp)) {
          user.role = payload.role
        }
      })
    }
  }
})

/**
 * Actions
 */
export const {
  setPage,
  setPageSize,
  setSortColumn,
  setSortDirection,
  setFilters,
  setUsers,
  toggleUserSelected,
  setAllUsersSelected,
  updateUsersRequest,
  updateUsersRole,
  deleteUsersRequest
} = adminSlice.actions

/**
 * Selectors
 */
const getModuleState = (state: { admin: AdminState }): any => state.admin

export const getFetching = createSelector(
  getModuleState,
  (state) => state.fetching
)

export const getSubmitting = createSelector(
  getModuleState,
  (state) => state.submitting
)

export const getPage = createSelector(getModuleState, (state) => state.page)

export const getPageSize = createSelector(
  getModuleState,
  (state) => state.pageSize
)

export const getSortColumn = createSelector(
  getModuleState,
  (state) => state.sortColumn
)

export const getSortDirection = createSelector(
  getModuleState,
  (state) => state.sortDirection
)

export const getFilters = createSelector(
  getModuleState,
  (state) => state.filters
)

export const getSortDisabled = createSelector(
  getModuleState,
  (state) => state.sortDisabled
)

export const getUsers = createSelector(getModuleState, (state) => state.users)

export const getUsersCount = createSelector(
  getModuleState,
  (state) => state.count
)

export const getSelectedUsers = createSelector(getUsers, (users) =>
  users.filter(({ selected }: any) => selected)
)

export const getHasSelectedUsers = createSelector(getUsers, (users) =>
  users.some(({ selected }: any) => selected)
)

/**
 * Epics
 */
type SetPageAction = ReturnType<typeof setPage>
type SetPageSizeAction = ReturnType<typeof setPageSize>
type SetSortColumnAction = ReturnType<typeof setSortColumn>
type SetSortDirectionAction = ReturnType<typeof setSortDirection>
type SetFiltersAction = ReturnType<typeof setFilters>
type SetUsersAction = ReturnType<typeof setUsers>
type UpdateUsersRequestAction = ReturnType<typeof updateUsersRequest>
type UpdateUsersRoleAction = ReturnType<typeof updateUsersRole>
type DeleteUsersRequestAction = ReturnType<typeof deleteUsersRequest>

const fetchUserSessionEpic: Epic<Action, SetUsersAction> = (action$, state$) =>
  action$.pipe(
    ofType<
    Action,
    | SetPageAction
    | SetPageSizeAction
    | SetSortColumnAction
    | SetSortDirectionAction
    | SetFiltersAction
    >(
      // @ts-expect-error: PRoblemas de tipos
      setPage.type,
      setPageSize.type,
      setSortColumn.type,
      setSortDirection.type,
      setFilters.type
    ),
    map(
      (): AdminFilters => ({
        page: getPage(state$.value),
        pageSize: getPageSize(state$.value),
        sortColumn: getSortColumn(state$.value),
        sortDirection: getSortDirection(state$.value),
        countryFilter: getFilters(state$.value).country,
        companyFilter: getFilters(state$.value).company,
        roleFilter: getFilters(state$.value).role
      })
    ),
    debounceTime(300),
    filter(
      ({ countryFilter, companyFilter, roleFilter }) =>
        (countryFilter !== null) || (companyFilter !== null) || (roleFilter !== null)
    ),
    tap((filters) => {
      logger.info(
        'Fetching users for admin with:',
        `?${serializeQuery(filters)}`
      )
    }),
    mergeMap((filters: AdminFilters) =>
      adminAPI.fetchUsers(filters).pipe(
        map(({ response }) => ({
          users: adminUserMapper(response.users),
          count: response.count
        })),
        map((mappedResponse) => {
          logger.info('Fetched users for admin.', mappedResponse)
          return setUsers(mappedResponse)
        }),
        catchError((error) => {
          logger.error('Error fetching users for admin.', error)
          const intl = intlHelper.getIntl()
          toast.error(
            intl?.formatMessage({
              id: TranslationKey.ERROR_FETCHING_USERS
            }),
            toastConfig
          )
          return of(
            setUsers({
              users: [],
              count: 0
            })
          )
        })
      )
    )
  )

const updateUsersEpic: Epic<Action, UpdateUsersRoleAction | SetUsersAction> = (
  action$,
  state$
) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, UpdateUsersRequestAction>(updateUsersRequest.type),
    map(({ payload }: any) =>
      getSelectedUsers(state$.value).map(({ sharp }: any) => ({
        id: sharp,
        role: payload.role
      }))
    ),
    mergeMap((usersToUpdate) =>
      adminAPI.updateUsers(usersToUpdate).pipe(
        map(({ response }) => {
          logger.info('Updated users role', response)
          const intl = intlHelper.getIntl()
          toast(
            intl?.formatMessage({
              id: TranslationKey.USERS_UPDATE_SUCCESS
            }),
            toastConfig
          )
          return updateUsersRole({
            userIds: usersToUpdate.map(({ id }: any) => id),
            role: usersToUpdate[0]?.role
          })
        }),
        catchError((error) => {
          logger.error('Error updating users', error)
          const intl = intlHelper.getIntl()
          toast.error(
            intl?.formatMessage({
              id: TranslationKey.USERS_UPDATE_ERROR
            }),
            toastConfig
          )
          return of(
            setUsers({
              users: getUsers(state$.value),
              count: getUsersCount(state$.value)
            })
          )
        })
      )
    )
  )

const deleteUsersEpic: Epic<Action, UpdateUsersRoleAction | SetUsersAction> = (
  action$,
  state$
) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, DeleteUsersRequestAction>(deleteUsersRequest.type),
    map(() => getSelectedUsers(state$.value).map(({ sharp }: any) => sharp)),
    mergeMap((userIds: number[]) =>
      adminAPI.deleteUsers(userIds).pipe(
        map(({ response }) => {
          logger.info('Delete users.', userIds, response)
          const intl = intlHelper.getIntl()
          toast(
            intl?.formatMessage({
              id: TranslationKey.DELETE_USERS_SUCCESS
            }),
            toastConfig
          )
          return updateUsersRole({
            userIds,
            role: UserRole.Invalid
          })
        }),
        catchError((error) => {
          logger.error('Error deleting users.', error)
          const intl = intlHelper.getIntl()
          toast.error(
            intl?.formatMessage({
              id: TranslationKey.DELETE_USERS_ERROR
            }),
            toastConfig
          )
          return of(
            setUsers({
              users: getUsers(state$.value),
              count: getUsersCount(state$.value)
            })
          )
        })
      )
    )
  )

export const adminEpic = combineEpics(
  ...[fetchUserSessionEpic, updateUsersEpic, deleteUsersEpic]
)

export default adminSlice.reducer
