import { makeStyles } from '@material-ui/core'
import CircularProgress from '@material-ui/core/CircularProgress'
import TextField from '@material-ui/core/TextField'
import Autocomplete from '@material-ui/lab/Autocomplete'
import React, {
  type ChangeEvent,
  type FunctionComponent,
  type ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { FormattedMessage } from 'react-intl'
import { ajax } from 'rxjs/ajax'
import { map } from 'rxjs/operators'
import { AppColor, HttpMethod } from '../../enums'
import { useDebounce } from '../../helpers/hooks'
import { serializeQuery } from '../../helpers/util-functions'
import { TranslationKey } from '../../i18n/translations'
import { logger } from '../../services'
import { type IOption } from '../../types'

const useStyles = makeStyles(() => ({
  popupIndicator: {
    color: AppColor.Primary
  }
}))

interface AutocompleteFieldProps {
  label: string
  placeholder?: string
  apiURL: string
  value?: IOption
  onChange: (option: IOption | IOption[] | null, name?: string) => void
  disabled?: boolean
  required?: boolean
  loading?: boolean
  multiple?: boolean
  size?: 'small' | 'medium'
  queryProperty?: string
  additionalFilters?: Record<
  string,
  string | number | string[] | number[] | boolean | null | undefined
  >
  mockOptions?: IOption[]
}

const AutocompleteField: FunctionComponent<AutocompleteFieldProps> = ({
  label,
  placeholder,
  apiURL,
  value,
  onChange,
  disabled = false,
  required = false,
  loading = false,
  multiple = false,
  size = 'medium',
  queryProperty = 'name',
  additionalFilters,
  mockOptions
}): ReactElement => {
  const [open, setOpen] = useState(false)
  const [options, setOptions] = useState<IOption[]>([])
  const [fetchLoading, setFetchLoading] = useState(false)
  const [inputValue, setInputValue] = useState('')
  const mountedRef = useRef(false)

  const debouncedValue = useDebounce(inputValue, 300)
  useEffect(() => {
    if (!mountedRef.current || debouncedValue !== '') {
      mountedRef.current = true
      return undefined
    }

    setFetchLoading(true)

    const urlWithQuery = `${apiURL}?${queryProperty}=${encodeURIComponent(
      debouncedValue
    )}${(additionalFilters != null) ? serializeQuery(additionalFilters) : ''}`
    logger.info(`Autocompleting ${urlWithQuery}`)

    const subscription$ = ajax({
      url: urlWithQuery,
      method: HttpMethod.Get,
      headers: {
        Accept: 'application/json; charset=UTF-8'
      },
      withCredentials: true
    })
      .pipe(map(({ response }: { response: any }) => response))
      .subscribe(
        (newOptions) => {
          setOptions(newOptions)
          setFetchLoading(false)
        },
        (err) => {
          if (mockOptions != null) {
            setOptions(mockOptions)
          } else {
            logger.error(`Error autocompleting ${urlWithQuery}`, err)
          }
          setFetchLoading(false)
        }
      )

    return () => {
      subscription$.unsubscribe()
    }
  }, [additionalFilters, apiURL, debouncedValue, mockOptions, queryProperty])

  const handleChange = useCallback(
    (_event: any, newValue: IOption | IOption[] | null) => {
      onChange(newValue)
    },
    [onChange]
  )

  const handleInputChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      setInputValue(event.target.value)
    },
    []
  )

  const classes = useStyles()

  return (
    <Autocomplete
      multiple={multiple}
      classes={classes}
      open={open}
      onOpen={() => {
        setOpen(true)
      }}
      onClose={() => {
        setOpen(false)
      }}
      getOptionSelected={(option: IOption, optionValue: IOption) =>
        option.name === optionValue.name
      }
      getOptionLabel={(option: IOption) => option.name}
      options={options}
      loading={fetchLoading}
      disabled={disabled}
      onChange={handleChange}
      value={value}
      loadingText={<FormattedMessage id={TranslationKey.LOADING} />}
      noOptionsText={
        inputValue !== ''
          ? (
          <FormattedMessage id={TranslationKey.AUTOCOMPLETE_NO_OPTIONS} />
            )
          : (
          <FormattedMessage id={TranslationKey.AUTOCOMPLETE_INSTRUCTIONS} />
            )
      }
      renderInput={(params) => (
        <TextField
          {...params}
          label={label}
          placeholder={typeof placeholder !== 'undefined' && placeholder !== null ? placeholder : label}
          variant="outlined"
          size={size}
          value={inputValue}
          onChange={handleInputChange}
          fullWidth
          required={required}
          InputLabelProps={{
            shrink: false
          }}
          InputProps={{
            ...params.InputProps,
            endAdornment: (
              <>
                {fetchLoading && loading
                  ? (
                  <CircularProgress color="inherit" size={20} />
                    )
                  : null}
                {params.InputProps.endAdornment}
              </>
            )
          }}
        />
      )}
    />
  )
}

export default AutocompleteField
