import {
  Breadcrumbs,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  Typography
} from '@material-ui/core'
import {
  Help as HelpIcon,
  NavigateNext as NavigateNextIcon
} from '@material-ui/icons'
import React, {
  type MouseEvent,
  type ReactElement,
  useCallback,
  useEffect,
  useRef,
  useState
} from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { Link } from 'react-router-dom'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { BenefitType } from '../../../enums'
import { TranslationKey } from '../../../i18n/translations'
import BenefitCard from '../BenefitCard'
import BenefitsHelpModal from '../Benefits/BenefitsHelpModal'
import { useCategoryByID, useFetchCategories } from '../hooks'
import {
  fetchBenefits,
  getBenefits,
  getFetchingBenefits,
  setBenefits
} from '../slice'
import styles from './CategoryView.module.scss'

function CategoryView (): ReactElement {
  const buttonRef = useRef(null)
  useFetchCategories()

  const { categoryID } = useParams()
  const category = useCategoryByID(Number(categoryID))
  const fetchingBenefits = useSelector(getFetchingBenefits)
  const benefits = useSelector(getBenefits)

  const dispatch = useDispatch()
  useEffect(() => {
    dispatch(
      fetchBenefits({
        categoryID: Number(categoryID)
      })
    )
  }, [categoryID, dispatch])

  const navigate = useNavigate()
  const handleBenefitsClick = useCallback(
    (event: MouseEvent) => {
      event.preventDefault()
      dispatch(
        setBenefits({
          benefits: undefined
        })
      )
      navigate('/benefits')
    },
    [dispatch, navigate]
  )

  const [showingBenefits, setShowingBenefits] = useState(true)
  const [showingAgreements, setShowingAgreements] = useState(true)
  const toggleShowingBenefits = useCallback(() => {
    setShowingBenefits((val) => !val)
  }, [])
  const toggleShowingAgreements = useCallback(() => {
    setShowingAgreements((val) => !val)
  }, [])

  return (
    <Grid
      container
      className={styles.container}
      alignItems="center"
      direction="column"
    >
      <BenefitsHelpModal buttonRef={buttonRef} />
      <Grid item xs={12} className={styles.titleContainer}>
        <Typography variant="h5">
          <FormattedMessage id={TranslationKey.BENEFITS} />
        </Typography>

        <Breadcrumbs separator={<NavigateNextIcon fontSize="small" />}>
          <Link to="/" className={styles.breadcrumb}>
            Home
          </Link>
          <Link
            to="/benefits"
            className={styles.breadcrumb}
            onClick={handleBenefitsClick}
          >
            <FormattedMessage id={TranslationKey.BENEFITS} />
          </Link>
          <Typography color="textPrimary" className={styles.categoryLink}>
            {(category != null) ? category.title : '...'}
          </Typography>
        </Breadcrumbs>
      </Grid>
      <Grid item xs={10}>
        {(category != null) && (
          <Grid container justifyContent="space-between">
            <Grid item xs={12} className={styles.categoryHeader}>
              <img src={category.logoURL} alt={`${category.title} logo`} />
              <Typography variant="h6">{category.title}</Typography>
            </Grid>

            <Grid item xs={12} className={styles.categoryHeader}>
              <IconButton
                ref={buttonRef}
                size="small"
                className={styles.helpButton}
              >
                <HelpIcon fontSize="small" />
              </IconButton>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showingBenefits}
                    color="primary"
                    onClick={toggleShowingBenefits}
                  />
                }
                label={<FormattedMessage id={TranslationKey.BENEFIT} />}
              />
              <FormControlLabel
                control={
                  <Checkbox
                    checked={showingAgreements}
                    onClick={toggleShowingAgreements}
                    color="secondary"
                  />
                }
                label={<FormattedMessage id={TranslationKey.AGREEMENT} />}
              />
            </Grid>
          </Grid>
        )}
      </Grid>
      {fetchingBenefits
        ? (
        <Grid item xs={10} className={styles.spinnerContainer}>
          <LoadingSpinner />
        </Grid>
          )
        : (
        <Grid item xs={12} sm={10} className={styles.benefitsContainer}>
          {benefits
            ?.filter(
              ({ type }) =>
                (showingBenefits && type === BenefitType.Benefit) ||
                (showingAgreements && type === BenefitType.Agreement)
            )
            .map((benefit) => (
              <BenefitCard key={benefit.id} {...benefit} />
            ))}
        </Grid>
          )}
    </Grid>
  )
}

export default CategoryView
