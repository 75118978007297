import { type Observable, type Subscriber } from 'rxjs'
import { ajax, type AjaxResponse } from 'rxjs/ajax'
import { apiURL } from '../config/environment'
import { HttpMethod } from '../enums'
import { serializeQuery } from '../helpers/util-functions'
import { type AdminFilters, type RawAdminUser } from '../types'

const contentTypeJSONHeader = {
  'Content-Type': 'application/json'
}
const acceptJSONHeader = {
  Accept: 'application/json; charset=UTF-8'
}

interface FetchUsersResponse extends AjaxResponse<any> {
  response: {
    users: RawAdminUser[]
    count: number
  }
}

export const fetchUsers = (
  filters: AdminFilters
): Observable<FetchUsersResponse> => {
  return ajax({
    url:
      apiURL !== null && typeof apiURL !== 'undefined'
        ? `${apiURL}/admin/users?${serializeQuery(filters)}`
        : '',
    method: HttpMethod.Get,
    headers: acceptJSONHeader,
    withCredentials: true
  })
}

interface UpdateUsersResponse {
  response: {
    users: RawAdminUser[]
  }
}
export const updateUsers = (
  users: Array<Partial<RawAdminUser>>
): Observable<UpdateUsersResponse> =>
  ajax({
    url:
      apiURL !== null && typeof apiURL !== 'undefined'
        ? `${apiURL}/admin/users`
        : '',
    method: HttpMethod.Put,
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader
    },
    body: users,
    withCredentials: true
  })

interface DeleteUsersResponse extends AjaxResponse<any> {
  response: number[]
}
export const deleteUsers = (
  userIds: number[]
): Observable<DeleteUsersResponse> =>
  ajax({
    url:
      apiURL !== null && typeof apiURL !== 'undefined'
        ? `${apiURL}/admin/users`
        : '',
    method: HttpMethod.Delete,
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader
    },
    body: userIds,
    withCredentials: true
  })

export const uploadCollaboratorsReport = (
  formData: FormData,
  progressSubscriber?: Subscriber<ProgressEvent>
): Observable<AjaxResponse<any>> =>
  ajax({
    url:
      apiURL !== null && typeof apiURL !== 'undefined'
        ? `${apiURL}/admin/report/collaborators`
        : '',
    method: HttpMethod.Post,
    body: formData,
    headers: acceptJSONHeader,
    withCredentials: true,
    progressSubscriber
  })

export const uploadExitsReport = (
  formData: FormData,
  progressSubscriber?: Subscriber<ProgressEvent>
): Observable<AjaxResponse<any>> =>
  ajax({
    url:
      apiURL !== null && typeof apiURL !== 'undefined'
        ? `${apiURL}/admin/report/withdrawals`
        : '',
    method: HttpMethod.Post,
    body: formData,
    headers: acceptJSONHeader,
    withCredentials: true,
    progressSubscriber
  })
