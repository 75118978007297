import { Button, IconButton } from '@material-ui/core'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons'
import { Form, Formik, type FormikProps } from 'formik'
import React, {
  type ReactElement,
  useCallback,
  useEffect,
  useMemo,
  useState
} from 'react'
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { toast } from 'react-toastify'
import { map } from 'rxjs/operators'
import * as yup from 'yup'
import { userSessionAPI } from '../../../api'
import { FormTextField } from '../../../components/FormFields'
import LoadingSpinner from '../../../components/LoadingSpinner'
import toastConfig from '../../../config/toast'
import { TranslationKey } from '../../../i18n/translations'
import { logger } from '../../../services'
import TranslateFormikErrors from '../../../wrappers/TranslateFormikErrors'
import { getSending, passwordRecoveryRequest } from '../slice'
import styles from './Session.module.scss'

interface PasswordRecoveryValues {
  password: string
  repeatPassword: string
}

const getValidationSchema = (intl: IntlShape, isAdmin = false): yup.ObjectSchema<any> =>
  yup.object({
    password: yup
      .string()
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.PASSWORD
            })
          }
        )
      )
      .matches(
        isAdmin
          ? /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.@$!%*#?&-])[A-Za-z\d.@$!%*#?&-]{15,}$/
          : /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.@$!%*#?&-])[A-Za-z\d.@$!%*#?&-]{8,}$/,
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_PWD_INSTRUCTIONS
          },
          {
            length: isAdmin ? 15 : 8
          }
        )
      ),
    // @ts-expect-error: Problemas de tipos
    repeatPassword: yup
      .string()
      .when('password', {
        is: (password: string | null): boolean =>
          password !== null && password.length > 0,
        then: yup.string().oneOf(
          [yup.ref('password')],
          intl.formatMessage({
            id: TranslationKey.VALIDATE_PWD_MATCH
          })
        )
      })
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.REPEAT_NEW_PWD
            })
          }
        )
      )
  })

function PasswordRecovery (): ReactElement {
  const intl = useIntl()
  const dispatch = useDispatch()
  const location = useLocation()
  const navigate = useNavigate()
  const [isAdmin, setIsAdmin] = useState(false)

  const token = new URLSearchParams(location.search).get('token') ?? ''
  useEffect(() => {
    const subscription = userSessionAPI
      .verifyPasswordRecoveryToken(token)
      .pipe(map(({ response }) => response.isAdmin))
      .subscribe(
        (verification) => {
          logger.info('User admin status', verification)
          setIsAdmin(verification)
        },
        (error) => {
          logger.error('Error verifying token', error)
          toast.error('Error sending token verification.', toastConfig)
          navigate('/session/login')
        }
      )
    return () => {
      subscription.unsubscribe()
    }
  }, [navigate, token])

  const [showingPassword, setShowingPassword] = useState(false)
  const toggleShowPassword = useCallback(() => {
    setShowingPassword(!showingPassword)
  }, [showingPassword])

  const sending = useSelector(getSending)

  const handleSubmit = useCallback(
    (values: PasswordRecoveryValues) => {
      dispatch(
        passwordRecoveryRequest({
          newPassword: values.password,
          token
        })
      )
    },
    [dispatch, token]
  )

  const validationSchema = useMemo(
    () => getValidationSchema(intl, isAdmin),
    [intl, isAdmin]
  )

  return (
    <Formik
        initialValues={{
          password: '',
          repeatPassword: ''
        }}
        initialErrors={{
          password: '',
          repeatPassword: ''
        }}
        validationSchema={validationSchema}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        {({ isValid }: FormikProps<PasswordRecoveryValues>) => (
          <Form autoComplete="off" noValidate>
            <TranslateFormikErrors />

            {/* Password */}
            <FormTextField
              name="password"
              label={intl.formatMessage({
                id: TranslationKey.NEW_PWD
              })}
              placeholder="*********"
              type={showingPassword ? 'text' : 'password'}
              endElement={
                <IconButton
                  onClick={toggleShowPassword}
                  className={styles.formInputButton}
                  tabIndex={-1}
                >
                  {showingPassword
                    ? (
                    <VisibilityIcon color="inherit" />
                      )
                    : (
                    <VisibilityOffIcon color="inherit" />
                      )}
                </IconButton>
              }
              disabled={sending}
              required
            />

            {/* Repeat Password */}
            <FormTextField
              name="repeatPassword"
              label={intl.formatMessage({
                id: TranslationKey.REPEAT_NEW_PWD
              })}
              placeholder="*********"
              type={showingPassword ? 'text' : 'password'}
              endElement={
                <IconButton
                  onClick={toggleShowPassword}
                  className={styles.formInputButton}
                  tabIndex={-1}
                >
                  {showingPassword
                    ? (
                    <VisibilityIcon color="inherit" />
                      )
                    : (
                    <VisibilityOffIcon color="inherit" />
                      )}
                </IconButton>
              }
              disabled={sending}
              required
            />

            <div className={styles.buttonContainer}>
              <Button
                type="submit"
                color="primary"
                variant="contained"
                disabled={!isValid || sending}
              >
                <FormattedMessage id={TranslationKey.RECOVER_PWD} />
              </Button>

              {sending && (
                <div className={styles.spinnerContainer}>
                  <LoadingSpinner />
                </div>
              )}
            </div>
          </Form>
        )}
      </Formik>
  )
}

export default PasswordRecovery
