/* eslint-disable no-param-reassign */

import {
  type Action,
  createSelector,
  createSlice,
  type PayloadAction
} from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { combineEpics, type Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { benefitsAPI } from '../../api'
import toastConfig from '../../config/toast'
import intlHelper from '../../i18n/intlHelper'
import { TranslationKey } from '../../i18n/translations'
import { logger } from '../../services'
import { type Benefit, type BenefitCategory } from '../../types'
import { clearUserSession, getLocale } from '../userSession/slice'
import { benefitCategoryMapper, benefitMapper } from './mappers'

/**
 * Action Payloads
 */
interface SetCategoriesPayload {
  categories: BenefitCategory[]
}

interface FetchBenefitsPayload {
  categoryID?: number
  textFilter?: string
}

interface SetBenefitsPayload {
  benefits?: Benefit[]
}

interface FetchBenefitDetailsPayload {
  benefitID: number
}

interface SetBenefitDetailsPayload {
  benefit?: Benefit
}

/**
 * State
 */
interface BenefitsState {
  fetchingCategories: boolean
  categories: BenefitCategory[]
  fetchingBenefits: boolean
  benefits?: Benefit[]
}

const initialState: BenefitsState = {
  fetchingCategories: true,
  categories: [],
  fetchingBenefits: false,
  benefits: undefined
}

/**
 * Slice
 */
const compensationSlice = createSlice({
  name: 'benefits',
  initialState,
  extraReducers: {
    [clearUserSession.toString()]: () => initialState
  },
  reducers: {
    fetchBenefitCategories (state) {
      state.fetchingCategories = true
    },
    setCategories (state, action: PayloadAction<SetCategoriesPayload>) {
      state.categories = action.payload.categories
      state.fetchingCategories = false
    },
    fetchBenefits (state, _action: PayloadAction<FetchBenefitsPayload>) {
      state.fetchingBenefits = true
    },
    setBenefits (state, action: PayloadAction<SetBenefitsPayload>) {
      state.benefits = action.payload.benefits
      state.fetchingBenefits = false
    },
    fetchBenefitDetails (
      _state,
      _action: PayloadAction<FetchBenefitDetailsPayload>
    ) {},
    setBenefitDetails (state, action: PayloadAction<SetBenefitDetailsPayload>) {
      if (action.payload.benefit == null) return
      let exists = false
      state.benefits?.forEach((benefit) => {
        if (benefit.id !== action.payload.benefit?.id) return
        exists = true
        benefit = action.payload.benefit
      })
      if (!exists) {
        state.benefits = state.benefits ?? []
        state.benefits.push(action.payload.benefit)
      }
    }
  }
})

/**
 * Actions
 */
export const {
  fetchBenefitCategories,
  setCategories,
  fetchBenefits,
  setBenefits,
  fetchBenefitDetails,
  setBenefitDetails
} = compensationSlice.actions

/**
 * Selectors
 */
const getModuleState = (state: { [compensationSlice.name]: BenefitsState }): BenefitsState => {
  return state[compensationSlice.name]
}

export const getFetchingCategories = createSelector(
  getModuleState,
  (state) => state.fetchingCategories
)

export const getCategories = createSelector(
  getModuleState,
  (state) => state.categories
)

export const getFetchingBenefits = createSelector(
  getModuleState,
  (state) => state.fetchingBenefits
)

export const getBenefits = createSelector(
  getModuleState,
  (state) => state.benefits
)

/**
 * Epics
 */
type FetchBenefitCategoriesAction = ReturnType<typeof fetchBenefitCategories>
type SetCategoriesAction = ReturnType<typeof setCategories>
type FetchBenefitsAction = ReturnType<typeof fetchBenefits>
type SetBenefitsAction = ReturnType<typeof setBenefits>
type FetchBenefitDetailsAction = ReturnType<typeof fetchBenefitDetails>
type SetBenefitDetailsAction = ReturnType<typeof setBenefitDetails>

export const fetchBenefitCategoriesEpic: Epic<Action, SetCategoriesAction> = (
  action$,
  state$
) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, FetchBenefitCategoriesAction>(fetchBenefitCategories.type),
    mergeMap(() =>
      benefitsAPI.fetchBenefitsCategories(getLocale(state$.value)).pipe(
        map(({ response }) => benefitCategoryMapper(response)),
        map((categories) => {
          logger.info('Fetched benefit categories.', categories)
          return setCategories({
            categories
          })
        }),
        catchError((error) => {
          logger.error('Error fetching benefit categories.', error)
          const intl = intlHelper.getIntl()
          toast.error(
            intl?.formatMessage({
              id: TranslationKey.ERROR_FETCHING_CATEGORIES
            }),
            toastConfig
          )
          return of(
            setCategories({
              categories: []
            })
          )
        })
      )
    )
  )

export const fetchBenefitsEpic: Epic<Action, SetBenefitsAction> = (
  action$,
  state$
) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, FetchBenefitsAction>(fetchBenefits.type),
    // @ts-expect-error: Problemas de tipos
    mergeMap(({ payload: { categoryID, textFilter } }) =>
      benefitsAPI
        .fetchBenefits(getLocale(state$.value), categoryID, textFilter)
        .pipe(
          map(({ response }) => response.map(benefitMapper)),
          map((benefits) => {
            logger.info('Fetched benefits.', benefits)
            return setBenefits({
              benefits
            })
          }),
          catchError((error) => {
            logger.error('Error fetching benefits', error)
            const intl = intlHelper.getIntl()
            toast.error(
              intl?.formatMessage({
                id: TranslationKey.ERROR_FETCHING_BENEFITS
              }),
              toastConfig
            )
            return of(
              setBenefits({
                benefits: []
              })
            )
          })
        )
    )
  )

export const fetchBenefitDetailsEpic: Epic<Action, SetBenefitDetailsAction> = (
  action$,
  state$
) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, FetchBenefitDetailsAction>(fetchBenefitDetails.type),
    // @ts-expect-error: Problemas de tipos
    mergeMap(({ payload: { benefitID } }) =>
      benefitsAPI.fetchBenefitDetails(benefitID, getLocale(state$.value)).pipe(
        map(({ response }) => benefitMapper(response)),
        map((benefit) => {
          logger.info('Fetched benefit details.', benefit)
          return setBenefitDetails({
            benefit
          })
        }),
        catchError((error) => {
          logger.error('Error fetching benefit.', error)
          const intl = intlHelper.getIntl()
          toast.error(
            intl?.formatMessage({
              id: TranslationKey.ERROR_FETCHING_BENEFITS
            }),
            toastConfig
          )
          return of(
            setBenefitDetails({
              benefit: undefined
            })
          )
        })
      )
    )
  )

export const benefitsEpic = combineEpics(
  // @ts-expect-error: Problemas de tipos
  ...[fetchBenefitCategoriesEpic, fetchBenefitsEpic, fetchBenefitDetailsEpic]
)

export default compensationSlice.reducer
