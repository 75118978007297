import MenuIcon from '@material-ui/icons/Menu'
import React, { useEffect, useState } from 'react'
import { FormattedMessage } from 'react-intl'
import { Link, useLocation } from 'react-router-dom'
import styles from './MenuSidebar.module.scss'

interface MenuItem {
  translationKey: string
  path: string
}

export function MenuSidebar ({
  menuItems
}: {
  menuItems: MenuItem[]
}): JSX.Element {
  const location = useLocation()
  const [showMenu, setShowMenu] = useState(false)
  const openMenu = (): void => {
    setShowMenu(true)
  }

  const closeMenu = (): void => {
    setShowMenu(false)
  }

  useEffect(() => {
    setShowMenu(false)
  }, [location])
  return (
    <div className={styles.mobileMenu}>
      <MenuIcon className={styles.mobileMenuIcon} onClick={openMenu} />
      {showMenu && (
        <div className={styles.bgMenu}>
          <div className={styles.contentMenu}>
            <div className={styles.headerMenu}>
              <MenuIcon onClick={closeMenu} />
              <p>Menú</p>
            </div>
            <ul className={styles.optionsMenu}>
{menuItems.map(({ path, translationKey }) => (
                <li
                  key={translationKey}
                  className={`${
                    location.pathname.startsWith(path)
                      ? styles.menuSelected
                      : ''
                  }`}
                >
                  <Link to={path} key={path}>
                    <FormattedMessage id={translationKey} />
                  </Link>
                </li>
              ))}
            </ul>
          </div>
        </div>
      )}
    </div>
  )
}
