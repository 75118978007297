import React, { type ReactElement } from 'react'
import holidays from '../../assets/images/Vacaciones.svg'
import styles from './Holidays.module.scss'

function Holidays (): ReactElement {
  return <div className={styles.container}>
    <div className={styles.header}>
      <h2>¡Pronto podrás verla!</h2>
      <p>
        Estamos trabajando en esta sección para mejorar la gestión de tus
        vacaciones.
      </p>
    </div>
    <div className={styles.content}>
      <img
        src={holidays}
        alt="Imagen de vacaciones"
        className={styles.imgMobil}
      />
    </div>
  </div>
}

export default Holidays
