import {
  CircularProgress,
  createStyles,
  type FilledTextFieldProps,
  InputAdornment,
  makeStyles,
  TextField,
  type Theme
} from '@material-ui/core'
import { useField } from 'formik'
import React, {
  type ChangeEvent,
  type FunctionComponent,
  type ReactElement,
  type ReactNode,
  useCallback
} from 'react'

interface FormTextFieldProps extends Partial<FilledTextFieldProps> {
  name: string
  label: string
  type?: string
  onValueChange?: (value?: string | number) => void
  loading?: boolean
  autoComplete?: 'off' | 'new-password'
  startElement?: ReactNode
  endElement?: ReactNode
  mapValue?: (value: string) => string
  inputComponent?: string | ReactElement
}

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    placeholder: {
      color: theme.palette.grey[600]
    }
  })
)

const FormTextField: FunctionComponent<FormTextFieldProps> = ({
  name,
  label,
  placeholder,
  onValueChange,
  type = 'text',
  loading = false,
  autoComplete = 'off',
  startElement,
  endElement = null,
  mapValue,
  inputComponent,
  ...props
}): ReactElement => {
  const [field, meta] = useField<number | string>(name)

  const handleChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      const newValue = (mapValue != null)
        ? mapValue(event.target.value)
        : event.target.value
      if (onValueChange != null) onValueChange(newValue)
      field.onChange({
        ...event,
        target: {
          name,
          value: newValue
        }
      })
    },
    [field, mapValue, name, onValueChange]
  )

  const hasError = meta.touched && typeof meta.error !== 'undefined' && meta.error !== null
  const classes = useStyles()

  return (
    <TextField
      {...field}
      value={field.value !== null && field.value !== 0 ? field.value : ''}
      {...props}
      onChange={handleChange}
      label={label}
      variant="outlined"
      fullWidth
      InputProps={{
        inputComponent: inputComponent as any,
        autoComplete,
        type,
        placeholder,
        startAdornment: typeof startElement !== 'undefined' && startElement !== null
          ? (
          <InputAdornment position="start" className={classes.placeholder}>
            {startElement}
          </InputAdornment>
            )
          : undefined,
        endAdornment:
          typeof endElement !== 'undefined' && endElement !== null
            ? endElement
            : loading
              ? (
            <InputAdornment position="end">
              {loading && (
                <CircularProgress size="20px" thickness={5} color="primary" />
              )}
              {endElement}
            </InputAdornment>
                )
              : undefined
      }}
      error={hasError}
      helperText={hasError ? meta.error : ' '}
    />
  )
}

export default FormTextField
