import {
  type Action,
  createSelector,
  createSlice,
  type PayloadAction
} from '@reduxjs/toolkit'
import * as formulaParser from 'hot-formula-parser'
import { toast } from 'react-toastify'
import { combineEpics, type Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap, switchMap } from 'rxjs/operators'
import { compensationAPI } from '../../api'
import toastConfig from '../../config/toast'
import {
  type Band,
  CompensationType,
  CountryCode,
  Gender,
  SpecialMexicoCompensations
} from '../../enums'
import intlHelper from '../../i18n/intlHelper'
import { TranslationKey } from '../../i18n/translations'
import { logger } from '../../services'
import { type Bonus, type Compensation } from '../../types'
import {
  clearUserSession,
  getLocale,
  getUserCountryCode,
  getUserGender,
  getUserMonthsServed
} from '../userSession/slice'

/**
 * Action Payloads
 */
interface SetCompensationPayload {
  compensations: Compensation[] | null
}

interface SetBonusPayload {
  bonus: Bonus | null
}

interface SetMonthlySalaryPayload {
  monthlySalary: number
}

interface SetBandPayload {
  band: Band
}

interface SetBandStiPayload {
  bandSti: Band
}

interface SetTimeDedicationRate {
  timeDedicationRate: number
}

interface SetJobBandMultiplier {
  jobBandMultiplier: number
}

interface SetIndividualAchievementPayload {
  individualAchievement: number
}

interface SetDiscretionaryIncentivePayload {
  discretionaryIncentive: number
}

interface SetSizeOfPiePayload {
  sizeOfPie: number
}

interface SetIndividualTargetPayload {
  individualTarget: number
}

interface SetEntityPerformPayload {
  entityPerform: number
}

interface SetBonusTarget {
  bonusTarget: number
}

interface SetKicker {
  kicker: number
}

/**
 * State
 */
interface CompensationState {
  fetchingCompensation: boolean
  compensations: Compensation[] | null
  fetchingBonus: boolean
  bonus: Bonus | null
  realBonus: Bonus | null
  monthlySalary: number
  band: Band | null
  bandSti: Band | null
  timeDedicationRate: number
  jobBandMultiplier: number
  individualAchievement: number
  discretionaryIncentive: number
  sizeOfPie: number
  individualTarget: number
  entityPerform: number
  bonusTarget: number
  kicker: number
}

const initialState: CompensationState = {
  fetchingCompensation: true,
  compensations: null,
  fetchingBonus: true,
  bonus: null,
  realBonus: null,
  monthlySalary: 0,
  band: null,
  bandSti: null,
  timeDedicationRate: 1,
  jobBandMultiplier: 0,
  individualAchievement: 0,
  discretionaryIncentive: 0,
  individualTarget: 0,
  sizeOfPie: 100,
  entityPerform: 100,
  bonusTarget: 1,
  kicker: 100
}

/**
 * Slice
 */
const compensationSlice = createSlice({
  name: 'compensation',
  initialState,
  extraReducers: {
    [clearUserSession.toString()]: () => initialState
  },
  reducers: {
    fetchCompensation (state) {
      state.fetchingCompensation = true
    },
    setCompensation (state, action: PayloadAction<SetCompensationPayload>) {
      state.fetchingCompensation = false
      state.compensations = action.payload.compensations
    },
    fetchBonus (state) {
      state.fetchingBonus = true
    },
    setBonus (state, action: PayloadAction<SetBonusPayload>) {
      state.fetchingBonus = false
      state.bonus = action.payload.bonus
      if (state.realBonus == null) {
        state.realBonus = action.payload.bonus
      }

      if (state.individualAchievement !== null) {
        if (state.individualAchievement !== 0) {
          const valueIndividuals =
            action.payload.bonus?.complements[0].variables[0].value
          state.individualAchievement =
            valueIndividuals != null
              ? valueIndividuals[valueIndividuals?.length - 1]
              : 100
        }
      }
    },
    setMonthlySalary (state, action: PayloadAction<SetMonthlySalaryPayload>) {
      state.monthlySalary = action.payload.monthlySalary
    },
    setBand (state, action: PayloadAction<SetBandPayload>) {
      state.band = action.payload.band
    },
    setBandSti (state, action: PayloadAction<SetBandStiPayload>) {
      state.bandSti = action.payload.bandSti
    },
    setTimeDedicationRate (state, action: PayloadAction<SetTimeDedicationRate>) {
      state.timeDedicationRate = action.payload.timeDedicationRate
    },
    setJobBandMultiplier (state, action: PayloadAction<SetJobBandMultiplier>) {
      state.jobBandMultiplier = action.payload.jobBandMultiplier
    },
    setIndividualAchievement (
      state,
      action: PayloadAction<SetIndividualAchievementPayload>
    ) {
      state.individualAchievement = action.payload.individualAchievement
    },
    setDiscretionaryIncentive (
      state,
      action: PayloadAction<SetDiscretionaryIncentivePayload>
    ) {
      state.discretionaryIncentive = action.payload.discretionaryIncentive
    },
    setSizeOfPie (state, action: PayloadAction<SetSizeOfPiePayload>) {
      state.sizeOfPie = action.payload.sizeOfPie
    },
    setBonusTarget (state, action: PayloadAction<SetBonusTarget>) {
      state.bonusTarget = action.payload.bonusTarget
    },
    setKicker (state, action: PayloadAction<SetKicker>) {
      state.kicker = action.payload.kicker
    },
    setIndividualTarget (
      state,
      action: PayloadAction<SetIndividualTargetPayload>
    ) {
      state.individualTarget = action.payload.individualTarget
    },
    setEntityPerform (state, action: PayloadAction<SetEntityPerformPayload>) {
      state.entityPerform = action.payload.entityPerform
    }
  }
})

/**
 * Actions
 */
export const {
  fetchCompensation,
  setCompensation,
  fetchBonus,
  setBonus,
  setMonthlySalary,
  setBand,
  setBandSti,
  setTimeDedicationRate,
  setJobBandMultiplier,
  setIndividualAchievement,
  setDiscretionaryIncentive,
  setSizeOfPie,
  setIndividualTarget,
  setEntityPerform,
  setBonusTarget,
  setKicker
} = compensationSlice.actions

/**
 * Selectors
 */
const getModuleState = (state: {
  [compensationSlice.name]: CompensationState
}): CompensationState => {
  return state[compensationSlice.name]
}

export const getFetchingCompensation = createSelector(
  getModuleState,
  (state) => state.fetchingCompensation
)

export const getCompensationsRegular = createSelector(
  getModuleState,
  (state): Compensation[] =>
    // @ts-expect-error: Problemas de tipos
    state.compensations?.filter(
      ({ type }) => type === CompensationType.Regular
    ) != null || []
)

export const getCompensationsBenefits = createSelector(
  getModuleState,
  (state): Compensation | undefined =>
    state.compensations?.find(({ type }) => type === CompensationType.Benefits)
)

export const getFetchingBonus = createSelector(
  getModuleState,
  (state) => state.fetchingBonus
)

export const getRealBonus = createSelector(
  getModuleState,
  (state) => state.realBonus
)
export const getRealBonusCurrency = createSelector(
  getRealBonus,
  (bonus) => bonus?.bonus.unit
)
export const getRealBonusHomeCurrency = createSelector(
  getRealBonus,
  // (bonus) => bonus?.homeCurrency,
  (bonus) => null
)

export const getBonus = createSelector(getModuleState, (state) => state.bonus)

export const getMonthlySalary = createSelector(
  getModuleState,
  (state) => state.monthlySalary
)

export const getBand = createSelector(getModuleState, (state) => state.band)
export const getBandSti = createSelector(
  getModuleState,
  (state) => state.bandSti
)

export const getTimeDedicationRate = createSelector(
  getModuleState,
  (state) => state.timeDedicationRate
)

export const getJobBandMultiplier = createSelector(
  getModuleState,
  (state) => state.jobBandMultiplier
)

export const getIndividualAchievement = createSelector(
  getModuleState,
  (state) => state.individualAchievement
)

export const getDiscretionaryIncentive = createSelector(
  getModuleState,
  (state) => state.discretionaryIncentive
)

export const getSizeOfPie = createSelector(
  getModuleState,
  (state) => state.sizeOfPie
)

export const getBonusTarget = createSelector(
  getModuleState,
  (state) => state.bonusTarget
)

export const getIndividualTarget = createSelector(
  getModuleState,
  (state) => state.individualTarget
)

export const getEntityPerform = createSelector(
  getModuleState,
  (state) => state.entityPerform
)

export const getKicker = createSelector(
  getModuleState,
  (state) => state.kicker
)

/**
 * Calculated
 */
const mappedCompensationsParser = new formulaParser.Parser()
export const getMappedCompensations = createSelector(
  [
    getCompensationsRegular,
    getUserMonthsServed,
    getUserGender,
    getMonthlySalary,
    getUserCountryCode
  ],
  (compensations, monthsServed, userGender, monthlySalary, userCountryCode) => {
    mappedCompensationsParser.setVariable('MONTHS_SERVED', monthsServed ?? 12)
    mappedCompensationsParser.setVariable(
      'GENDER',
      userGender ?? Gender.Female
    )
    mappedCompensationsParser.setVariable('MONTHLY_SALARY', monthlySalary)
    if (Array.isArray(compensations)) {
      return compensations
        .map((compensation) => {
          if (compensation.variables != null) {
            Object.entries(compensation.variables).forEach(
              ([variable, value]) => {
                mappedCompensationsParser.setVariable(variable, value)
              }
            )
          }
          const items = compensation.items?.map((item) => {
            if (item.variables != null) {
              Object.entries(item.variables).forEach(([variable, value]) => {
                mappedCompensationsParser.setVariable(variable, value)
              })
            }
            return {
              ...item,
              value: Number(mappedCompensationsParser.parse(item.formula).result)
            }
          })
          const itemsTotal =
            items?.reduce((sum, { value }) => sum + value, 0) ?? 0
          return {
            ...compensation,
            value:
              compensation.formula !== undefined
                ? Number(
                  mappedCompensationsParser.parse(compensation.formula).result
                )
                : itemsTotal,
            items
          }
        })
        .filter(
          ({ value, name }) =>
            userCountryCode !== CountryCode.Mexico ||
            value !== 0 ||
            !Object.values(SpecialMexicoCompensations).includes(
              name as SpecialMexicoCompensations
            )
        )
    } else {
      return []
    }
  }
)

const mappedBenefitParser = new formulaParser.Parser()
export const getMappedCompensationBenefits = createSelector(
  [
    getCompensationsBenefits,
    getUserMonthsServed,
    getUserGender,
    getMonthlySalary
  ],
  (compensationBenefit, monthsServed, userGender, monthlySalary) => {
    if (compensationBenefit == null) return []
    mappedBenefitParser.setVariable('GENDER', userGender ?? Gender.Female)
    mappedBenefitParser.setVariable('MONTHS_SERVED', monthsServed)
    mappedBenefitParser.setVariable('MONTHLY_SALARY', monthlySalary)
    return (
      compensationBenefit.items?.map((item) => {
        if (item.variables != null) {
          Object.entries(item.variables).forEach(([variable, value]) => {
            mappedCompensationsParser.setVariable(variable, value)
          })
        }
        return {
          ...item,
          value: Number(mappedCompensationsParser.parse(item.formula).result)
        }
      }) != null || []
    )
  }
)

const benefitsTotalParser = new formulaParser.Parser()
export const getBenefitsTotal = createSelector(
  [
    getCompensationsBenefits,
    getUserGender,
    getUserMonthsServed,
    getMonthlySalary
  ],
  (benefits, userGender, monthsServed, monthlySalary) => {
    benefitsTotalParser.setVariable('GENDER', userGender ?? Gender.Female)
    benefitsTotalParser.setVariable('MONTHS_SERVED', monthsServed)
    benefitsTotalParser.setVariable('MONTHLY_SALARY', monthlySalary)
    return (
      benefits?.items?.reduce(
        (sum, { formula }) =>
          sum + Number(benefitsTotalParser.parse(formula).result),
        0
      ) ?? 0
    )
  }
)

const getCompensationsTotal = createSelector(
  getMappedCompensations,
  (compensations) =>
    compensations.reduce((sum, { value }) => sum + (value ?? 0), 0)
)

const achievementParser = new formulaParser.Parser()
export const getTotalAchievementScore = createSelector(
  [
    getBonus,
    getSizeOfPie,
    getEntityPerform,
    getIndividualAchievement,
    getDiscretionaryIncentive,
    getIndividualTarget
  ],
  (
    bonus,
    sizeOfPie,
    entityPerform,
    individualAchievement,
    discretionaryIncentive,
    individualTarget
  ) => {
    achievementParser.setVariable('sop', sizeOfPie)
    achievementParser.setVariable('ep', entityPerform)
    achievementParser.setVariable('ip', individualAchievement ?? 0)
    achievementParser.setVariable('di', discretionaryIncentive ?? 0)
    achievementParser.setVariable('it', individualTarget ?? 0)

    return Number(
      achievementParser.parse(bonus?.complements[0].formula ?? '').result ?? 0
    )
  }
)

const bonusTotalParser = new formulaParser.Parser()
export const getBonusTotal = createSelector(
  [
    getBonus,
    getBandSti,
    getTotalAchievementScore,
    getTimeDedicationRate,
    getJobBandMultiplier,
    getDiscretionaryIncentive,
    getBonusTarget,
    getKicker
  ],
  (
    bonus,
    bandSti,
    totalAchievementScore,
    timeDedicationRate,
    jobBandMultiplier,
    discretionaryIncentive,
    bonusTarget,
    kicker
  ) => {
    bonusTotalParser.setVariable('tas', totalAchievementScore)
    bonusTotalParser.setVariable('mrs', bandSti ?? 0)
    bonusTotalParser.setVariable('tdr', timeDedicationRate)
    bonusTotalParser.setVariable('bm', jobBandMultiplier ?? 0)
    bonusTotalParser.setVariable('di', discretionaryIncentive ?? 0)
    bonusTotalParser.setVariable('bt', bonusTarget)
    bonusTotalParser.setVariable('k', kicker)
    return Number(
      bonusTotalParser.parse(bonus?.bonus.formula ?? '').result ?? 0
    )
  }
)

const staticBonusTotalParser = new formulaParser.Parser()
export const getStaticBonusTotal = createSelector(
  [
    getRealBonus,
    getBandSti,
    getTotalAchievementScore,
    getTimeDedicationRate,
    getBonusTarget
  ],
  (bonus, bandSti, totalAchievementScore, timeDedicationRate, bonusTarget) => {
    staticBonusTotalParser.setVariable('tas', totalAchievementScore ?? 1)
    staticBonusTotalParser.setVariable('bt', bonusTarget)
    staticBonusTotalParser.setVariable('mrs', bandSti ?? 0)
    staticBonusTotalParser.setVariable('tdr', timeDedicationRate ?? 1)
    const rawBonusTotal = Number(
      staticBonusTotalParser.parse(bonus?.bonus.formula ?? '').result ?? 0
    )
    return rawBonusTotal * 1
  }
)

export const getAdjustedAnnualBase = createSelector(
  [getMonthlySalary, getUserCountryCode],
  (monthlySalary, userCountryCode) =>
    userCountryCode === CountryCode.Panama
      ? monthlySalary * 11
      : monthlySalary * 12
)

export const getAnnualSalary = createSelector(
  [
    getAdjustedAnnualBase,
    getCompensationsTotal,
    getBenefitsTotal,
    getStaticBonusTotal
  ],
  (annualBase, compensationsTotal, benefitsValue, bonusValue) =>
    annualBase + compensationsTotal + benefitsValue + bonusValue
)

export const getMappedCompensationValues = createSelector(
  getMappedCompensations,
  (compensations) => compensations.map(({ value }) => value)
)

export const getTotalPercentages = createSelector(
  [
    getAnnualSalary,
    getAdjustedAnnualBase,
    getMappedCompensationValues,
    getBenefitsTotal,
    getStaticBonusTotal
  ],
  (
    annualSalary,
    annualBase,
    compensationValues,
    benefitsTotal,
    bonusValue
  ): number[] =>
    annualBase > 0
      ? [annualBase, ...compensationValues, benefitsTotal, bonusValue].map(
          (val) => (val / annualSalary) * 100
        )
      : []
)

/**
 * Epics
 */
type FetchCompensationAction = ReturnType<typeof fetchCompensation>
type SetCompensationAction = ReturnType<typeof setCompensation>
type FetchBonusAction = ReturnType<typeof fetchBonus>
type SetBonusAction = ReturnType<typeof setBonus>

const fetchCompensationEpic: Epic<Action, SetCompensationAction> = (
  action$,
  state$
) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, FetchCompensationAction>(fetchCompensation.type),
    mergeMap(() =>
      compensationAPI.fetchCompensationData(getLocale(state$.value)).pipe(
        map(({ response }) => response.compensation),
        map((compensations) => {
          logger.info('Fetched compensation.', compensations)
          return setCompensation({
            compensations
          })
        }),
        catchError((error) => {
          logger.error('Error fetching bonus', error)
          const intl = intlHelper.getIntl()
          toast.error(
            intl?.formatMessage({
              id: TranslationKey.ERROR_FETCHING_COMPENSATION
            }),
            toastConfig
          )
          return of(
            setCompensation({
              compensations: null
            })
          )
        })
      )
    )
  )

const fetchBonusEpic: Epic<Action, SetBonusAction> = (action$, state$) =>
  action$.pipe(
    // @ts-expect-error: Problemas de tipos
    ofType<Action, FetchBonusAction>(fetchBonus.type),
    switchMap(() =>
      compensationAPI.fetchBonusData(getBand(state$.value)).pipe(
        map(({ response }) => response),
        map((bonus) => {
          logger.info('Fetched bonus.', bonus)
          return setBonus({
            bonus
          })
        }),
        catchError((error) => {
          logger.error('Error fetching bonus', error)
          const intl = intlHelper.getIntl()
          toast.error(
            intl?.formatMessage({
              id: TranslationKey.ERROR_FETCHING_BONUS
            }),
            toastConfig
          )
          return of(
            setBonus({
              bonus: null
            })
          )
        })
      )
    )
  )

export const compensationEpic = combineEpics(
  // @ts-expect-error: Problemas de tipos
  ...[fetchCompensationEpic, fetchBonusEpic]
)

export default compensationSlice.reducer
