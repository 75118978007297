import { Grid, Typography } from '@material-ui/core'
import Card from '@material-ui/core/Card'
import CardActions from '@material-ui/core/CardActions'
import CardContent from '@material-ui/core/CardContent'
import React, { type ReactElement, useState } from 'react'
import { Link } from 'react-router-dom'
import { type BenefitCategoryCardProps } from '../../../../types'
import { mapBackendPublicURL } from '../../../../helpers/util-functions'
import DeleteItem from './ModalDelete'
import NewCategorie from './ModalFormCategorie'
import styles from './Categories.module.scss'

function BenefitCategoryCard (props: BenefitCategoryCardProps): ReactElement {
  const { data, url, deleteItem, loading, saveData } = props
  const [alert, setAlert] = useState(false)

  const handleDelete = (idCat: number): void => {
    if (deleteItem != null) {
      deleteItem(idCat)
    }
  }

  const handleClickForm = (): void => {
    setAlert(true)
  }
  const handleClose = (): void => {
    setAlert(false)
  }

  const handleSaveCategorie = (save: boolean): void => {
    saveData(save)
  }

  return (
    <Grid item xs={6} sm={3} md={3} lg={2}>
      <Card variant="outlined" className={styles.contentCard}>
        <CardActions className={styles.spaceBotttom}>
          <Grid item xs={10} className={styles.textRight}>
            <NewCategorie edit nameBtn="Editar" idRow={data.id} showForm saveData={handleSaveCategorie} dataCat={data} />
          </Grid>
          <Grid item xs={2}>
            <DeleteItem
              deleteItem={handleDelete}
              open={handleClickForm}
              close={handleClose}
              showAlert={alert}
              title="Eliminar categoría"
              loading={loading}
              describe="Es posible que haya beneficios asociados, ¿Seguro quieres eliminar la categoría seleccionada?"
              id={data.id}
            />
          </Grid>
        </CardActions>
        <Link to={url} className={styles.link}>
          <CardContent className={styles.contentCardBody}>
            <img src={mapBackendPublicURL(data.icon)} alt="Benefit category card logo." />
            <br />
            <Typography variant="body1">{data.name}</Typography>
          </CardContent>
        </Link>
      </Card>
    </Grid>
  )
}

export default BenefitCategoryCard
