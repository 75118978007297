import { IconButton } from '@material-ui/core'
import { type CSSProperties } from '@material-ui/core/styles/withStyles'
import { Close as CloseIcon } from '@material-ui/icons'
import React, {
  type FunctionComponent,
  type ReactElement,
  type ReactNode,
  type RefObject,
  useCallback,
  useEffect,
  useState
} from 'react'
import Modal, { type Props as ModalProps } from 'react-modal'

Modal.setAppElement('#root')

const customStyles: Modal.Styles = {
  content: {
    alignItems: 'center',
    borderRadius: 16,
    bottom: 0,
    boxShadow: '2px 2px 20px #00000029',
    display: 'flex',
    flexDirection: 'column',
    left: 0,
    margin: 0,
    maxHeight: '90vh',
    maxWidth: 700,
    minHeight: 500,
    opacity: 1,
    overflowY: 'auto',
    position: 'relative',
    right: 0,
    top: 0,
    width: '85%'
  },

  overlay: {
    alignItems: 'center',
    backdropFilter: 'blur(1px)',
    backgroundColor: 'rgba(10, 10, 10, 0.4)',
    display: 'flex',
    justifyContent: 'center',
    zIndex: 10
  }
}

const closeButtonStyle: CSSProperties = {
  position: 'absolute',
  top: 0,
  right: 0
}

interface GeneralModalProps extends ModalProps {
  children: ReactNode
  buttonRef?: RefObject<HTMLElement>
  buttonId?: string
  withCloseButton?: boolean
  maxWidth?: number
  minHeight?: number
  justifyContent?: 'flex-start' | 'center'
  bottomButtons?: ReactNode[]
  bottomCloseButton?: ReactElement
}

const GeneralModal: FunctionComponent<GeneralModalProps> = ({
  children,
  shouldCloseOnOverlayClick = true,
  shouldCloseOnEsc = true,
  onRequestClose,
  withCloseButton = true,
  buttonRef,
  buttonId,
  maxWidth,
  minHeight,
  justifyContent = 'flex-start',
  bottomButtons,
  bottomCloseButton,
  ...props
}): ReactElement => {
  const [opened, setOpened] = useState(false)

  const openModal = useCallback(() => {
    setOpened(true)
  }, [])
  const closeModal = useCallback(
    (event: any) => {
      setOpened(false)
      if (onRequestClose != null) {
        onRequestClose(event)
      }
    },
    [onRequestClose]
  )
  useEffect(() => {
    if (((buttonRef?.current) == null) && buttonId !== undefined && buttonId !== '') return undefined
    const element = (((buttonRef?.current)) ??
      document.getElementById(buttonId !== undefined ? buttonId : '')) as HTMLElement
    element.addEventListener('click', openModal)
    return () => {
      element.removeEventListener('click', openModal)
    }
  }, [buttonId, buttonRef, openModal])

  const enhancedCloseButton = (bottomCloseButton != null)
    ? React.cloneElement(bottomCloseButton, {
      onClick: closeModal
    })
    : null

  return (
    <Modal
      {...props}
      isOpen={opened}
      shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
      shouldCloseOnEsc={shouldCloseOnEsc}
      onRequestClose={closeModal}
      style={{
        ...customStyles,
        content: {
          ...customStyles.content,
          justifyContent,
          maxWidth,
          minHeight
        }
      }}
    >
      {withCloseButton && (
        <IconButton
          color="primary"
          style={closeButtonStyle}
          onClick={closeModal}
        >
          <CloseIcon />
        </IconButton>
      )}

      {children}
      {(bottomButtons != null) && (
        <div
          style={{
            marginTop: 20,
            marginBottom: 10
          }}
        >
          {enhancedCloseButton}
          {bottomButtons}
        </div>
      )}
    </Modal>
  )
}

export default GeneralModal
