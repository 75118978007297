import { type Observable } from 'rxjs'
import { ajax, type AjaxResponse } from 'rxjs/ajax'
import { apiURL } from '../config/environment'
import { HttpMethod, type Locale } from '../enums'
import { type RawUser } from '../types'

const contentTypeJSONHeader = {
  'Content-Type': 'application/json'
}
const acceptJSONHeader = {
  Accept: 'application/json; charset=UTF-8'
}

export interface FetchUserSessionResponse extends AjaxResponse<any> {
  response: {
    user: RawUser
  }
}

export const fetchUserSession = (): Observable<FetchUserSessionResponse> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/user-session` : '',
    method: HttpMethod.Get,
    headers: acceptJSONHeader,
    withCredentials: true
  })

export const refreshUserSession = (): Observable<AjaxResponse<any>> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/refresh` : '',
    method: HttpMethod.Post,
    headers: acceptJSONHeader,
    withCredentials: true
  })

interface LoginUserResponse extends AjaxResponse<any> {
  response: {
    user: RawUser
  }
}
export const loginUser = (
  code: string,
  state: string
): Observable<LoginUserResponse> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/sign-in` : '',
    method: HttpMethod.Post,
    body: {
      code,
      state
    },
    headers: {
      ...contentTypeJSONHeader,
      ...acceptJSONHeader,
      origin: process.env.REACT_APP_APP_URL
    },
    withCredentials: true
  })

export const logoutUser = (): Observable<AjaxResponse<any>> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/logout` : '',
    method: HttpMethod.Get,
    headers: acceptJSONHeader,
    withCredentials: true
  })

export const createUser = (
  sharp: number,
  email: string,
  password: string,
  locale: Locale
): Observable<AjaxResponse<any>> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/register` : '',
    method: HttpMethod.Post,
    body: {
      sharp,
      email,
      password
    },
    headers: {
      'Accept-Language': locale,
      ...contentTypeJSONHeader,
      ...acceptJSONHeader
    }
  })

export const verifyCode = (
  date: Date,
  code: number,
  locale: Locale
): Observable<AjaxResponse<any>> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/verify-code` : '',
    method: HttpMethod.Post,
    body: {
      date,
      code
    },
    headers: {
      'Accept-Language': locale,
      ...contentTypeJSONHeader,
      ...acceptJSONHeader
    }
  })

export const forgotPassword = (
  email: string,
  locale: Locale
): Observable<AjaxResponse<any>> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/forgot-password` : '',
    method: HttpMethod.Post,
    body: {
      email
    },
    headers: {
      'Accept-Language': locale,
      ...contentTypeJSONHeader,
      ...acceptJSONHeader
    },
    withCredentials: true
  })

interface VerifyTokenResponse extends AjaxResponse<any> {
  response: {
    isAdmin: boolean
  }
}
export const verifyPasswordRecoveryToken = (
  token: string
): Observable<VerifyTokenResponse> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/verify-password-token?token=${token}` : '',
    method: HttpMethod.Get,
    headers: acceptJSONHeader,
    withCredentials: true
  })

export const recoverPassword = (
  locale: Locale,
  newPassword: string,
  currentPassword?: string,
  token?: string
): Observable<AjaxResponse<any>> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/password-recovery` : '',
    method: HttpMethod.Put,
    body: {
      newPassword,
      currentPassword,
      token
    },
    headers: {
      'Accept-Language': locale,
      ...contentTypeJSONHeader,
      ...acceptJSONHeader
    },
    withCredentials: true
  })

interface UploadAvatarResponse extends AjaxResponse<any> {
  response: {
    avatar: string
  }
}
export const uploadAvatar = (
  avatarImage: File
): Observable<UploadAvatarResponse> => {
  const formData = new FormData()
  formData.append('avatar', avatarImage)
  return ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/update-avatar` : '',
    method: HttpMethod.Post,
    body: formData,
    headers: acceptJSONHeader,
    withCredentials: true
  })
}

export const reportError = (
  fields: string,
  description: string
): Observable<AjaxResponse<any>> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/profile-error` : '',
    method: HttpMethod.Post,
    body: {
      fields,
      description
    },
    headers: {
      ...acceptJSONHeader,
      ...contentTypeJSONHeader
    },
    withCredentials: true
  })

interface UpdateProfileResponse extends AjaxResponse<any> {
  response: {
    profile: {
      name: string
      email: string
    }
  }
}
export const updateProfile = (
  name: string,
  email: string,
  locale: Locale
): Observable<UpdateProfileResponse> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/update-name` : '',
    method: HttpMethod.Put,
    body: {
      name,
      email
    },
    headers: {
      'Accept-Language': locale,
      ...acceptJSONHeader,
      ...contentTypeJSONHeader
    },
    withCredentials: true
  })

interface EmailChangeVerificationResponse extends AjaxResponse<any> {
  response: {
    email: string
  }
}
export const emailChangeVerification = (
  token: string,
  locale: Locale
): Observable<EmailChangeVerificationResponse> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/verify-email` : '',
    method: HttpMethod.Put,
    body: {
      token
    },
    headers: {
      'Accept-Language': locale,
      ...acceptJSONHeader,
      ...contentTypeJSONHeader
    }
  })

interface VerifyMinuTokenResponse extends AjaxResponse<any> {
  response: {
    data: string
  }
}

export const getSessionToken = (): Observable<VerifyMinuTokenResponse> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/session-token` : '',
    method: HttpMethod.Get,
    headers: acceptJSONHeader,
    withCredentials: true
  })
