import { Button, Grid, Typography } from '@material-ui/core'
import React, { type ReactElement, useCallback, useRef } from 'react'
import { FormattedDate, FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import userProfileImg from '../../../assets/images/user-profile.svg'
import { CountryCode } from '../../../enums'
import { TranslationKey } from '../../../i18n/translations'
import isoCountries from '../../../services/isoCountries'
import ProfilePicture from '../ProfilePicture'
import ReportErrorModal from '../ReportErrorModal'
import { getUser, logoutUserRequest } from '../slice'
import styles from './UserProfile.module.scss'

function UserProfile (): ReactElement {
  const user = useSelector(getUser)
  const intl = useIntl()
  const dispatch = useDispatch()

  const handleCloseSessionClick = useCallback(() => {
    dispatch(logoutUserRequest())
  }, [dispatch])

  const reportErrorRef = useRef(null)
  return (
    <Grid container className={styles.container}>
        <ReportErrorModal buttonRef={reportErrorRef} />
        <Grid item xs={12}>
          <div className={styles.profileHeader}>
            <ProfilePicture avatarURL={user?.avatar} />
            <div className={styles.profileTitle}>
              <Typography variant="h5">
                <FormattedMessage id={TranslationKey.MY_PROFILE} />
              </Typography>

              <Typography variant="subtitle1">
                {user?.nickname ?? user?.name}
              </Typography>
            </div>
            <img src={userProfileImg} alt="Users interacting with platform." />
          </div>
        </Grid>
        <Grid container className={styles.containerFields} spacing={2}>
          <Grid item xs={12}>
            <Typography variant="h6" className={styles.textTitle}>
              <FormattedMessage id={TranslationKey.EMAIL} />
            </Typography>
            <div className={styles.textBox}>
              <Typography variant="body1">{user?.email}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={styles.textTitle}>
              <FormattedMessage id={TranslationKey.COUNTRY} />
            </Typography>
            <div className={styles.textBox}>
              <Typography variant="body1">
                {isoCountries.getName(
                  user?.country ?? CountryCode.Mexico,
                  intl.locale
                )}
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={styles.textTitle}>
              <FormattedMessage id={TranslationKey.COMPANY} />
            </Typography>
            <div className={styles.textBox}>
              <Typography variant="body1">{user?.uen}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={styles.textTitle}>
              <FormattedMessage id={TranslationKey.VICE_PRESIDENCY} />
            </Typography>
            <div className={styles.textBox}>
              <Typography variant="body1">{user?.vp}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={styles.textTitle}>
              <FormattedMessage id={TranslationKey.POSITION} />
            </Typography>
            <div className={styles.textBox}>
              <Typography variant="body1">{user?.position}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={styles.textTitle}>
              <FormattedMessage id={TranslationKey.BAND} />
            </Typography>
            <div className={styles.textBox}>
              <Typography variant="body1">{user?.band}</Typography>
            </div>
          </Grid>
          <Grid item xs={12} md={6}>
            <Typography variant="h6" className={styles.textTitle}>
              <FormattedMessage id={TranslationKey.SHARP_ID} />
            </Typography>
            <div className={styles.textBox}>
              <Typography variant="body1">{user?.sharp}</Typography>
            </div>
          </Grid>
          <Grid item xs={12}>
            <Typography variant="h6" className={styles.textTitle}>
              <FormattedMessage id={TranslationKey.HIRING_DATE} />
            </Typography>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={styles.textBox}>
              <Typography variant="body1">
                <FormattedDate
                  value={user?.hiringDate ?? new Date()}
                  day="2-digit"
                />
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={styles.textBox}>
              <Typography variant="body1">
                <FormattedDate
                  value={user?.hiringDate ?? new Date()}
                  month="long"
                />
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} sm={4}>
            <div className={styles.textBox}>
              <Typography variant="body1">
                <FormattedDate
                  value={user?.hiringDate ?? new Date()}
                  year="numeric"
                />
              </Typography>
            </div>
          </Grid>
          <Grid item xs={12} className={styles.buttonsContainer}>
            <button ref={reportErrorRef} type="button" className="link-button">
              <FormattedMessage id={TranslationKey.REPORT_ERROR} />
            </button>
            <Button
              variant="outlined"
              color="primary"
              onClick={handleCloseSessionClick}
            >
              <FormattedMessage id={TranslationKey.CLOSE_SESSION} />
            </Button>
            <Link to="/profile/edit">
              <Button
                variant="contained"
                color="primary"
                style={{ color: '#000' }}
              >
                <FormattedMessage id={TranslationKey.EDIT_PROFILE} />
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Grid>
  )
}

export default UserProfile
