import { type Observable } from 'rxjs'
import { ajax, type AjaxResponse } from 'rxjs/ajax'
import { apiURL } from '../config/environment'
import { HttpMethod } from '../enums'
import {
  type IBenefitSave,
  type IDeleteItem,
  type IFormControlCategorie,
  type ISuppliers
} from '../types'

const contentTypeJSONHeader = {
  'Content-Type': 'application/json'
}
const acceptJSONHeader = {
  Accept: 'application/json; charset=UTF-8'
}

interface ManagerResponse {
  response: {
    success: string
    message?: string
  }
}

export const getCategoriesAdmin = (
): Observable<AjaxResponse<unknown>> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/admin/categories` : '',
    method: HttpMethod.Get,
    headers: {
      ...acceptJSONHeader,
      'Accept-Language': 'es'
    },
    withCredentials: true
  })

export const ManagerCat = (
  data: IFormControlCategorie
): Observable<ManagerResponse> => {
  const formData = new FormData()
  formData.append('thumbnail', data.thumbnail)
  formData.append('icon', data.icon)
  formData.append('pillar', String(data.pillar))
  formData.append('name', String(data.name))

  if (data.id !== null && data.id !== undefined) {
    formData.append('id', String(data.id))
    formData.append('_method', 'put')
  }

  return ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/admin/categories` : '',
    method: HttpMethod.Post,
    body: formData,
    headers: {
      'Accept-Language': 'es'
    },
    withCredentials: true
  })
}

export const getBenefits = (
  idCat: string,
  country: string,
  page: number,
  size: number
): Observable<AjaxResponse<unknown>> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/admin/benefits?page=${page}&sortColumn=title&sortDirection=asc&categoryId=${idCat}&country=${country}&pageSize=${size}` : '',
    method: HttpMethod.Get,
    headers: {
      'Accept-Language': 'es',
      ...acceptJSONHeader,
      ...contentTypeJSONHeader
    },
    withCredentials: true
  })

export const ManagerBenefit = (
  data: IBenefitSave
): Observable<ManagerResponse> => {
  const formData = new FormData()
  formData.append('title', data.title)
  formData.append('country', data.country)
  formData.append('type', data.type)
  formData.append('description', data.description)
  formData.append('supplierId', data.supplierId)
  formData.append('address', data.address)
  formData.append('phone', data.phone)
  formData.append('webPage', data.webPage)
  formData.append('doc', data.doc)
  formData.append('another', data.another)
  formData.append('categoryId', String(data.categoryId))

  if (data.id !== null && data.id !== undefined) {
    formData.append('id', String(data.id))
    formData.append('_method', 'put')
  }

  return ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/admin/benefits` : '',
    method: HttpMethod.Post,
    body: formData,
    headers: {
      'Accept-Language': 'es'
    },
    withCredentials: true
  })
}

export const DeleteBenefit = (data: IDeleteItem): Observable<ManagerResponse> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/admin/benefits` : '',
    method: HttpMethod.Delete,
    body: data,
    headers: {
      'Accept-Language': 'es',
      ...acceptJSONHeader,
      ...contentTypeJSONHeader
    },
    withCredentials: true
  })

export const DeleteCategorie = (
  data: IDeleteItem
): Observable<ManagerResponse> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/admin/categories` : '',
    method: HttpMethod.Delete,
    body: data,
    headers: {
      'Accept-Language': 'es',
      ...acceptJSONHeader,
      ...contentTypeJSONHeader
    },
    withCredentials: true
  })

export const getSupplier = (page: number, textFilter: string, size: number): Observable<AjaxResponse<unknown>> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/admin/suppliers?page=${page}&sortColumn=name&sortDirection=desc&textFilter=${textFilter}&pageSize=${size}` : '',
    method: HttpMethod.Get,
    headers: {
      'Accept-Language': 'es',
      ...acceptJSONHeader,
      ...contentTypeJSONHeader
    },
    withCredentials: true
  })

export const ManagerSupplier = (
  data: ISuppliers
): Observable<ManagerResponse> => {
  const formData = new FormData()
  formData.append('logo', data.logo)
  formData.append('name', data.name)

  if (data.id !== null && data.id !== undefined) {
    formData.append('id', String(data.id))
    formData.append('_method', 'put')
  }

  return ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/admin/suppliers` : '',
    method: HttpMethod.Post,
    body: formData,
    headers: {
      'Accept-Language': 'es'
    },
    withCredentials: true
  })
}

export const DeleteSupplier = (
  data: IDeleteItem
): Observable<ManagerResponse> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/admin/suppliers` : '',
    method: HttpMethod.Delete,
    body: data,
    headers: {
      'Accept-Language': 'es',
      ...acceptJSONHeader,
      ...contentTypeJSONHeader
    },
    withCredentials: true
  })
