import queryString from 'query-string'
import { apiURL } from '../config/environment'
import { Locale, UserRole } from '../enums'

export const getLocaleFromBrowser = (): Locale => Locale.Spanish
// navigator.language.includes('es') || process.env.NODE_ENV === 'development'
//   ? Locale.Spanish
//   : Locale.English;

export const serializeQuery = (
  obj: Record<string, unknown> | number[]
): string => {
  const isoDateObj = Object.entries(obj).reduce(
    (newObj, [key, value]) => ({
      ...newObj,
      [key]: value instanceof Date ? value.toISOString() : (value as string)
    }),
    {}
  )
  return queryString.stringify(isoDateObj, {
    arrayFormat: 'bracket'
  })
}

export const getFlattenedFields = (
  obj: Record<string, string>,
  prefix = ''
): string[] =>
  Object.entries(obj).reduce<string[]>((accumulator, [key, value]): string[] => {
    if (typeof value === 'object') {
      return [...accumulator, ...getFlattenedFields(value, key)]
    }
    const realKey = prefix !== '' ? `${prefix}.${key}` : key
    return [...accumulator, realKey]
  }, [])

export function testCredentialsError (this: {
  parent: { credentialsError: boolean }
}): boolean {
  return !this.parent.credentialsError
}

export const idDocumentInputConvert = (rawValue: string): string =>
  rawValue.toLocaleUpperCase().replace(/[^a-zA-Z0-9-]/g, '')

export const mapBackendPublicURL = (urlPath?: string): string => {
  if (typeof urlPath !== 'undefined' && urlPath !== null) {
    if (typeof apiURL !== 'undefined' && apiURL !== null) {
      return `${apiURL.replace(/api$/g, '')}${urlPath}`
    }
  }
  return ''
}

export const percentageFormatter = (value: number): string =>
  `${Number.isInteger(value) ? value : value.toFixed(2)}%`

export const getValidUserRoles = (): UserRole[] =>
  Object.values(UserRole).filter((role) => role !== UserRole.Invalid)
