import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { UserRole } from '../../enums'
import { getUserRole } from '../userSession/slice'

export const useAdminRedirect = (): void => {
  const userRole = useSelector(getUserRole)
  const navigate = useNavigate()

  useEffect(() => {
    if (userRole !== UserRole.Admin) {
      navigate('/')
    }
  }, [navigate, userRole])
}
