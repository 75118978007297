const PETerms = `
INFORMACIÓN DE LA COMPAÑÍA
Gracias por visitar nuestra plataforma +BeneFI (en lo adelante “la Plataforma”) la misma en de uso exclusivo de los empleados de ABInbev y de sus empresas filiales y subsidiarias, a nivel local o internacional, tiene como objetivo que puedas tener acceso en todo momento a tu información personal, tus beneficios, cálculo de bonos, consulta de volantes de pago, cálculo de compensación total fija y variable, gestión de vacaciones y solicitud de cartas laborales, de la misma forma consultar el estatus de dichas solicitudes, la misma es propiedad de las empresas: Backus Estrategia, UCP Backus y Johnston S.A.A., Cervecería San Juan S.A., Compañía Cervecera Ambev, Transportes 77 S.A., Naviera Oriente S.A.C. y Cervecería Barbarian S.A.C. (que en adelante las denominaremos ABI), no obstante cuando se especifique ABI, también se estará haciendo referencia sus empresas filiales y subsidiarias, a nivel local o internacional. A menos que se especifique de otra manera, los términos "nosotros (as)", "nuestro(a)" se refiere como propio en el contexto de +BeneFI.

Al visitar la Plataforma o hacer uso de los servicios que ofrece la mismas, usted confirma que ha leído y aceptado todo lo estipulado en estos Términos y Condiciones, incluyendo la Política de Privacidad, ambas relacionadas con el uso de esta Plataforma. Los presentes términos y condiciones, regulan el acceso, manejo de información personal y uso de la aplicación en general, en caso de que usted no esté de acuerdo con los mismos y no sea un empleado de ABI, por favor absténgase de hacer uso de esta Plataforma. 

Esta plataforma incluye enlaces con otros sitios, algunos de ellos operados por algunas de nuestras empresas filiales o subsidiarias tanto dentro del territorio de la República del Perú como fuera de él y, otros operados por terceros. Estos enlaces se han colocado como una vía adicional para acceder a la información que ellos contienen. ABI no es responsable por el contenido de cualquiera de las otras páginas web o cualquier producto o servicio que pueda ser ofrecido a través de esos sitios. Los sitios web de terceras partes pueden contener información con la cual ABI esté o no esté de acuerdo. La inclusión de enlaces a otros sitios no puede ser vista como un respaldo del contenido de los sitios web con los cuales se está enlazando. Distintos términos y condiciones pueden aplicar a los sitios web con los cuales se ha enlazado.


ACEPTACIÓN Y MODIFICACIONES
Mediante el uso y acceso a la Plataforma, y al pulsar “ACEPTAR” estos Términos y Condiciones al crear su cuenta, usted reconoce de manera expresa que ha leído, entendido y que está de acuerdo con lo estipulado en este documento, por lo que está de acuerdo en que estos son los términos que regulan el uso de esta Plataforma, incluyendo la Política de Privacidad, si usted no acepta estos Términos y Condiciones, usted no está autorizado a usar esta Plataforma.

El usuario reconoce que ABI en su calidad de empleadora posee información personal del usuario/empleado, y que también, a través del uso de la Plataforma almacena los datos personales que el usuario libre y voluntariamente ha suministrado, tanto propios como de personas relacionadas al empleado/usuario, y que ha dado su consentimiento expreso para el almacenamiento, procesamiento y uso de dichos datos. Para más información sobre el uso los datos personales que recopilamos, para qué fines utilizamos sus datos personales, con quién podemos compartirlos, durante cuánto tiempo lo mantenemos y cómo puede ejercer sus derechos de acceso, rectificación, restricción, supresión, objeción y portabilidad en virtud de las leyes de protección de datos aplicables, favor consultar la Política de Privacidad.

ABI se reserva la facultad de modificar en cualquier momento estos Términos y Condiciones, dichas modificaciones entraran en vigencia una vez sea publicada la versión actualizada en la Plataforma. Le notificaremos el cambio a estos Términos y Condiciones actualizando la fecha de vigencia en la parte superior de estos Términos y Condiciones. El uso continuado de la Plataforma y los servicios prestados mediante la misma después de cualquier modificación a estos Términos y Condiciones indicará que está de acuerdo con dichos términos modificados. En todo caso, se recomienda que consulte periódicamente los presentes términos de uso.

De igual forma ABI se reserva el derecho de hacer adiciones, eliminaciones o modificaciones a la información contenida en la Plataforma en cualquier momento, para bloquear (temporal o permanentemente) el acceso a la Plataforma o cerrar la Plataforma, sin ninguna notificación previa, para realizar mantenimiento y/o implementar cualquier mejora y/o modificación en la Plataforma.

 
PROPÓSITO DE LA PLATAFORMA Y SERVICIOS 
Esta Plataforma es de uso exclusivo de los empleados de ABI, y tiene como objetivo principal responder a sus solicitudes y necesidades de apoyo de manera más eficiente, proporciona el acceso en todo momento a la información personal del empleado/usuario, sus beneficios, cálculo de bonos, compensación total fija y variable, consulta de boletas de pago, gestión de vacaciones, solicitud de cartas laborales, de la misma forma consultar el estatus de dichas solicitudes (todos los servicios disponibles en la Plataforma se denominan "los Servicios").

La plataforma puede tener, enlaces con otros servicios proporcionados por terceros, los mismos son separados de la Plataforma y únicamente enlazados por esta vía, pueden ser operados con un propósito diferente al de este sitio, y el propósito de esos sitios puede incluir la promoción y el mercadeo.

 
OBLIGACIONES Y RESPONSABILIDADES DEL USUARIO
El usuario deberá respetar en todo momento los presentes Términos y Condiciones de uso de la Plataforma. De forma expresa el usuario manifiesta que utilizará la Plataforma de manera diligente y asumiendo cualquier responsabilidad que pudiera derivarse del incumplimiento de las normas.

Al registrarse en la Plataforma, no podrá compartir su(s) contraseña(s), información de cuenta o acceso a la Plataforma. Usted es responsable de mantener la confidencialidad de su(s) contraseña(s) e información de cuenta, y usted es responsable de todas las actividades que ocurran bajo su(s) contraseña(s) o cuenta(s) o como resultado de su acceso a la Plataforma.

Así mismo, el usuario no podrá utilizar la Plataforma para transmitir, almacenar, divulgar, promover o distribuir datos o contenidos que sean portadores de virus o cualquier otro código informático, archivos o programas diseñados para interrumpir, destruir o perjudicar el funcionamiento de cualquier programa o equipo informático o de telecomunicaciones o violar derechos de otros.

El usuario reconoce que no puede, modificar cualquier parte de la Plataforma que no sea razonablemente necesaria para utilizar la Plataforma para el propósito previsto; recopilar o almacenar información personal sobre otros, incluidas las direcciones de correo electrónico;   anunciar u ofrecer la compra o venta de bienes o servicios para cualquier propósito comercial, a menos que dicha facilidad sea autorizada por ABI; hacerse pasar por cualquier persona o entidad con el propósito de engañar a otros; utilizar la Plataforma o los Servicios de cualquier manera que pueda dañar, deshabilitar, sobrecargar o perjudicar la Plataforma o los Servicios o interferir con el uso y disfrute de la Plataforma o los Servicios por parte de terceros; intentar obtener acceso a cualquiera de los Servicios, otras cuentas, sistemas informáticos o redes conectadas a la Plataforma o los Servicios a través de la piratería, la minería de contraseñas o cualquier otra forma o medio.

El usuario acepta cumplir con todas las leyes y reglas locales aplicables con respecto al uso aceptable y la conducta en Internet. Si el usuario utiliza esta Plataforma desde otro país, es responsable del cumplimiento de todas y cada una de las leyes locales aplicables.

En cualquier caso, si el usuario causa una pérdida técnica o corte del servicio del sitio o de los sistemas que transmiten el sitio al usuario y/o a otros, el usuario aceptará la responsabilidad por cualquiera de las obligaciones, costos y gastos, (incluyendo los pagos de abogados) resultantes de ese corte.

De igual forma, el usuario acepta indemnizar y eximir a ABI de cualquier incumplimiento de su parte relacionado a estos Términos y Condiciones, y por cualquier reclamación o demanda presentada contra ABI por cualquier tercero que surja de su uso de la Plataforma, Servicios y/o contenido enviado, publicado o transmitido a través de la Plataforma, incluyendo, entre otros, todas las reclamaciones, acciones, procedimientos, pérdidas, responsabilidades, daños, costos, gastos (incluidos los costos y gastos legales), independientemente de lo que ABI haya sufrido o incurrido en una consecuencia de su incumplimiento de estos Términos y Condiciones.


RESPONSABILIDAD DEL PORTAL
El usuario conoce y acepta que la Plataforma no otorga ninguna garantía de cualquier naturaleza, ya sea expresa o implícita, sobre los datos, contenidos, información y servicios que se incorporan y ofrecen en la Plataforma, más allá de lo exigido por la Ley. De manera especial el usuario conoce y acepta que las herramientas contenidas en la Plataforma, tales como “Bono STI”, “Mi Compensación”, “Beneficios” y otras similares, son estrictamente simulaciones diseñadas para una mejor comprensión del usuario respecto a los beneficios ofrecidos por ABI, por lo que bajo ningún concepto deben ser entendidas como valores fijos que ABI está comprometida a pagar. Así mismo, ABI se reserva el derecho a cambiar las fórmulas y variables para el cálculo de estos valores cuando crea conveniente, sin que esto suponga ningún tipo de eliminación del beneficio al ser parte de una simulación.

Al acceder a esta Plataforma, usted reconoce y acepta que su uso es bajo su propio riesgo y que esta Plataforma, sus propietarios, funcionarios, directores o cualquiera de las partes involucradas en la creación, producción o entrega de esta Plataforma no son responsables de ningún daño, incluyendo cualquier daño directo, incidental, consecuente, indirecto o especial, o cualquier otra pérdida, costos o gastos de cualquier tipo ( incluyendo honorarios legales, honorarios de expertos u otros desembolsos) que puedan surgir, directa o indirectamente, independientemente de si dicha responsabilidad o daños surgen o no en contrato, agravio, negligencia, equidad, por ley o de otra manera, incluyendo por defecto oculto/latente, en cualquier conexión con el acceso, el uso o la navegación de esta Plataforma o en relación con cualquier contenido, información, datos, promociones, actividades, asociadas con esta Plataforma, o en relación con su descarga de cualquier material, texto, datos, imágenes , vídeo o audio de esta Plataforma, incluyendo pero no limitado a cualquier cosa causada por defectos de transmisión, virus, errores, acción humana o inacción de cualquier sistema informático, línea telefónica, hardware, software o software mal funcionamiento, o cualquier otro error, falla o retraso en las transmisiones de computadoras o conexiones de red, o que surja de reclamaciones de terceros (incluso si ABI ha sido advertido de la posibilidad de tales daños). 

Exceptuando los casos que la Ley imponga expresamente lo contrario, y exclusivamente con la medida y extensión en que lo imponga, ABI no garantiza que la Plataforma o los servicios cumplan con sus requisitos, que el Contenido sea preciso o confiable, que la funcionalidad de la Plataforma o los servicios sea ininterrumpida o libre de errores, que los defectos sean corregidos o que la Plataforma o los servicios o el servidor que los pone a disposición estén libres de virus o cualquier otra cosa que pueda ser perjudicial o destructiva. La Plataforma y los que se prestan a través de esta, se proporcionan sin ninguna representación o respaldo realizado y sin garantía de ningún tipo, ya sea expresa o implícita, incluyendo pero no limitado a las garantías implícitas de calidad satisfactoria, idoneidad para un propósito particular, no infracción, compatibilidad, seguridad y exactitud y ausencia de defectos ocultos/latentes. ABI no asume responsabilidad alguna respecto a los posibles daños y perjuicios causados por el uso y utilización de la información, datos y servicios de la misma; excluyendo de manera intrínseca a su propietaria ABI y cualquiera de sus empresas filiales y subsidiarias. 

En todo caso, la Plataforma excluye cualquier responsabilidad por los daños y perjuicios que puedan deberse a la información y/o servicios prestados o suministrados por terceros diferentes de ABI y cualquiera de sus empresas filiales y subsidiarias. Toda responsabilidad será del tercero, ya sea proveedor o colaborador.

En la medida permitida por la ley aplicable, ABI mantiene el derecho de cancelar su acceso a cualquiera o a todos los servicios o a la Plataforma en cualquier momento, sin previo aviso, por cualquier motivo, incluyendo, sin limitación, el incumplimiento de estos Términos y Condiciones. ABI también puede, en cualquier momento, a nuestra entera discreción, interrumpir la Plataforma o los servicios o cualquier parte de la misma con o sin previo aviso y usted acepta que no seremos responsables ante usted o cualquier tercero por cualquier terminación de su acceso a la Plataforma o los Servicios.

ABI hace todos los intentos razonables para excluir virus de esta Plataforma, pero no puede garantizar dicha exclusión, por lo que no se acepta ninguna responsabilidad por virus. Por lo tanto, se recomienda tomar todas las medidas de seguridad adecuadas. En adición ABI tiene la intención de actualizar la Plataforma de forma regular, pero en la medida permitida de conformidad con la ley aplicable, no asume ninguna obligación de actualizar ninguno de los contenidos.
 
Nada de lo que se incluye en estos Términos y Condiciones se interpretará de manera que se excluya o limite la responsabilidad de ABI por fraude, por muerte o lesiones personales como resultado de la negligencia de ABI, o cualquier otra responsabilidad que no pueda ser excluida bajo la ley aplicable.


PROPIEDAD INTELECTUAL E INDUSTRIAL
Esta Plataforma, incluyendo todo su contenido, es propiedad de ABInbev, incluyendo las marcas registrada, sus empresas afiliadas o de sus licenciados, y está protegido por el registro de sus marcas y patentes, bajo las leyes de la República del Perú, Estados Unidos de Norteamérica y de otros países. A menos que se indique expresamente de otra manera, le autorizamos hacer uso de la plataforma, no está permitido imprimir, hacer copias del material o información que se encuentra en la Plataforma, presentar o transmitir cualquier material del sitio en cualquier forma o por cualquier medio y no modificará, venderá, transmitirá, ni distribuirá material del sitio, incluyendo la subida o colocación de material al Internet o colocar el material para que esté disponible en línea. Se advierte al usuario que la información no puede ser modificada, y que debe respetar las leyes de derechos de autor. 

La Plataforma presenta varios logos y marcas registradas y servicios registrados que son de propiedad de o están licenciadas a varias subsidiarias y afiliadas de ABI. La Plataforma puede incluir marcas y servicios registrados pertenecientes a terceras partes. Todos estos registros y marcas son propiedad de sus respectivos dueños y el usuario se abstendrá de usar o presentarlos de cualquier manera sin el debido permiso por escrito del correspondiente dueño del registro o marca.

Derechos de Propiedad Intelectual de otros (terceros). ABI respeta los derechos de propiedad intelectual de terceros, por lo que solicita a los usuarios que hagan lo mismo. Si el usuario considera que su trabajo ha sido copiado de alguna forma que constituye una violación o transgresión a sus derechos, el usuario puede ponerse en contacto y notificárselo a People Aló PE.  No obstante a lo anterior, ABI no es responsable por el uso de derechos de propiedad Intelectual de terceros que puedan hacer otros.
`

export default PETerms
