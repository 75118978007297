import { Button, IconButton } from '@material-ui/core'
import {
  Visibility as VisibilityIcon,
  VisibilityOff as VisibilityOffIcon
} from '@material-ui/icons'
import { Form, Formik } from 'formik'
import { pick as _pick } from 'lodash-es'
import React, {
  type ReactElement,
  type ReactNode,
  useCallback,
  useMemo,
  useRef,
  useState
} from 'react'
import { FormattedMessage, type IntlShape, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import * as yup from 'yup'
import {
  FormCheckboxField,
  FormTextField
} from '../../../components/FormFields'
import LoadingSpinner from '../../../components/LoadingSpinner'
import { TranslationKey } from '../../../i18n/translations'
import TranslateFormikErrors from '../../../wrappers/TranslateFormikErrors'
import { TermsModal } from '../../common/Modals'
import { createUserRequest, getSending } from '../slice'
import styles from './Session.module.scss'

enum RegisterField {
  SharpID = 'sharp',
  Email = 'email',
  TermsAndConditions = 'termsAndConditions',
  Password = 'password',
  PasswordConfirmation = 'passwordConfirmation',
}

interface RegisterFormValues {
  [RegisterField.SharpID]: number | ''
  [RegisterField.Email]: string
  [RegisterField.TermsAndConditions]: boolean
  [RegisterField.Password]: string
  [RegisterField.PasswordConfirmation]: string
}

const getValidationSchema = (intl: IntlShape): yup.ObjectSchema<any> =>
  yup.object().shape({
    [RegisterField.SharpID]: yup
      .number()
      .transform((value: number): undefined | number =>
        Number.isNaN(value) ? undefined : value
      )
      .test(
        'length-test',
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_MIN_LENGTH
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.SHARP_ID
            }),
            minLength: 8
          }
        ),
        (value) => value !== undefined && value.toString().length >= 8
      )
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.SHARP_ID
            })
          }
        )
      ),
    [RegisterField.Email]: yup
      .string()
      .email(
        intl.formatMessage({
          id: TranslationKey.VALIDATE_EMAIL
        })
      )
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.EMAIL
            })
          }
        )
      ),
    [RegisterField.TermsAndConditions]: yup
      .boolean()
      .required()
      .oneOf(
        [true],
        intl.formatMessage({
          id: TranslationKey.VALIDATE_TERMS
        })
      ),
    [RegisterField.Password]: yup
      .string()
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.PASSWORD
            })
          }
        )
      )
      .matches(
        /^(?=.*[A-Za-z])(?=.*\d)(?=.*[.@$!%*#?&-])[A-Za-z\d.@$!%*#?&-]{8,}$/,
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_PWD_INSTRUCTIONS
          },
          {
            length: 8
          }
        )
      ),
    // @ts-expect-error: Problemas de tipos
    [RegisterField.PasswordConfirmation]: yup
      .string()
      .when(RegisterField.Password, {
        is: (password: string | null): boolean =>
          password !== null && password.length > 0,
        then: yup.string().oneOf(
          [yup.ref('password')],
          intl.formatMessage({
            id: TranslationKey.VALIDATE_PWD_MATCH
          })
        )
      })
      .required(
        intl.formatMessage(
          {
            id: TranslationKey.VALIDATE_REQUIRED
          },
          {
            fieldName: intl.formatMessage({
              id: TranslationKey.REPEAT_NEW_PWD
            })
          }
        )
      )
  })

const initialValues: RegisterFormValues = {
  [RegisterField.SharpID]: '',
  [RegisterField.Email]: '',
  [RegisterField.TermsAndConditions]: false,
  [RegisterField.Password]: '',
  [RegisterField.PasswordConfirmation]: ''
}

const initialErrors = {
  [RegisterField.SharpID]: '',
  [RegisterField.Email]: '',
  [RegisterField.TermsAndConditions]: '',
  [RegisterField.Password]: '',
  [RegisterField.PasswordConfirmation]: ''
}

function Register (): ReactElement {
  const intl = useIntl()
  const validationSchema = useMemo(() => getValidationSchema(intl), [intl])
  const sending = useSelector(getSending)

  const [showingPassword, setShowingPassword] = useState(false)
  const toggleShowPassword = useCallback(() => {
    setShowingPassword(!showingPassword)
  }, [showingPassword])

  const passwordRef = useRef<HTMLInputElement>(null)

  // Modal Refs
  const termsRef = useRef(null)

  const stepElements: ReactNode[] = [
    // First Step
    <Form autoComplete="off" noValidate key="form-register">
      {/* Modals */}
      <TermsModal buttonRef={termsRef} />

      {/* Formik Helpers */}
      <TranslateFormikErrors />

      {/* Sharp ID */}
      <FormTextField
        name={RegisterField.SharpID}
        type="text"
        label={intl.formatMessage({
          id: TranslationKey.SHARP_ID
        })}
        placeholder={intl.formatMessage({
          id: TranslationKey.SHARP_ID_PLACEHOLDER
        })}
        disabled={sending}
        required
      />

      {/* Email */}
      <FormTextField
        name={RegisterField.Email}
        label={intl.formatMessage({
          id: TranslationKey.EMAIL
        })}
        placeholder={intl.formatMessage({
          id: TranslationKey.EMAIL_PLACEHOLDER
        })}
        disabled={sending}
        required
      />

      <FormCheckboxField
        name={RegisterField.TermsAndConditions}
        label={
          <button ref={termsRef} type="button" className="link-button">
            <FormattedMessage id={TranslationKey.TERMS_AND_CONDITIONS} />
          </button>
        }
        disabled={sending}
      />

      <div className={styles.buttonContainer}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={sending}
        >
          <FormattedMessage id={TranslationKey.CONTINUE} />
        </Button>

        <div className={styles.linksContainer}>
          <Link to="/session/login">
            <FormattedMessage id={TranslationKey.HAVE_ACCOUNT} />
          </Link>
        </div>
      </div>
    </Form>,
    // Second Step
    <Form autoComplete="off" noValidate key="form-register-autocomplete">
      <TranslateFormikErrors />

      {/* Password */}
      <FormTextField
        inputRef={passwordRef}
        autoFocus
        name="password"
        label={intl.formatMessage({
          id: TranslationKey.NEW_PWD
        })}
        placeholder={intl.formatMessage({
          id: TranslationKey.NEW_PWD_PLACEHOLDER
        })}
        type={showingPassword ? 'text' : 'password'}
        endElement={
          <IconButton
            className={styles.formInputButton}
            onClick={toggleShowPassword}
            tabIndex={-1}
          >
            {showingPassword
              ? (
              <VisibilityIcon color="inherit" />
                )
              : (
              <VisibilityOffIcon color="inherit" />
                )}
          </IconButton>
        }
        disabled={sending}
        required
      />

      {/* Repeat Password */}
      <FormTextField
        name={RegisterField.PasswordConfirmation}
        label={intl.formatMessage({
          id: TranslationKey.REPEAT_NEW_PWD
        })}
        placeholder={intl.formatMessage({
          id: TranslationKey.REPEAT_NEW_PWD
        })}
        type={showingPassword ? 'text' : 'password'}
        endElement={
          <IconButton
            className={styles.formInputButton}
            onClick={toggleShowPassword}
            tabIndex={-1}
          >
            {showingPassword
              ? (
              <VisibilityIcon color="inherit" />
                )
              : (
              <VisibilityOffIcon color="inherit" />
                )}
          </IconButton>
        }
        disabled={sending}
        required
      />

      <div className={styles.buttonContainer}>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          disabled={sending}
        >
          <FormattedMessage id={TranslationKey.SIGN_UP} />
        </Button>

        {sending && (
          <div className={styles.spinnerContainer}>
            <LoadingSpinner />
          </div>
        )}

        <div className={styles.linksContainer}>
          <Link to="/session/login">
            <FormattedMessage id={TranslationKey.HAVE_ACCOUNT} />
          </Link>
        </div>
      </div>
    </Form>
  ]

  const [formStep, setFormStep] = useState(0)
  const isLastStep = stepElements.length === formStep + 1

  const handleValidation = useCallback(
    async (values: RegisterFormValues) =>
      await validationSchema
        .validate(values, {
          abortEarly: false
        })
        .then(() => ({}))
        .catch(({ inner }: yup.ValidationError) => {
          const totalErrors = inner.reduce(
            (prev, { path, message }) => ({
              ...prev,
              [path ?? '']: message
            }),
            {}
          )
          return isLastStep
            ? totalErrors
            : _pick(totalErrors, [
              RegisterField.SharpID,
              RegisterField.Email,
              RegisterField.TermsAndConditions
            ])
        }),
    [isLastStep, validationSchema]
  )

  const dispatch = useDispatch()
  const handleSubmit = useCallback(
    (values: RegisterFormValues) => {
      if (isLastStep) {
        dispatch(
          createUserRequest({
            sharp: values[RegisterField.SharpID] as number,
            email: values.email,
            password: values.password
          })
        )
      } else {
        setFormStep((step) => step + 1)
        if (passwordRef.current != null) {
          passwordRef.current.focus()
        }
      }
    },
    [dispatch, isLastStep]
  )

  return (
    <Formik
        enableReinitialize
        initialValues={initialValues}
        initialErrors={initialErrors}
        validate={handleValidation}
        onSubmit={handleSubmit}
        autoComplete="off"
      >
        {stepElements[formStep] ?? null}
      </Formik>
  )
}

export default Register
