import { mapBackendPublicURL } from '../../helpers/util-functions'
import { type RawUser, type User } from '../../types'

export const mapRawUser = (rawUser: RawUser): User => ({
  id: rawUser.id,
  name: rawUser.collaborator.name,
  sharp: rawUser.collaborator.sharp,
  email: rawUser.email,
  position: rawUser.collaborator.position ?? '',
  role: rawUser.role,
  vp: rawUser.collaborator.vp,
  businessUnit: rawUser.collaborator.bu,
  country: rawUser.collaborator.country,
  band: rawUser.collaborator.band,
  uen: rawUser.collaborator.uen,
  lastPasswordChange: new Date(rawUser.last_PWD_change),
  hiringDate: new Date(rawUser.collaborator.hiring_date),
  avatar: mapBackendPublicURL(rawUser.avatar),
  nickname: rawUser.nickname,
  gender: rawUser.collaborator.gendre,
  homeCoutry: rawUser.collaborator.home_country
})
