import { useState,  useEffect } from 'react'
import {
  Container,
  Typography,
  Grid,
  FormControl,
  Select,
  MenuItem,
  Button,
  Box,
  CircularProgress, TextField, InputAdornment
} from '@material-ui/core'
import { useSelector } from 'react-redux'
import { getUser } from '../../userSession/slice'
import styles from './Payroll-admin.module.scss'
import { useFormik } from 'formik'
import * as Yup from 'yup'
import { format, getYear, setMonth, subYears } from 'date-fns'
import { map } from 'rxjs/operators'
import { toast } from 'react-toastify'
import { TranslationKey } from '../../../i18n/translations'
import SearchIcon from '@material-ui/icons/Search'
import { DownloadPayrollAllData, StartDownloadPayrollAllData, fetchFilterData, fetchPayrollPreview } from '../../../api/payroll'
import intlHelper from '../../../i18n/intlHelper'
import { logger } from '../../../services'
import toastConfig from '../../../config/toast'
import { MultiSelect } from '../../../components/MultiSelectCheckbox/MultiSelect'
import { DatePicker, PickersDay, LocalizationProvider } from '@mui/x-date-pickers'
import { styled } from '@mui/material/styles'
import {es} from 'date-fns/locale'
import { AdapterDateFns } from '@mui/x-date-pickers/AdapterDateFns'
const StyledDay = styled(PickersDay)(({ theme }) => ({
  borderRadius: theme.shape.borderRadius,
  color: 'secondary',
  '&.Mui-selected': {
    backgroundColor: '#00A29A'
  },  
  '&.Mui-selected:not(.Mui-focused)': {
    backgroundColor: '#00A29A'
  },
  '&.Mui-selected, &.Mui-selected.Mui-focused': {
    backgroundColor: '#00A29A'
  }
}))
interface formTicketValues {
  from_date: Date
  until_date: Date
}

interface CustomRequest {
  range: string[]
  country: string[]
  company: string[]
  location: string[]
  uen: string[]
}
interface DateSelected {
  from_date: Date
  until_date: Date
}
interface FormularioValues {
  from_date: Date
  until_date: Date
  range: {
    week: string
    value: number
  }
  sharp: string
}
export const PayrollAdmin: React.FC = () => {
  const intl = intlHelper.getIntl()
  const user = useSelector(getUser)
  const [countries, setCountries] = useState<string[]>([])
  const [companies, setCompanies] = useState<string[]>([])
  const [locations, setLocations] = useState<string[]>([])
  const [clearFilters, setClearFilters] = useState<boolean>(false)
  const [uens, setUens] = useState<string[]>([])
  const [request, setRequest] = useState<CustomRequest>({ range: [], country: [], company: [], location: [], uen: [] })
  const [formularioValues, setFormularioValues] = useState<FormularioValues | null>(null)
  const [sizePayrolls, setSizePayrolls] = useState<number | null>(null)
  const [successMessage, setSuccessMessage] = useState<boolean>(false)
  const [loadingDown, setLoadingDown] = useState<boolean>(false)
  const maxRetries = 20
  const retryInterval = 5000
  const minDate = setMonth(new Date(2023, 7), 7)
  let cont = 0
  useEffect(() => {
    const fetchData = (): void => {
      void getFilters('country', JSON.stringify({
        filter_name: 'country',
        filters: {}
      }))
    }
    fetchData()
  }, [])

  const  isFiveWeek=(year:number, month:number):boolean => {
    const firstDayOfNextMonth = new Date(year, month + 1, 1)
    // Restar un día (en milisegundos) para obtener el último día del mes actual
    const lastDayOfMonth = new Date(firstDayOfNextMonth.getTime() - 86400000).getDate()
    return lastDayOfMonth>28
  }
  const handleClearFilters = (): void => {
    setClearFilters(true) // Alternar entre true y false para evitar el bucle infinito
    setRequest({ range: [], country: [], company: [], location: [], uen: [] })

    setTimeout(() => {
      setClearFilters(false)
    }, 10)
  }
  useEffect(() => {
    setLoadingDown(true)
    setTimeout(() => {
      setLoadingDown(false)
    }, 1000)
  }, [sizePayrolls])
  useEffect(() => {

  }, [countries, companies, locations, uens, successMessage])
  if (user?.country === 'PE') {
    window.location.assign('https://www.turecibo.com')
  }

  // const closeModal = (): void => {
  //   setOpenModal(false)
  // }

  // const handleOpenModal = (): void => {
  //   setOpenModal(true)
  // }
  const formik = useFormik({
    initialValues: {
      from_date: new Date(),
      until_date: new Date(),
      range: { week: 'Semana 1', value: 1 },
      sharp: ''
    },
    validationSchema: Yup.object({
      from_date: Yup.date().required(),
      until_date: Yup.date().required(),
      range: Yup.object().required()
    }),
    onSubmit: (values: FormularioValues) => {
      setFormularioValues(values)
      handleGetPayrolls(values)
    }
  })

  const handleGetPayrolls = (values: FormularioValues): void => {
    setSuccessMessage(false)
    setRequest({ ...request })
    const sendDate = converterRequest(request, values)
    setSizePayrolls(null)
    setLoadingDown(true)
    fetchPayrollPreview(JSON.stringify(sendDate))
      .pipe(map(({ response }) => response))
      .subscribe(
        (payroll) => {
          logger.info('fetching payroll', Number(payroll))
          setSizePayrolls(Number(payroll))
          setLoadingDown(false)
        },
        (err) => {
          logger.error('Error fetching payroll', err)
          setLoadingDown(false)
          setSizePayrolls(0)
          toast.error('No se han encontrado recibos para mostrar. Por favor, realiza otra consulta.', toastConfig
          )
        }
      )
  }
  function converterRequest(request: any, values: FormularioValues): any {
    const fromDate = format(values.from_date, 'dd-MM-yyy')
    const untilDate = format(values.until_date, 'dd-MM-yyy')
    const sendDate = {
      from_date: fromDate,
      until_date: untilDate,
      filters: {
        ...(request.country.length > 0 && { country: request.country }),
        ...(request.company.length > 0 && { company_name: request.company }),
        ...(request.location.length > 0 && { employee_location: request.location }),
        ...(request.uen.length > 0 && { uen: request.uen }),
        ...(values.sharp.length > 0 && { sharp_id: [values.sharp]})
      }
    }
    return sendDate
  }
  
  const getFilters = async (type: string, requestData: string): Promise<void> => {
    fetchFilterData(JSON.parse(requestData))
      .pipe(map(({ response }) => response))
      .subscribe(
        (element) => {
          let arrayCompanies: string[] = []
          let arrayLocations: string[] = []
          let arrayUens: string[] = []
          switch (type) {
            case 'country':
              setCountries([])
              element?.forEach((country: any) => {
                if (country && country !== '' && !countries.includes(country)) {
                  setCountries((prevCountries) => [...prevCountries, country])
                }
              })
              break
            case 'company':
              arrayCompanies = []
              setCompanies([])
              element?.forEach((companie: any) => {
                if (companie && !arrayCompanies.includes(companie)) {
                  arrayCompanies.push(companie)
                }
              })
              setCompanies(arrayCompanies)
              break
            case 'location':
              arrayLocations = []
              setLocations([])
              element?.forEach((location: any) => {
                if (location && location !== '' && !arrayLocations.includes(location)) {
                  arrayLocations.push(location)
                }
              })
              setLocations(arrayLocations)
              break
            case 'uen':
              arrayUens = []
              setUens([])
              element?.forEach((uen: any) => {
                if (uen && uen !== '' && !arrayUens.includes(uen)) {
                  arrayUens.push(uen)
                }
              })
              setUens(arrayUens)
              break
            default:
              break
          }
        },
        (err) => {
          logger.error('Error fetching payroll', err)
        }
      )
  }

  const StartDownloadPayroll = async (): Promise<void> => {
    cont = 0
    try {
      setSuccessMessage(false)
      if(formularioValues)
      {
        const sendDate = converterRequest(request, formularioValues)
        const result = await StartDownloadPayrollAllData(JSON.stringify(sendDate)).toPromise()
        if (result?.response) {
          const { response } = result
          if (response.success) {
            toast.success('Procesando la descarga de tus recibos. Recibirás un correo con un archivo ZIP.', toastConfig)
            setSuccessMessage(true)
          }
        }
      }
    } catch (error: any) {
      setSuccessMessage(false)
      if (error.status === 422) {
        toast.error('Error 422: Entrada no válida. Por favor, verifica tus datos.', toastConfig)
      } else if (error.status === 404) {
        toast.error('Error 404: No se encontraron nóminas con estos filtros.', toastConfig)
      } else {
        toast.error('Hubo un error inesperado. Por favor, inténtalo de nuevo.', toastConfig)
      }
    }
  }

  const handleRetry = async (id: string): Promise<void> => {
    console.log('Valor actual de retryCount:', cont)
    if (cont < maxRetries) {
      cont++
      setTimeout(async () => {
        handleDownloadPayroll(id)
      }, retryInterval)
    } else {
      handleError('Error downloading payroll after retries')
    }
  }

  const handleError = (errorMessage: string, error?: any): void => {
    setLoadingDown(false)
    logger.error(errorMessage, error)
    toast.error(errorMessage, toastConfig)
  }

  const handleDownloadPayroll = (id: string): void => {
    setLoadingDown(true)
    DownloadPayrollAllData(id).subscribe(
      async ({ response }) => {
        console.log('Response:  ', response)
        if (response?.data?.length === 0) {
          if (response.message === 'File still processing') {
            logger.error('Se está procesando...')
            await handleRetry(id)
          }
          else if (response.message === 'File already downloaded') {
            const downloadDate: string = response?.data?.downloadDate ? format(new Date(response?.data?.downloadDate), 'dd-MM-yyyy') : ''
            logger.error('El archivo ya fué descargado ', downloadDate)
            toast.error(
              intl?.formatMessage({
                id: `El archivo ya fue descargado el ${downloadDate}`
              }),
              toastConfig
            )
          }
        }
        else if (response) {
          logger.error('Viene el archivo directamente ', response)
          const fileURL = window.URL.createObjectURL(response)
          const anchorElem = document.createElement('a')
          anchorElem.href = fileURL
          anchorElem.target = '_blank'
          anchorElem.download = 'payroll.zip'
          anchorElem.style.display = 'none'
          document.body.appendChild(anchorElem)
          anchorElem.click()
          setTimeout(() => {
            document.body.removeChild(anchorElem)
            window.URL.revokeObjectURL(fileURL)
            setLoadingDown(false)
          }, 0)
          setLoadingDown(false)
        }
      },
      (err) => {
        setLoadingDown(false)
        logger.error('Error downloading payroll', err)
        toast.error(
          intl?.formatMessage({
            id: TranslationKey.ERROR_PAYROLL
          }),
          toastConfig
        )
      }
    )
  }

  const handleSelectionChangeRange = (selectedItems: any[]): void => {
    console.log('Quinta semana? :  ', selectedItems?.includes(5))
    setSizePayrolls(null)
    setSuccessMessage(false)
    setRequest({ ...request, range: selectedItems })
  }
  const handleSelectionChangeCountry = async (selectedItems: any): Promise<void> => {
    setSizePayrolls(null)
    setSuccessMessage(false)
    await getFilters('company', JSON.stringify({
      filter_name: 'company_name',
      filters: { country: selectedItems }
    }))
    selectedItems.length > 0 ? setRequest({ ...request, country: selectedItems }) : setRequest({ ...request, country: [], company: [], location: [], uen: [] })
  }
  const handleSelectionChangeCompany = async (selectedItems: any): Promise<void> => {
    setSuccessMessage(false)
    setSizePayrolls(null)
    await getFilters('location', JSON.stringify({
      filter_name: 'employee_location',
      filters: { country: countries, ...(selectedItems.length > 0 && { company_name: selectedItems }) }
    }))
    selectedItems.length > 0 ? setRequest({ ...request, company: selectedItems }) : setRequest({ ...request, company: [], location: [], uen: [] })
  }
  const handleSelectionChangeLocation = async (selectedItems: any): Promise<void> => {
    setSuccessMessage(false)
    setSizePayrolls(null)
    await getFilters('uen', JSON.stringify({
      filter_name: 'uen',
      filters: { country: countries, company_name: companies, ...(selectedItems.length > 0 && { employee_location: selectedItems }) }
    }))
    setRequest({ ...request, location: selectedItems })
    selectedItems.length > 0 ? setRequest({ ...request, location: selectedItems }) : setRequest({ ...request, location: [], uen: [] })
  }
  const handleSelectionChangeUen = async (selectedItems: any): Promise<void> => {
    setSuccessMessage(false)
    setSizePayrolls(null)
    selectedItems.length > 0 ? setRequest({ ...request, uen: selectedItems }) : setRequest({ ...request, uen: [] })
  }

  return (
    <>
      <LocalizationProvider dateAdapter={AdapterDateFns} adapterLocale={es} >
      <Container className={styles.pt2}>
        <div className={`${styles.boxpayroll} ${styles.p2} ${styles.mb2}`}>
          <form onSubmit={formik.handleSubmit}>
            <Grid container spacing={2}>
              <Grid item xs={12}>
                <p>
                  Haz el filtrado necesario para que puedas descargar los recibos de nómina de manera masiva.
                </p>
              </Grid>
              <Grid item xs={6}>
                <Typography variant="subtitle1">
                </Typography>
              </Grid>
              <Grid item xs={6}>
                <div className={`${styles.containerInputs}`}>
                  <div className={`${styles.item4} ${styles.vcenter}`} onClick={handleClearFilters}>

                    Limpiar Filtros

                  </div>
                  <div className={`${styles.itemSearch} ${styles.btnsearch}`}>
                    <Typography variant="subtitle1">
                      <TextField
                        id="input-with-icon-textfield"
                        placeholder="Buscar por ID de Empleado"
                        label=""
                        name="sharp"  // Asocia el campo al campo 'employeeId' de initialValues
                        value={formik.values.sharp}
                        onChange={formik.handleChange}
                        InputProps={{
                          disableUnderline: true,
                          startAdornment: (
                            <InputAdornment position="start" className={`${styles.MuiInputLabel} noUnderline`}>
                              <SearchIcon />

                            </InputAdornment>
                          ),
                          classes: {
                            input: styles.placeholderText // Agrega una clase para el estilo del placeholder
                          }
                        }}
                      />
                    </Typography>
                  </div>
                  <div className={`${styles.itemConsultar}`}>
                    <Button
                      variant="contained"
                      color="primary"
                      type="submit"
                      style={{ color: '#00A29A' }}
                    >
                      Consultar recibos
                    </Button>
                  </div>
                </div>
              </Grid>
              <div className={`${styles.filterContainer}`}>
                <div className={`${styles.item5}`}>
                 
                  <FormControl className={styles.formControl} fullWidth>
                    <Typography variant="caption" display="block" gutterBottom>
                      Fecha inicial
                    </Typography>
                 
                  <DatePicker  
                  name="from_date"
                  value={formik.values.from_date}
                  onChange={(date) => {
                    formik.setFieldValue('from_date', date).finally(() => {})
                    return undefined
                  }}
                  slots={{
                    day: StyledDay
                  }}
                  minDate={minDate}
                  maxDate={new Date()}
                  />
                  </FormControl>
                </div>
                <div className={`${styles.item5}`}>

                <FormControl className={styles.formControl} fullWidth>
                    <Typography variant="caption" display="block" gutterBottom>
                      Fecha Final
                    </Typography>
                 
                  <DatePicker  
                  name="until_date"
                  value={formik.values.until_date}
                  localeText={{
                    clearButtonLabel: 'Spanish'
                  }}
                  onChange={(date) => { 
                    formik.setFieldValue('until_date', date).finally(() => {})
                    return undefined
                  }}
                  slots={{
                    day: StyledDay
                  }}
                  minDate={minDate}
                  maxDate={new Date()}
                  />
                  </FormControl>
                </div>
                <div className={`${styles.item5}`}>
                  <MultiSelect label="País" onSelectionChange={handleSelectionChangeCountry} items={countries} clearSelection={clearFilters} />
                </div>
                <div className={`${styles.item5}`}>
                  <MultiSelect label="Compañía" onSelectionChange={handleSelectionChangeCompany} items={companies} disabled={request?.country?.length < 1} clearSelection={clearFilters} />
                </div>
                <div className={`${styles.item5}`}>
                  <MultiSelect label="Ubicación" onSelectionChange={handleSelectionChangeLocation} items={locations} disabled={request?.country?.length < 1 && request?.company?.length < 1} clearSelection={clearFilters} />
                </div>
                <div className={`${styles.item5}`}>
                  <MultiSelect label="Personal subárea" onSelectionChange={handleSelectionChangeUen} items={uens} disabled={request?.country?.length < 1 && request?.company?.length < 1 && request?.location?.length < 1} clearSelection={clearFilters} />
                </div>
              </div>
              <Grid container justifyContent="center">
                <Button
                  variant="contained"
                  color="primary"
                  style={{ color: '#000', margin: '20px 0' }}
                  disabled={sizePayrolls === null || sizePayrolls === 0 || loadingDown}
                  onClick={StartDownloadPayroll}>
                  Descargar recibos
                </Button>
              </Grid>
              {
                sizePayrolls && sizePayrolls > 0
                  ? (
                    <Box width="100%">
                      <Typography variant="subtitle2" align="center">
                        Existen {sizePayrolls} recibos para descargar
                      </Typography>
                    </Box>
                  )
                  : (
                    <Box width="100%" paddingBottom="1rem">
                      {sizePayrolls != null && (
                        <Typography variant="subtitle2" align="center">
                          No hay recibos para mostrar. Verifica los criterios de búsqueda o prueba con diferentes parámetros.
                        </Typography>
                      )}
                    </Box>
                  )
              }
              {successMessage && (
                <Box width="100%" paddingBottom="1rem" paddingTop="1rem">
                  <Typography variant="subtitle2" align="center">
                    Estamos trabajando en la descarga de tus recibos, este proceso puede demorar un poco.
                  </Typography>
                  <Typography variant="subtitle2" align="center">
                    Pronto recibirás un correo con un archivo adjunto en formato ZIP que contiene la información solicitada.
                  </Typography>
                </Box>
              )
              }
              {loadingDown && (
                <div className={`${styles.m_auto}`}>
                  <CircularProgress color="primary" thickness={5} size="1.5em" />
                </div>
              )}
              <Box width="100%" textAlign="center" paddingTop="2rem">
                <Typography
                  variant="subtitle2"
                  className={styles.btnEmail}
                >
                  ¿Necesitas ayuda?
                </Typography>
                <Typography
                  variant="subtitle2"
                  align="center"
                  style={{ paddingTop: '7px' }}
                >
                  Puedes comunicarte con soporte
                </Typography>
                <Typography
                  variant="subtitle2"
                  className={styles.btnEmail}
                >
                  haciendo click <a href="https://soporte.somosmaz.com/pqr/compensaci%C3%B3n" target="_blank" rel="noopener noreferrer" className={styles.link_color}>aquí</a>
                </Typography>
              </Box>
            </Grid>
          </form>
        </div>
        {/* <ModalTicket openModal={openModal} modalClose={closeModal} /> */}
      </Container>
      </LocalizationProvider>
    </>
  )
}

// export default function DateComponent({ onDateSelect, label }: { onDateSelect: Function, label: string }): JSX.Element {
//   const [value, setValue] = useState<Date | null>(null);
//   useEffect(() => {
//     if(value)
//       onDateSelect(value)
//     console.log("Regresando..",value)
//   }, [value]);
//   return (
//     <DatePicker label={label}  onChange={(newValue) => setValue(new Date(String(newValue)))}/>
//   )
// }
