import {
  Button,
  Container,
  Grid,
  InputAdornment,
  TextField,
  Typography
} from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import { AttachMoney, Help } from '@material-ui/icons'
import React, { type ChangeEvent, Fragment, useCallback, useRef } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import botella from '../../../assets/images/Botella_Compensacion.svg'
import { useCurrencyFormatter } from '../../../helpers/hooks'
import { TranslationKey } from '../../../i18n/translations'
import { TipHelpModal } from '../HelpModals'
import {
  getAnnualSalary,
  getBonus,
  getMappedCompensations,
  getMonthlySalary,
  getStaticBonusTotal,
  setMonthlySalary
} from '../slice'
import styles from './InitPanel.module.scss'

function InitPanel (): JSX.Element {
  const totalRef = useRef(null)
  const dispatch = useDispatch()

  const annualSalary = useSelector(getAnnualSalary)
  const monthlySalary = useSelector(getMonthlySalary)
  const currencyFormatter = useCurrencyFormatter()
  const mappedCompensations = useSelector(getMappedCompensations)
  const hasBonus = !(useSelector(getBonus) == null)
  const bonusTotal = useSelector(getStaticBonusTotal)

  const handleMonthlySalaryChange = useCallback(
    (event: ChangeEvent<HTMLInputElement>) => {
      dispatch(
        setMonthlySalary({
          monthlySalary: Number(event.target.value)
        })
      )
    },
    [dispatch]
  )

  return (
    <>
      <div className={styles.banner}>
        <Container maxWidth="lg">
          <Typography className={styles.bannerTitle}>
            <FormattedMessage id={TranslationKey.MY_COMPENSATION} />
          </Typography>
          <Typography className={styles.bannerDescription}>
            <FormattedMessage id={TranslationKey.COMPENSATION_P} />
          </Typography>
        </Container>
      </div>
      <div className={styles.mainContent}>
        <Container maxWidth="md">
          <Grid container spacing={3}>
            <Grid item xs={12} md={6} style={{ textAlign: 'center' }}>
              <img src={botella} alt="botella" className={styles.bottle} />
            </Grid>
            <Grid item xs={12} md={6}>
              <div className={styles.card}>
                <TipHelpModal
                  buttonRef={totalRef}
                  title={<FormattedMessage id={TranslationKey.ANNUAL_TOTAL} />}
                  text={
                    <FormattedMessage id={TranslationKey.ANNUAL_TOTAL_HELP} />
                  }
                />
                <div className={styles.cardTotal}>
                  <Help
                    className={styles.cardTotalIcon}
                    ref={totalRef}
                    type="button"
                  />
                  <Typography className={styles.cardTotalTitle}>
                    <FormattedMessage id={TranslationKey.ANNUAL_TOTAL} />
                  </Typography>
                  <Typography className={styles.cardTotalValue}>
                    {monthlySalary != null
                      ? currencyFormatter.format(annualSalary)
                      : currencyFormatter
                        .format(0)
                        .replace('0,00', ' -')
                        .replace('0.00', ' -')}
                  </Typography>
                </div>
                <p className={styles.compSalaryText}>
                  <FormattedMessage id={TranslationKey.COMPENSATION_ITEM1} />
                </p>
                <TextField
                  id="outlined-basic"
                  fullWidth
                  label={
                    <FormattedMessage id={TranslationKey.MONTHLY_SALARY_BASE} />
                  }
                  variant="outlined"
                  InputProps={{
                    startAdornment: (
                      <InputAdornment position="start">
                        <AttachMoney />
                      </InputAdornment>
                    )
                  }}
                  placeholder="0.00"
                  value={monthlySalary ?? ''}
                  onChange={handleMonthlySalaryChange}
                />
                {mappedCompensations.map((compensation, index) => {
                  const buttonId = `compensation-tooltip-${index}`
                  return (
                    <Fragment key={compensation.name}>
                      <hr className={styles.separator} />
                      <div className={styles.cardGroup}>
                        <div>
                          <span
                            className={`${styles.bullet} ${
                              styles[`color${index + 2}`]
                            }`}
                          />
                          <Typography variant="subtitle1">
                            {compensation.name}
                          </Typography>
                        </div>
                        <div>
                          {compensation.formula !== null && (
                            <Typography variant="subtitle1" color="primary">
                              {currencyFormatter.format(compensation.value)}
                            </Typography>
                          )}
                          {compensation.help !== null && (
                            <>
                              <IconButton
                                id={buttonId}
                                size="small"
                                className={styles.helpButton}
                              >
                                <Help fontSize="small" />
                              </IconButton>
                              <TipHelpModal
                                buttonId={buttonId}
                                title={compensation.name}
                                text={compensation.help}
                              />
                            </>
                          )}
                        </div>
                      </div>

                      {compensation.items?.map((item) => (
                        <Fragment key={item.name}>
                          <hr className={styles.separator} />
                          <div className={styles.subGroup}>
                            <Typography variant="body2">{item.name}</Typography>
                            <Typography variant="body2">
                              {currencyFormatter.format(item.value)}
                            </Typography>
                          </div>
                        </Fragment>
                      ))}
                    </Fragment>
                  )
                })}

                {hasBonus && (
                  <>
                    <hr className={styles.separator} />
                    <div className={styles.cardGroup}>
                      <div>
                        <span
                          className={`${styles.bullet} ${
                            styles[`color${3 + mappedCompensations.length}`]
                          }`}
                        />
                        <Link to="/compensation/bonus">
                          <Button variant="contained" color="primary">
                            <FormattedMessage
                              id={TranslationKey.CALCULATE_BONUS}
                            />
                          </Button>
                        </Link>
                      </div>
                      <div>
                        <Typography variant="subtitle1" color="primary">
                          {currencyFormatter.format(bonusTotal)}
                        </Typography>
                      </div>
                    </div>
                  </>
                )}
                <br />
                <hr />
                <Typography variant="body2" className={styles.disclamer}>
                  <FormattedMessage
                    id={TranslationKey.COMPENSATION_DISCLAIMER}
                  />
                </Typography>
              </div>
            </Grid>
          </Grid>
        </Container>
      </div>
    </>
  )
}
export default InitPanel
