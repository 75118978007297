import {
  Button,
  Grid,
  IconButton,
  Slider,
  Typography
} from '@material-ui/core'
import { Help as HelpIcon } from '@material-ui/icons'
import React, { type ReactElement, useCallback, useEffect } from 'react'
import { FormattedMessage } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import { Link } from 'react-router-dom'
import { percentageFormatter } from '../../../helpers/util-functions'
import { TranslationKey } from '../../../i18n/translations'
import { TipHelpModal } from '../HelpModals'
import {
  getBonus,
  getDiscretionaryIncentive,
  getEntityPerform,
  getIndividualAchievement,
  getIndividualTarget,
  getSizeOfPie,
  getTotalAchievementScore,
  setDiscretionaryIncentive,
  setEntityPerform,
  setIndividualAchievement,
  setIndividualTarget,
  setSizeOfPie
} from '../slice'
import styles from './Compensation.module.scss'

function ThirdPanel (): ReactElement {
  const dispatch = useDispatch()

  const bonus = useSelector(getBonus)

  const compensationInfo = bonus?.complements[0]
  const { title, variables = [] }: any = (compensationInfo != null) || {}

  const totalAchievementScore = useSelector(getTotalAchievementScore)

  const sizeOfThePieInfo = variables?.filter(
    (data: any) => data.representation === 'sop'
  )[0]

  const entityPerformanceInfo = variables?.filter(
    (data: any) => data.representation === 'ep'
  )[0]

  const individualTargetInfo = variables?.filter(
    (data: any) => data.representation === 'it'
  )[0]

  const individualAchievementInfo = variables?.filter(
    (data: any) => data.representation === 'ip'
  )[0]

  const discretionaryIncentiveInfo = variables?.filter(
    (data: any) => data.representation === 'di'
  )[0]

  const updateSizeOfThePie = useCallback(
    (dataValue: number) => {
      if (typeof sizeOfThePieInfo?.value === 'object') {
        dispatch(
          setSizeOfPie({
            sizeOfPie: dataValue
          })
        )
      }
    },
    [sizeOfThePieInfo, dispatch]
  )

  const updateEntityPerformance = useCallback(
    (dataValue: number) => {
      if (typeof entityPerformanceInfo?.value === 'object') {
        dispatch(
          setEntityPerform({
            entityPerform: dataValue
          })
        )
      }
    },
    [entityPerformanceInfo, dispatch]
  )

  const updateIndividualTarget = useCallback(
    (dataValue: number) => {
      if (typeof individualTargetInfo?.value === 'object') {
        dispatch(
          setIndividualTarget({
            individualTarget: dataValue
          })
        )
      }
    },
    [individualTargetInfo, dispatch]
  )

  const updateIndividualAchievement = useCallback(
    (dataValue: number) => {
      if (typeof individualAchievementInfo?.value === 'object') {
        dispatch(
          setIndividualAchievement({
            individualAchievement: dataValue
          })
        )
      }
    },
    [individualAchievementInfo, dispatch]
  )

  const updateDiscretionaryIncentive = useCallback(
    (dataValue: number) => {
      if (typeof discretionaryIncentiveInfo?.value === 'object') {
        dispatch(
          setDiscretionaryIncentive({
            discretionaryIncentive: dataValue
          })
        )
      }
    },
    [discretionaryIncentiveInfo, dispatch]
  )

  useEffect(() => {
    updateSizeOfThePie(
      sizeOfThePieInfo?.value[sizeOfThePieInfo?.value.length - 1]
    )
    updateEntityPerformance(
      entityPerformanceInfo?.value[entityPerformanceInfo?.value.length - 1]
    )
    updateIndividualTarget(
      individualTargetInfo?.value[individualTargetInfo?.value.length - 1]
    )
    updateIndividualAchievement(
      individualAchievementInfo?.value[
        individualAchievementInfo?.value.length - 1
      ]
    )
    updateDiscretionaryIncentive(discretionaryIncentiveInfo?.value[0])
  }, [
    updateSizeOfThePie,
    sizeOfThePieInfo,
    updateEntityPerformance,
    entityPerformanceInfo,
    updateIndividualTarget,
    individualTargetInfo,
    updateIndividualAchievement,
    individualAchievementInfo,
    updateDiscretionaryIncentive,
    discretionaryIncentiveInfo
  ])

  const sizeOfPie = useSelector(getSizeOfPie)
  const entityPerformance = useSelector(getEntityPerform)
  const individualTarget = useSelector(getIndividualTarget)
  const individualAchievement = useSelector(getIndividualAchievement)
  const discretionaryIncentive = useSelector(getDiscretionaryIncentive)

  const getMarksByRepresentation = (marks: number | Array<string | number>): any =>
    typeof marks === 'object' &&
    marks.map((label) => ({
      label,
      value: label as number
    }))

  const getValueByRepresentation = (
    representation: string,
    marks: number | Array<string | number>
  ): any => {
    const marksInfo = getMarksByRepresentation(marks)
    const markMin = marksInfo !== null ? marksInfo[0] : null
    const markMax = marksInfo !== null ? marksInfo[marksInfo.length - 1] : null

    const min = (markMin != null) ? markMin.value : 0
    const max = (markMax != null) ? markMax.value : 0
    switch (representation) {
      case 'sop':
        return {
          value: sizeOfPie,
          marks: marksInfo,
          min,
          max
        }

      case 'ep':
        return {
          value: entityPerformance,
          marks: marksInfo,
          min,
          max
        }

      case 'it':
        return {
          value: individualTarget,
          marks: marksInfo,
          min,
          max
        }

      case 'ip':
        return {
          value: individualAchievement,
          marks: marksInfo,
          min,
          max
        }

      case 'di':
        return {
          value: discretionaryIncentive,
          marks: marksInfo,
          min,
          max
        }

      default:
        return {
          value: 0,
          marks: [{ label: '0', value: 0 }],
          min,
          max
        }
    }
  }

  const handleSliderDataChange = (
    dataValue: number,
    representation: string
  ): void => {
    switch (representation) {
      case 'sop':
        updateSizeOfThePie(dataValue)
        break
      case 'ep':
        updateEntityPerformance(dataValue)
        break
      case 'it':
        updateIndividualTarget(dataValue)
        break
      case 'ip':
        updateIndividualAchievement(dataValue)
        break
      case 'di':
        updateDiscretionaryIncentive(dataValue)
        break

      default:
        break
    }
  }
  return (
    <>
      <Grid item xs={12} sm={6} md={6} lg={5} className={styles.panelContainer}>
        <div className={styles.panelTitle}>
          <Typography variant="subtitle1">{title}</Typography>
          <Typography variant="h4">
            {percentageFormatter(totalAchievementScore)}
          </Typography>
        </div>
        {variables.map((variable: any, index: number) => {
          const buttonId = `compensation-sti-tooltip-${index}`
          const { representation, unit, value, variableType, help } = variable
          const optionStiValue = getValueByRepresentation(
            representation,
            value
          )
          const valueLabelFormat = (indexValue: number): string | number | undefined => {
            const markLabel =
              typeof optionStiValue.marks === 'object'
                ? optionStiValue.marks.find(
                  (option: any) => option.value === indexValue
                )?.label
                : ''
            return markLabel
          }
          return (
            <React.Fragment key={buttonId}>
              <div className={styles.cardGroup}>
                <div>
                  <Typography variant="subtitle1">{variable.title}</Typography>
                </div>
                <div>
                  <Typography variant="subtitle1" color="primary">
                    {optionStiValue.value}
                    {unit}
                  </Typography>
                  <IconButton
                    id={buttonId}
                    size="small"
                    className={styles.helpButton}
                  >
                    <HelpIcon fontSize="small" />
                  </IconButton>
                  <TipHelpModal
                    buttonId={buttonId}
                    title={variable.title}
                    text={help}
                  />
                </div>
              </div>
              {variableType === 'INPUT_COMBO' && (
                <Slider
                  className={styles.slider}
                  color="secondary"
                  valueLabelDisplay="auto"
                  track={false}
                  step={null}
                  marks={optionStiValue.marks}
                  min={optionStiValue.min}
                  max={optionStiValue.max}
                  defaultValue={0}
                  valueLabelFormat={valueLabelFormat}
                  onChange={(_, valueOChange) => {
                    handleSliderDataChange(
                      valueOChange as number,
                      representation
                    )
                  }
                  }
                />
              )}
              {variableType === 'INPUT_RANGE' && (
                <Slider
                  key={optionStiValue?.value}
                  className={styles.slider}
                  color="secondary"
                  valueLabelDisplay="auto"
                  step={1}
                  defaultValue={optionStiValue.value}
                  marks
                  min={optionStiValue.min}
                  max={optionStiValue.max}
                  onChange={(_, valueOChange) => {
                    handleSliderDataChange(
                      valueOChange as number,
                      representation
                    )
                  }
                  }
                />
              )}
              <hr className={styles.separator} />
            </React.Fragment>
          )
        })}
        <div className={styles.cardGroup}>
          <Link to="/compensation/bonus">
            <Button color="primary" variant="contained">
              <FormattedMessage id={TranslationKey.CALCULATE_BONUS} />
            </Button>
          </Link>
        </div>
      </Grid>

      <Grid item xs={false} sm={12} className={styles.disclaimer}>
        <Typography variant="body2">
          <FormattedMessage id={TranslationKey.COMPENSATION_DISCLAIMER} />
        </Typography>
      </Grid>
    </>
  )
}

export default ThirdPanel
