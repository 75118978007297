import { combineEpics, type Epic } from 'redux-observable'
import { adminEpic } from '../modules/admin/slice'
import { benefitsEpic } from '../modules/benefits/slice'
import { compensationEpic } from '../modules/compensation/slice'
import { userSessionEpic } from '../modules/userSession/slice'

// @ts-expect-error Mal argumento
const rootEpic = combineEpics<Epic>(
  adminEpic,
  benefitsEpic,
  userSessionEpic,
  compensationEpic
)

export type RootEpic = ReturnType<typeof rootEpic>

export default rootEpic
