import { Grid, Typography } from '@material-ui/core'
import React, { type FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import styles from './BenefitCategoryCard.module.scss'

interface BenefitCategoryCardProps {
  id: number
  title: string
  logoURL: string
}

const BenefitCategoryCard: FunctionComponent<BenefitCategoryCardProps> = ({
  id,
  title,
  logoURL
}) => (
  <Grid item xs={6} sm={6} md={6} lg={6} className={styles.categoriesContainer}>
    <Link to={`/benefits/categories/${id}`} className={styles.link}>
      <div className={styles.container}>
        <img src={logoURL} alt="Benefit category card logo." />
        <Typography variant="body1">{title}</Typography>
      </div>
    </Link>
  </Grid>
)

export default BenefitCategoryCard
