import { type NumberFormat } from '@formatjs/intl-numberformat/src/types'
import { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { fromEvent } from 'rxjs'
import { delay } from 'rxjs/operators'
import { CountryCode, CountryCurrencyCode } from '../enums'
import {
  getRealBonusCurrency,
  getRealBonusHomeCurrency
} from '../modules/compensation/slice'
import { getUserCountryCode } from '../modules/userSession/slice'

export const usePrevious = <T>(value: T): T | undefined => {
  // The ref object is a generic container whose current property is mutable ...
  // ... and can hold any value, similar to an instance property on a class
  const ref = useRef<T>()

  // Store current value in ref
  useEffect(() => {
    ref.current = value
  }, [value]) // Only re-run if value changes

  // Return previous value (happens before update in useEffect above)
  return ref.current
}

export const useDebounce = <T>(value: T, debounceDelay: number): T => {
  // State and setters for debounced value
  const [debouncedValue, setDebouncedValue] = useState(value)
  useEffect(
    () => {
      // Update debounced value after delay
      const handler = setTimeout(() => {
        setDebouncedValue(value)
      }, debounceDelay)
      // Cancel the timeout if value changes (also on delay change or unmount)
      // This is how we prevent debounced value from updating if value is changed ...
      // .. within the delay period. Timeout gets cleared and restarted.
      return () => {
        clearTimeout(handler)
      }
    },
    [value, debounceDelay] // Only re-call effect if value or delay changes
  )
  return debouncedValue
}

export const useWindowWidth = (): number => {
  const [width, setWidth] = useState(window.innerWidth)
  useEffect(() => {
    const sub = fromEvent(window, 'resize')
      .pipe(delay(300))
      .subscribe(() => {
        setWidth(window.innerWidth)
      })
    return () => {
      sub.unsubscribe()
    }
  }, [])
  return width
}

export const useQueryParams = (): URLSearchParams => {
  const location = useLocation()
  return new URLSearchParams(location.search)
}

const enCountries = [
  CountryCode.Barbados,
  CountryCode.Dominica,
  CountryCode.SaintVincent
]

const countryCurrencyMap: Record<CountryCode, CountryCurrencyCode> = {
  [CountryCode.Barbados]: CountryCurrencyCode.Barbados,
  [CountryCode.Colombia]: CountryCurrencyCode.Colombia,
  [CountryCode.Dominica]: CountryCurrencyCode.Dominica,
  [CountryCode.DominicanRepublic]: CountryCurrencyCode.DominicanRepublic,
  [CountryCode.Ecuador]: CountryCurrencyCode.Ecuador,
  [CountryCode.Guatemala]: CountryCurrencyCode.Guatemala,
  [CountryCode.Honduras]: CountryCurrencyCode.Honduras,
  [CountryCode.Mexico]: CountryCurrencyCode.Mexico,
  [CountryCode.Panama]: CountryCurrencyCode.Panama,
  [CountryCode.Peru]: CountryCurrencyCode.Peru,
  [CountryCode.Salvador]: CountryCurrencyCode.Salvador,
  [CountryCode.SaintVincent]: CountryCurrencyCode.SaintVincent
}

interface CustomCurrencyFormatter {
  currency: string
  startsWithCurrency: boolean
  format: (x: number) => string
}

export const useCurrencyFormatter = (
  showHomeCurrency = false
): CustomCurrencyFormatter => {
  const countryCode = useSelector(getUserCountryCode) ?? CountryCode.Mexico
  const localeRoot = enCountries.includes(countryCode) ? 'en' : 'es'
  const locale = `${localeRoot}-${countryCode}`

  const countryCurrency =
    useSelector(getRealBonusCurrency) ?? countryCurrencyMap[countryCode]
  const homeCurrency = useSelector(getRealBonusHomeCurrency)
  const realCurrency =
    showHomeCurrency && homeCurrency !== null ? homeCurrency : countryCurrency

  const [formatter, setFormatter] = useState<NumberFormat>(
    new Intl.NumberFormat(locale) as NumberFormat
  )

  useEffect(() => {
    const newFormatter = new Intl.NumberFormat(locale, {
      style: 'currency',
      currency: realCurrency
    }) as NumberFormat
    setFormatter(newFormatter)
  }, [locale, countryCode, realCurrency])

  const formatParts = formatter.formatToParts(10000)
  const currency = formatParts
    .filter(({ type }) => ['currency', 'literal'].includes(type))
    .reduce((prev, { value }) => prev + value, '')

  return {
    currency,

    startsWithCurrency:
      formatParts.findIndex(({ type }) => type === 'currency') === 0,

    format (val: number): string {
      const formattedVal = val
        .toFixed(2)
        .replace('.', ',')
        .replace(/(\d)(?=(\d{3})+,)/g, '$1.')
      return this.startsWithCurrency
        ? `${currency}${formattedVal}`
        : `${formattedVal}${currency}`
    }
  }
}
