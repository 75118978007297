import { Typography } from '@material-ui/core'
import Container from '@material-ui/core/Container'
import React, { type ReactElement, type ReactNode } from 'react'
import { FormattedMessage } from 'react-intl'
import support from '../../assets/images/Soporte.png'
import { TranslationKey } from '../../i18n/translations'
import styles from './Support.module.scss'
/*
const useHelpMailLink = (): string => {
  const mainAddress = 'daniel.leroux@ab-inbev.com';
  const intl = useIntl();
  return `mailto:${mainAddress}?subject=${encodeURIComponent(
    intl.formatMessage({
      id: TranslationKey.HELP_MAIL_SUBJECT,
    }),
  )}&body=${encodeURIComponent(
    intl.formatMessage({
      id: TranslationKey.HELP_MAIL_BODY,
    }),
  )}`;
};
*/
function Support (): ReactElement {
  const helpLink =
    'https://anheuserbuschinbev.sharepoint.com/sites/somos_MAZ/simplificaMAZ/SitePages/+Benefi.aspx'
  const mailLink =
    'https://forms.office.com/Pages/ResponsePage.aspx?id=GUvwznZ3lEq4mzdcd6j5Nn1v3UuNnx1Eunak6gK0px9UREI2UzRFSTlJTUw2SE0wSzdSTTNMQVMyMi4u'
  const samLink = 'https://abimaz.service-now.com'

  return (
    <div className={styles.container}>
      <div className={styles.header}>
        <Typography variant="h4">
          <FormattedMessage id={TranslationKey.HELP_TITLE} />
        </Typography>
      </div>
      <div className={styles.content}>
        <Container maxWidth="sm">
          <ol>
            <li className={styles.contentText}>
              <FormattedMessage
                id={TranslationKey.HELP_P1}
                values={{
                  a: (...chunks: ReactNode[]): ReactElement => (
                    <a
                      className={styles.link}
                      href={helpLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {chunks}
                    </a>
                  )
                }}
              />
            </li>

            <li className={styles.contentText}>
              <FormattedMessage
                id={TranslationKey.HELP_P2}
                values={{
                  a: (...chunks: ReactNode[]): ReactElement => (
                    <a
                      className={styles.link}
                      href={mailLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {chunks}
                    </a>
                  )
                }}
              />
            </li>

            <li className={styles.contentText}>
              <FormattedMessage
                id={TranslationKey.HELP_P3}
                values={{
                  a: (...chunks: ReactNode[]): ReactElement => (
                    <a
                      className={styles.link}
                      href={samLink}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      {chunks}
                    </a>
                  )
                }}
              />
            </li>
          </ol>

          <img
            src={support}
            alt="Imagen de soporte"
            className={styles.imgMobil}
          />
        </Container>
      </div>
    </div>
  )
}

export default Support
