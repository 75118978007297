import {
  Button,
  FormControl,
  Grid,
  InputLabel,
  MenuItem,
  Select
} from '@material-ui/core'
import {
  type ChangeEvent,
  type ReactElement,
  useCallback,
  useMemo,
  useState
} from 'react'
import { FormattedMessage, useIntl } from 'react-intl'
import { useDispatch, useSelector } from 'react-redux'
import AutocompleteField from '../../../components/AutocompleteField'
import { apiURL } from '../../../config/environment'
import { CountryCode, type UserRole } from '../../../enums'
import { getValidUserRoles } from '../../../helpers/util-functions'
import { TranslationKey } from '../../../i18n/translations'
import isoCountries from '../../../services/isoCountries'
import { type IOption } from '../../../types'
import { getFetching, getSubmitting, setFilters } from '../slice'
import styles from './UsersAdmin.module.scss'

const userRoles = getValidUserRoles()

function UsersFilters (): ReactElement {
  const intl = useIntl()

  const countryOptions = useMemo(
    () =>
      Object.values(CountryCode).map((countryCode) => ({
        value: countryCode,
        label: isoCountries.getName(countryCode, intl.locale)
      })),
    [intl.locale]
  )
  const [country, setCountry] = useState<CountryCode | null>(null)
  const handleCountryChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      setCountry((event.target.value as CountryCode) ?? null)
    },
    []
  )

  const [company, setCompany] = useState<string | null>(null)
  const handleCompanyChange = useCallback(
    (option: IOption | IOption[] | null) => {
      setCompany((option as IOption)?.name)
    },
    []
  )

  const roleOptions = useMemo(
    () =>
      userRoles.map((role) => ({
        value: role,
        label: intl.formatMessage({
          id: `USER_ROLE_${role}`
        })
      })),
    [intl]
  )
  const [role, setRole] = useState<UserRole | null>(null)
  const handleRoleChange = useCallback(
    (event: ChangeEvent<{ value: unknown }>) => {
      setRole((event.target.value as UserRole) ?? null)
    },
    []
  )

  const dispatch = useDispatch()
  const handleFilterClick = useCallback(() => {
    dispatch(
      setFilters({
        country,
        company,
        role
      })
    )
  }, [company, country, dispatch, role])

  const submitting = useSelector(getSubmitting)
  const fetching = useSelector(getFetching)

  return (
    <Grid
      container
      className={styles.filtersContainer}
      spacing={2}
      alignItems="center"
    >
      {/* Country */}
      <Grid item xs={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>
            <FormattedMessage id={TranslationKey.COUNTRY} />
          </InputLabel>
          <Select
            value={country ?? ''}
            onChange={handleCountryChange}
            label={intl.formatMessage({
              id: TranslationKey.COUNTRY
            })}
            disabled={fetching ?? submitting}
          >
            <MenuItem
              value=""
              style={{
                color: '#aaa'
              }}
            >
              <FormattedMessage id={TranslationKey.NONE} />
            </MenuItem>
            {countryOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/* Company */}
      <Grid item xs={4}>
        <AutocompleteField
          label=""
          placeholder={intl.formatMessage({
            id: TranslationKey.COMPANY
          })}
          apiURL={(apiURL !== '' && typeof apiURL !== 'undefined') ? `${apiURL}/admin/autocomplete/company` : ''}
          onChange={handleCompanyChange}
          disabled={fetching ?? submitting}
        />
      </Grid>

      {/* Role */}
      <Grid item xs={3}>
        <FormControl fullWidth variant="outlined">
          <InputLabel>
            <FormattedMessage id={TranslationKey.ROLE} />
          </InputLabel>
          <Select
            label={intl.formatMessage({
              id: TranslationKey.ROLE
            })}
            value={role ?? ''}
            onChange={handleRoleChange}
            disabled={fetching ?? submitting}
          >
            <MenuItem
              value=""
              style={{
                color: '#aaa'
              }}
            >
              <FormattedMessage id={TranslationKey.NONE} />
            </MenuItem>
            {roleOptions.map(({ value, label }) => (
              <MenuItem key={value} value={value}>
                {label}
              </MenuItem>
            ))}
          </Select>
        </FormControl>
      </Grid>

      {/* Filter */}
      <Grid item xs={2}>
        <Button
          variant="outlined"
          color="primary"
          size="medium"
          fullWidth
          disabled={((country !== null) && (company !== null) && (role !== null)) ?? fetching ?? submitting}
          onClick={handleFilterClick}
        >
          <FormattedMessage id={TranslationKey.FILTER} />
        </Button>
      </Grid>
    </Grid>
  )
}

export default UsersFilters
