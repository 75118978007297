/* eslint-disable no-param-reassign */
import {
  type Action,
  createSelector,
  createSlice,
  type PayloadAction
} from '@reduxjs/toolkit'
import { toast } from 'react-toastify'
import { combineEpics, type Epic, ofType } from 'redux-observable'
import { of } from 'rxjs'
import { catchError, map, mergeMap } from 'rxjs/operators'
import { adminBenefitsAPI } from '../../../api'
import toastConfig from '../../../config/toast'
import intlHelper from '../../../i18n/intlHelper'
import { TranslationKey } from '../../../i18n/translations'
import { logger } from '../../../services'
import { type BenefitCategory } from '../../../types'
import { benefitCategoryMapper } from '../../benefits/mappers'
import { clearUserSession } from '../../userSession/slice'

/**
 * Action Payloads
 */
interface SetCategoriesPayload {
  categories: BenefitCategory[]
}

/**
 * State
 */
interface BenefitsState {
  fetchingCategories: boolean
  categories: BenefitCategory[]
}

const initialState: BenefitsState = {
  fetchingCategories: true,
  categories: []
}

/**
 * Slice
 */
const compensationSlice = createSlice({
  name: 'benefits',
  initialState,
  extraReducers: {
    [clearUserSession.toString()]: () => initialState
  },
  reducers: {
    fetchBenefitCategories (state) {
      state.fetchingCategories = true
    },
    setCategories (state, action: PayloadAction<SetCategoriesPayload>) {
      state.categories = action.payload.categories
      state.fetchingCategories = false
    }
  }
})

/**
 * Actions
 */
export const {
  fetchBenefitCategories,
  setCategories
} = compensationSlice.actions

/**
 * Selectors
 */
const getModuleState = (state: { [compensationSlice.name]: BenefitsState }): BenefitsState =>
  state[compensationSlice.name]

export const getFetchingCategories = createSelector(
  getModuleState,
  (state) => state.fetchingCategories
)

export const getCategories = createSelector(
  getModuleState,
  (state) => state.categories
)

/**
 * Epics
 */
type SetCategoriesAction = ReturnType<typeof setCategories>

export const fetchBenefitCategoriesEpic: Epic<Action, SetCategoriesAction> = (
  action$,
  state$
) =>
  action$.pipe(
    ofType<Action, any>(fetchBenefitCategories.type),
    mergeMap(() =>
      adminBenefitsAPI.getCategoriesAdmin().pipe(
        map(({ response }: any) => benefitCategoryMapper(response)),
        map((categories) => {
          logger.info('Fetched benefit categories.', categories)
          return setCategories({
            categories
          })
        }),
        catchError((error) => {
          logger.error('Error fetching benefit categories.', error)
          const intl = intlHelper.getIntl()
          toast.error(
            intl?.formatMessage({
              id: TranslationKey.ERROR_FETCHING_CATEGORIES
            }),
            toastConfig
          )
          return of(
            setCategories({
              categories: []
            })
          )
        })
      )
    )
  )

export const benefitsEpic = combineEpics(...[fetchBenefitCategoriesEpic])

export default compensationSlice.reducer
