import { Typography } from '@material-ui/core'
import { KeyboardArrowRight as KeyboardArrowRightIcon } from '@material-ui/icons'
import React, { type FunctionComponent } from 'react'
import { Link } from 'react-router-dom'
import { AppColor, BenefitType } from '../../../enums'
import { type Benefit } from '../../../types'
import styles from './BenefitCard.module.scss'

const borderColorMap: Record<BenefitType, AppColor> = {
  [BenefitType.Benefit]: AppColor.Primary,
  [BenefitType.Agreement]: AppColor.Secondary
}

const MAX_CHARACTERS = 64

const BenefitCard: FunctionComponent<Benefit> = ({
  id,
  type,
  title,
  description,
  providerName,
  providerImgURL
}) => (
  <Link to={`/benefits/${id}`} className={styles.link}>
    <div
      className={styles.container}
      style={{
        borderLeftColor: borderColorMap[type]
      }}
    >
      <div className={styles.logoContainer}>
        <img src={providerImgURL} alt={`${providerName} logo`} />
      </div>
      <div className={styles.textContainer}>
        <Typography variant="subtitle1" color="inherit">
          {title}
        </Typography>
        <Typography variant="body2" color="inherit">
          {description.length > MAX_CHARACTERS
            ? `${description.slice(0, MAX_CHARACTERS).trim()}...`
            : description}
        </Typography>
      </div>

      <KeyboardArrowRightIcon />
    </div>
  </Link>
)

export default BenefitCard
