import { type ReactElement, useEffect, useState } from 'react'
import { userSessionAPI } from '../../api'
import Footer from '../common/Footer'
import Menu from '../common/Menu'
import styles from './MinuIframe.module.scss'

declare global {
  interface Window {
    minuBenefi: (action: string, data: string) => void
  }
}

function MinuIframe (): ReactElement {
  const [minuTokenSession, setMinuTokenSession] = useState<string>('')

  const openMinu = (token: string): void => {
    window.minuBenefi('minuOpen', token)
    const minuBtn: HTMLElement = document.getElementsByClassName(
      'minuBtn'
    )[0] as HTMLElement
    minuBtn.click()
  }
  useEffect(() => {
    const token = userSessionAPI.getSessionToken()
    token.subscribe(
      (res) => {
        setMinuTokenSession(res.response.data)
        openMinu(res?.response?.data)
      },
      (err) => { console.error('Error: ', err) }
    )
  }, [])

  return (
    <div>
      <Menu />
      <div className={styles.container}>
        <span className="contMinuBtn">
          <button
            type="button"
            id="minu-btn"
            className="minuBtn"
            style={{ position: 'absolute', opacity: 0 }}
          >
            MINU
          </button>
        </span>
        <div id="minu-iframe-cont" className={styles.minuIframeCont} />
      </div>
      <Footer />
    </div>
  )
}

export default MinuIframe
