import { Grid } from '@material-ui/core'
import IconButton from '@material-ui/core/IconButton'
import KeyboardArrowLeft from '@material-ui/icons/KeyboardArrowLeft'
import KeyboardArrowRight from '@material-ui/icons/KeyboardArrowRight'
import React, { type ChangeEvent, useCallback, useEffect, useState } from 'react'
import { delay, map } from 'rxjs/operators'
import { adminBenefitsAPI } from '../../../../api'
import LoadingSpinner from '../../../../components/LoadingSpinner'
import { mapBackendPublicURL } from '../../../../helpers/util-functions'
import { type IListSupplier, type ISuppliers } from '../../../../types'
import DeleteItem from '../categories/ModalDelete'
import NewProvider from './ModalFormProviders'
import styles from './Providers.module.scss'

export default function ListProviders (props: IListSupplier): JSX.Element {
  const [page, setPage] = useState(0)
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [totalData, setTotalData] = useState(10)
  const [alertDelete, setAlertDelete] = useState(false)
  const [showLoading, setShowLoading] = useState(false)
  const [providers, setProvider] = useState<ISuppliers[]>([])
  const [loading, setLoading] = useState(true)
  const [idRow, setIdRow] = useState<number>(0)
  const { keySearch, saveData } = props

  const handleClickDelete = (idSupplier: number): void => {
    setIdRow(idSupplier)
    setAlertDelete(true)
  }
  const handleCloseDelete = (): void => {
    setAlertDelete(false)
  }

  const getSuppliers = (pageList: number, textFilter: string, rows: number): void => {
    setLoading(true)
    adminBenefitsAPI
      .getSupplier(pageList, textFilter, rows)
      .pipe(
        // @ts-expect-error: Problemas de tipos
        delay(3000),
        map(
          ({
            response
          }: {
            response: { suppliers: ISuppliers[], count: number }
          }) => response
        )
      )
      .subscribe(
        (suppliers) => {
          setProvider(suppliers.suppliers)
          setTotalData(suppliers.count)
          setLoading(false)
        },
        () => {
          setProvider([])
          setLoading(false)
        }
      )
  }

  const handleDelete = (idSupplier: number): void => {
    setAlertDelete(true)
    setShowLoading(true)
    adminBenefitsAPI.DeleteSupplier({ id: idSupplier }).subscribe(
      () => {
        setAlertDelete(false)
        setShowLoading(false)
        getSuppliers(0, '', rowsPerPage)
      },
      () => {
        setAlertDelete(false)
      }
    )
  }

  useEffect(() => {
    if (keySearch !== '') {
      getSuppliers(0, keySearch, rowsPerPage)
    } else {
      getSuppliers(page, '', rowsPerPage)
    }
  }, [keySearch, page, rowsPerPage, saveData])

  const hadleRows = useCallback((event: ChangeEvent<{ value: string }>) => {
    setRowsPerPage(parseInt(event.target.value, 10))
  }, [])

  const handleSaveSupplier = (save: boolean): void => {
    if (save) {
      if (keySearch !== '') {
        getSuppliers(0, keySearch, rowsPerPage)
      } else {
        getSuppliers(page, '', rowsPerPage)
      }
    }
  }

  return (
    <table>
      <thead>
        <tr>
          <th>Logo </th>
          <th colSpan={6}>Nombre del proveedor</th>
        </tr>
      </thead>
      <tbody>
        {loading
          ? (
          <Grid item xs={12} className={styles.centerContent}>
            <LoadingSpinner />
          </Grid>
            )
          : (
              providers.map((row: ISuppliers) => (
            <tr key={row.id}>
              <td>
                <img
                  src={mapBackendPublicURL(String(row.logo))}
                  alt="Logo"
                  width="50"
                />
              </td>
              <td colSpan={4}>{row.name}</td>
              <td align="right" className={styles.textEdit}>
                <NewProvider
                  edit
                  showForm
                  nameBtn="Editar"
                  idRow={(row.id !== null && typeof row.id !== 'undefined') ? row.id : 0}
                  providerName={row.name}
                  saveData={handleSaveSupplier}
                />
              </td>
              <td align="right">
                <DeleteItem
                  deleteItem={handleDelete}
                  open={() => { handleClickDelete((row.id !== null && typeof row.id !== 'undefined') ? row.id : 0) }}
                  close={handleCloseDelete}
                  showAlert={alertDelete}
                  title="Eliminar proveedor"
                  describe="¿Seguro quieres eliminar el proveedor seleccionado?"
                  id={idRow}
                  loading={showLoading}
                />
              </td>
            </tr>
              ))
            )}
      </tbody>
      <tfoot>
        <tr>
          <td colSpan={3} className={styles.textRightTd}>
            Filtrar por página:
          </td>
          <td>
            <select onChange={hadleRows}>
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </td>
          <td>
            {page}-{providers.length} de {rowsPerPage}
          </td>
          <td>
            <IconButton
              disabled={page === 0}
              aria-label="previous page"
              onClick={() => { setPage(page - 1) }}
            >
              <KeyboardArrowLeft />
            </IconButton>
            <IconButton
              disabled={page >= providers.length || rowsPerPage >= totalData}
              aria-label="next page"
              onClick={() => { setPage(page + 1) }}
            >
              <KeyboardArrowRight />
            </IconButton>
          </td>
        </tr>
      </tfoot>
    </table>
  )
}
