import { type Observable } from 'rxjs'
import { ajax, type AjaxResponse } from 'rxjs/ajax'
import { apiURL } from '../config/environment'
import { HttpMethod, type Locale } from '../enums'
import { serializeQuery } from '../helpers/util-functions'
import { type RawBenefit, type RawBenefitCategory } from '../types'

const acceptJSONHeader = {
  Accept: 'application/json; charset=UTF-8'
}

interface BenefitCategoriesResponse extends AjaxResponse<any> {
  response: RawBenefitCategory[]
}
export const fetchBenefitsCategories = (
  locale: Locale
): Observable<BenefitCategoriesResponse> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/benefits-categories` : '',
    method: HttpMethod.Get,
    headers: {
      ...acceptJSONHeader,
      'Accept-Language': locale
    },
    withCredentials: true
  })

interface BenefitsResponse extends AjaxResponse<any> {
  response: RawBenefit[]
}
export const fetchBenefits = (
  locale: Locale,
  categoryID?: number,
  textFilter?: string
): Observable<BenefitsResponse> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined')
      ? `${apiURL}/benefits?${serializeQuery({
      categoryID,
      textFilter
    })}`
      : '',
    method: HttpMethod.Get,
    headers: {
      ...acceptJSONHeader,
      'Accept-Language': locale
    },
    withCredentials: true
  })

interface BenefitDetailsResponse extends AjaxResponse<any> {
  response: RawBenefit
}
export const fetchBenefitDetails = (
  benefitId: number,
  locale: Locale
): Observable<BenefitDetailsResponse> =>
  ajax({
    url: (apiURL !== null && typeof apiURL !== 'undefined') ? `${apiURL}/benefits/${benefitId}` : '',
    method: HttpMethod.Get,
    headers: {
      ...acceptJSONHeader,
      'Accept-Language': locale
    },
    withCredentials: true
  })
